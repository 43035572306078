import React from 'react';

import tabular from './Tabular.module.css';

export const TabularHeader = (props) => {
    const menus = props.menus.map((item, index) => {
        return (
            <TabularMenu 
                key={index} 
                title={item}
                state={index}
                onTabClickHandler={props.onTabClickHandler}
                active={index === props.active}/>
        );
    });
    return (
        <>
            <div className={tabular.TabHeader}>
                {menus}
            </div>
        </>
    );
}

const TabularMenu = (props) => {
    let menuClasses = [tabular.TabHeader_Item];

    if (props.active) {
        menuClasses.push(tabular.TabHeader_Item__Active);
    }

    const onClick = () => {
        props.onTabClickHandler(props.state);
    }

    return (
        <div className={menuClasses.join(" ")} onClick={onClick}>
            {props.title}
        </div>
    );
}

export const TabularBody = (props) => {
    return (
        <div className={tabular.TabBody}>
            {props.children}
        </div>
    );
}