import React, { useEffect, useState } from "react";
import VerticalListsMultiple from "../../../../../../component/UI/List/VerticalListsMultiple/VerticalListsMultiple";
import classes from "../../NewBorrowing.module.css";

const SoftwareFilter = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [shownSoftwares, setShownSoftware] = useState([]);

  const searchInputChangeHandler = (event) => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {
    if (!searchInput && props.softwares) {
      setShownSoftware(props.softwares);
    }
    const delaySearch = setTimeout(() => {
      if (!searchInput) {
        setShownSoftware(props.softwares);
        return;
      }
      const filteredSoftware = props.softwares.filter((software) =>
        software.name.toLowerCase().includes(searchInput.toLowerCase())
      );
      setShownSoftware(filteredSoftware);
    }, 1000);

    return () => clearTimeout(delaySearch);
  }, [searchInput, props.softwares]);

  const nextButtonHandler = () => {
    props.changeStateHandler(3);
  };

  const backButtonHandler = () => {
    props.changeStateHandler(2);
  };

  let nextButtonClasses = [classes.NextButton];

  return (
    <div>
      <h4 className="title">Software Filter</h4>

      <div style={{ marginBottom: "5px" }}>
        <input
          type="text"
          value={searchInput}
          placeholder="Search by the name of the item"
          onChange={searchInputChangeHandler}
        ></input>
      </div>

      <div style={{ marginBottom: "70px" }}>
        {props.softwares && props.softwares.length !== 0 ? (
          <VerticalListsMultiple
            items={shownSoftwares}
            selectedItems={props.filterSoftwares}
            addItemHandler={props.addFilterSoftwareHandler}
            removeItemHandler={props.removeFilterSoftwareHandler}
            height={"30rem"}
          />
        ) : (
          <div style={{marginTop: "1.5rem"}}>Sorry, currently no software is available.</div>
        )}
      </div>
      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className="material-icons" style={{ marginRight: "8px" }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(" ")}
          onClick={nextButtonHandler}
        >
          Next
          <i className="material-icons" style={{ marginLeft: "8px" }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default SoftwareFilter;
