import React, { useEffect, useState } from "react";
import "../../../themes/bm5/css/login.css";
import { useMsal } from "@azure/msal-react";
import axios from "axios";

const LoginMicrosoft = (props) => {
  const { loading, setLoading, cookies, messageHandler, history } = props;

  const { instance, accounts, inProgress } = useMsal()

  const fetchUser = async (token) => {
    setLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_DEFAULT_API + "login-microsoft",
        {
          microsoft_token: token,
        }
      );
      if (response.data) {
        if (!response.data.payload.status) {
          messageHandler(response.data.payload.message, "error", true);
          setLoading(false);
          return;
        }
        messageHandler("Login Success", "success", true);
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        cookies.set("_s", response.data.payload.data, {
          expires: tomorrow,
          path: "/",
          sameSite: true,
        });
        setTimeout(() => {
          history.push("/");
        }, 500);
      }
    } catch (error) {
      if (!error.response) {
        messageHandler(
          "Unexpected error, please contact the administrator",
          "error",
          true
        );
      } else {
        messageHandler(error.response.status, "error", true);
      }

      setLoading(false);
      return;
    }
  };

  const handleClick = (e) => {
    const loginRequest = {
      scopes: ["user.read"],
      prompt: "select_account",
    };

    instance.loginRedirect(loginRequest).catch((error) => {
      console.log("Error redirecting to login!");
      console.error(error);
    });
  };

  useEffect(() => {
    const handleRedirect = async () => {
      if (inProgress === "none" && accounts.length > 0) {
        try {
          const response = await instance.acquireTokenSilent({
            account: accounts[0],
            scopes: ["user.read"],
          });
          const microsoft_id = accounts[0].homeAccountId;
          const token = response.idToken;
          fetchUser(token, microsoft_id);
        } catch (error) {
          messageHandler("Failed to acquire token silently", "error", true);
        }
      }
    };

    handleRedirect();
  }, [instance, accounts, inProgress]);
  return (
    <button
      className="button button-primary wide"
      type="button"
      onClick={handleClick}
      disabled={loading}
    >
      Login
    </button>
  );
};

export default LoginMicrosoft;
