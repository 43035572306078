import React, { useState } from "react";
import "../../../themes/bm5/css/login.css";
import axios from "axios";

const MessierForm = (props) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const { loading, setLoading, cookies, messageHandler, history } = props;

  const validateUsernameAndPassword = (username, password) => {
    if (username.length === 0) {
      return false;
    } else if (password.length === 0) {
      return false;
    }

    return true;
  };

  const updateData = (e) => {
    const target = e.target;
    setFormData({
      ...formData,
      [target.name]: target.value,
    });
  };

  const getSalt = async (username) => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_DEFAULT_API + "get-salt",
        {
          username: username,
        },
        { withCredentials: false }
      );
      return {
        data: result.data.payload.data ?? null,
        status: result.data.payload.status ?? false,
        message: result.data.payload.status.message ?? "Unexpected error, please contact the administrator",
      };
    } catch (error) {
      return {
        data: null,
        status: false,
        message: !error.response
          ? "Unexpected error, please contact the administrator"
          : error.response.status,
      };
    }
  };

  const getUser = async (username, password) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_DEFAULT_API + "login-messier",
        {
          username: username,
          password: password,
        },
        { withCredentials: false }
      );
      return {
        data: response.data.payload.data ?? null,
        status: response.data.payload.status ?? false,
        message: response.data.payload.status.message ?? "Unexpected error, please contact the administrator",
      };
    } catch (error) {
      return {
        data: null,
        status: false,
        message: !error.response
          ? "Unexpected error, please contact the administrator"
          : error.response.status,
      };
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { username, password } = formData;
    if (!validateUsernameAndPassword(username, password)) {
      messageHandler("Invalid username and password!", "error", true);
      setLoading(false);
      return;
    }
    const salt = await getSalt(username);
    if (!salt.status) {
      messageHandler(salt.message, "error", true);
      setLoading(false);
      return;
    }
    const payload = window.EncryptToBase64(salt.data + username, password);
    const login = await getUser(username, payload);
    if (!login.status) {
      messageHandler(login.message, "error", true);
      setLoading(false);
      return;
    }
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    cookies.set("_s", login.data, {
      expires: tomorrow,
      path: "/",
      sameSite: true,
    });
    setTimeout(() => {
      history.push("/");
    }, 500);
  };

  return (
    <form
      id="login-form"
      className="custom-form"
      action="#"
      method="post"
      style={{
        borderTop: "solid 1px black",
        paddingTop: "1rem",
      }}
      onSubmit={handleSubmit}
    >
      <p style={{ width: "100%", textAlign: "center" }}>Staff Login</p>
      <p>
        <span className="custom-textbox">
          <span className="icon-wrap">
            <i className="icon icon-user"></i>
          </span>
          <input
            type="text"
            name="username"
            placeholder="Username"
            autoComplete="off"
            value={formData.username}
            onChange={updateData}
          />
        </span>
      </p>
      <p>
        <span className="custom-textbox">
          <span className="icon-wrap">
            <i className="icon icon-pass"></i>
          </span>
          <input
            type="password"
            name="password"
            placeholder="Password"
            required=""
            value={formData.password}
            onChange={updateData}
          />
        </span>
      </p>
      <p>
        <input
          type="submit"
          name="btnLogin"
          value="Login Staff"
          className="button button-orange wide"
          disabled={loading}
        />
      </p>
    </form>
  );
};

export default MessierForm;
