import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
const Provider = (props) => {
  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
      authority: process.env.REACT_APP_MICROSOFT_TENANT_ID,
      redirectUri: process.env.REACT_APP_REDIRECT_URI,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: process.env.REACT_APP_CACHE_LOCATION,
      storeAuthStateInCookie:
        process.env.REACT_APP_STORE_AUTH_STATE_IN_COOKIE === "true",
    },
  };
  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      {props.children}
    </MsalProvider>
  )
};

export default Provider;
