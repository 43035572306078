import React from "react";
import Calendar from "react-calendar";
import Moment from "moment";
import classes from "./UpdateDateShift.module.css";

const UpdateDateShift = (props) => {
  const calendarHandler = (date) => {
    props.dateHandler(Moment(date).format("YYYY-MM-DD").toString());
  };

  return (
    <div style={{ maxWidth: "100%" }} className={classes.SubTitle__Container}>
      <div className={classes.SubTitle}>
        Update {props.type === "check-out" ? "Check Out" : "Check In"} Detail
      </div>
      <div className={classes.SubSubTitle__Container}>
        <div className={classes.SubSubTitle} style={{ margin: "1rem 0rem" }}>
          Date
        </div>

        <Calendar
          className={classes.Calendar}
          value={new Date(props.selectedDate)}
          calendarType="ISO 8601"
          onChange={calendarHandler}
          minDate={
            props.lastBorrowingDate !== null
              ? new Date(Moment().add(1, "days").toString())
              : new Date(Moment().add(1, "days").toString())
          }
          tileDisabled={({ date }) => date.getDay() === 0}
        />

        <div className={classes.SubSubTitle} style={{ margin: "1rem 0rem" }}>
          Shift
        </div>
        <select
          className={classes.InputText}
          value={props.selectedShiftID ? props.selectedShiftID : ""}
          onChange={(evt) => {
            props.setSelectedShiftID(evt.target.value);
          }}
        >
          {props.allShifts &&
            props.allShifts.map((shift, i) => {
              return (
                <option key={i} value={shift.id}>
                  {shift.name}
                </option>
              );
            })}
        </select>
      </div>
      {/* <div className={classes.SubSubTitle__Container} style={{ margin: "1rem 0rem" }}>
          <div className={classes.SubSubSubTitle}>
            Existing Borrowing Transaction
          </div>

          {!props.existingBorrowingTransaction ? "There is no conflicting borrowing transaction." : ""}

          <div className={classes.SubSubSubTitle}>
            Existing Asset Borrowing Transaction
          </div>

          {!props.existingAssetBorrowingTransaction ? "There is no conflicting asset borrowing transaction." : ""}
      </div> */}
      <div
        style={{ marginTop: "2rem" }}
        className={classes.SubSubTitle__Container}
      >
        <span
          style={{
            display: "inline-block",
            padding: "14px 16px",
            backgroundColor: "#dff0d8",
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#3c763d",
            borderColor: "#d6e9c6",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
          }}
          onClick={props.submitButtonHandler}
        >
          Update
        </span>
      </div>
    </div>
  );
};

export default UpdateDateShift;
