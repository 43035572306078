import React from 'react';

import classes from './VerticalList.module.css';

const VerticalList = (props) => {
  const clickHandler = () => {
    if (props.onClick) {
      props.onClick(props.item);
    }
  };

  let verticalListClass = [classes.VerticalList];

  if (props.active) {
    verticalListClass.push(classes.Active);
  }

  return (
    <div className={verticalListClass.join(' ')} onClick={clickHandler}>
      {props.children}
    </div>
  );
};

export default VerticalList;
