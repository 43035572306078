import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Loading from '../../../component/UI/Loading/Loading';
import NewBorrowing from './NewBorrowing/NewBorrowing';
import NewAssetBorrowing from './NewAssetBorrowing/NewAssetBorrowing';
import BorrowingHistory from './BorrowingHistory/BorrowingHistory';
import SubmitFeedback from './Feedback/SubmitFeedback';
import StudentRules from './Rules/StudentRules';
import LogOut from '../LogOut';
import AssetBorrowingHistory from './AssetBorrowingHistory/AssetBorrowingHistory';

const StudentHome = (props) => {

  return (
    <>
      <Switch>
        <Route
          path='/student/room-borrowing'
          render={(_) => (
            <NewBorrowing
              userData={props.userData}
              messageHandler={props.messageHandler}
              {..._}
            />
          )}
        />
        <Route
          path='/student/asset-borrowing'
          render={(_) => (
            <NewAssetBorrowing
              userData={props.userData}
              messageHandler={props.messageHandler}
              {..._}
            />
          )}
        />
        <Route
          path='/student/borrowing-history'
          render={(_) => (
            <BorrowingHistory
              userData={props.userData}
              messageHandler={props.messageHandler}
              {..._}
            />
          )}
        />
        <Route
          path='/student/asset-borrowing-history'
          render={(_) => (
            <AssetBorrowingHistory
              userData={props.userData}
              messageHandler={props.messageHandler}
              {..._}
            />
          )}
        />
        <Route
          path='/student/submit-feedback'
          render={(_) => (
            <SubmitFeedback
              userData={props.userData}
              messageHandler={props.messageHandler}
              {..._}
            />
          )}
        />
        <Route
          path='/student/rules'
          render={(_) => (
            <StudentRules
              userData={props.userData}
              messageHandler={props.messageHandler}
              {..._}
            />
          )}
        />
        <Route
          path='/log-out'
          render={(_) => <LogOut messageHandler={props.messageHandler} />}
        />
        <Redirect to='/student/room-borrowing' />
      </Switch>
      <br />
    </>
  );
};

export default StudentHome;
