import React, { useState } from 'react';
import Axios from 'axios';

import Loading from '../../../../component/UI/Loading/Loading';

import classes from '../NewBorrowing/NewBorrowing.module.css';

const SubmitFeedback = (props) => {
  const [feedbackData, setFeedbackData] = useState({
    message: '',
    reporter_name: props.userData.name,
    binusian_id: props.userData.binusian_id,
    reporter_email: props.userData.email,
  });
  const [isLoad, setIsLoad] = useState(false);

  const setData = (type, data) => {
    let newState = { ...feedbackData };
    if (type === 'feedback') {
      newState.message = data;
    }
    setFeedbackData(newState);
  };

  const submitButtonHandler = async () => {
    setIsLoad(true);
    if (feedbackData.message != null) {
      let submitURL = 'student/feedback/submit-feedback';
      if (props.userData.cid != null) {
        //staff
        submitURL = 'staff/feedback/submit-feedback';
      }
      //kalo student submitURL ga keganti, soalnya itu route buat student

      try {
        let submit = await Axios.post(
          process.env.REACT_APP_DEFAULT_API + submitURL,
          feedbackData
        );

        props.messageHandler(
          submit.data.payload.message,
          submit.data.payload.status ? 'success' : 'error',
          true
        );
        let message = submit.data.payload.status
          ? submit.data.payload.message
          : submit.data.payload.message;
        props.messageHandler(
          message,
          submit.data.payload.status ? 'success' : 'error',
          true
        );

        if (submit.data.payload.status) {
          setTimeout(() => {
            props.history.push('/');
          }, 2000);
        }
      } catch (error) {
        props.messageHandler(
          'Unexpected error, please contact the administrator',
          'error',
          true
        );
      }
    } else {
      props.messageHandler("Feedback message can't be null", 'error', true);
    }
    setIsLoad(false);
  };

  return (
    <div className={classes.Container}>
      {isLoad ? <Loading /> : ''}
      <div className={classes.Title}>Submit Feedback</div>
      <div className={classes.SubTitle__Container}>
        <div className={classes.SubTitle}>feedback</div>
        <p className={classes.Description}>
          We would love to hear your thoughts, concerns or problems with
          anything so we can improve!
        </p>
        <textarea
          className={classes.InputText}
          value={feedbackData.message}
          placeholder='Ex: I found some bug'
          onChange={(evt) => {
            setData('feedback', evt.target.value);
          }}
        />
      </div>
      <div className={classes.ButtonContainer__SpaceBetween}>
        <div></div>
        <div className={classes.NextButton} onClick={submitButtonHandler}>
          Submit
          <i className='material-icons' style={{ marginLeft: '8px' }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default SubmitFeedback;
