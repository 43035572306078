import React, { useState, useEffect } from 'react';

import VerticalListsMultipleAssetWithImage from '../../../../../../component/UI/List/VerticalListsMultipleAssetWithImage/VerticalListsMultipleAssetWithImage';

import classes from '../../NewBorrowing.module.css';

const AssetBorrowingData = (props) => {
  // eslint-disable-next-line
  const [assets, setAssets] = useState([]);


  const backButtonHandler = () => {
    props.changeStateHandler(3);
  };

  const nextButtonHandler = () => {
    props.changeStateHandler(5);
  };

  let nextButtonClasses = [classes.NextButton];
  let nextButtonText = 'Skip';
  if (
    props.selectedAssets != null &&
    Array.isArray(props.selectedAssets) &&
    props.selectedAssets.length > 0
  ) {
    nextButtonText = 'Next';
  }

  return (
    <div className={classes.BorrowingDataContainer}>
      <div className={classes.Title} style={{ lineHeight: '20pt' }}>
      Choose asset that you want to borrow!

      </div>
      <div className={classes.SubTitle__Container}>
        <div className={classes.SubTitle}>Equipment</div>
        <p className={classes.Description}>
          If you don't need any asset, just press skip at the bottom of the
          page!
        </p>
      </div>
      <h4>{`Quantity: ${props.selectedAssets.length}`}</h4>
      <VerticalListsMultipleAssetWithImage
        assets={props.availableAssets}
        selectedAssets={props.selectedAssets}
        addAssetHandler={props.addAssetHandler}
        removeAssetHandler={props.removeAssetHandler}
      />
      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className='material-icons' style={{ marginRight: '8px' }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(' ')}
          onClick={nextButtonHandler}
        >
          {nextButtonText}
          <i className='material-icons' style={{ marginLeft: '8px' }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AssetBorrowingData);
