import React, { useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Button, Dropdown, Image } from "semantic-ui-react";
import Loading from "../../../../component/UI/Loading/Loading";
import Sorry from "../../../../images/sorry.webp";
import classes from "./StaffReporting.module.css";
import axios from "axios";

const StaffReporting = (props) => {
  const [isLoad, setIsLoad] = useState(false);
  const [semester, setSemester] = useState([]);
  const [rooms, setRooms] = useState({ withDesc: [], plain: [] });
  const [borrowingRooms, setBorrowingRooms] = useState([]);
  const [assetRooms, setAssetRooms] = useState([]);
  const selectedOption = useRef({
    semester: "",
    type: "Room",
    location: "All",
    room: "All",
  });

  const filename = useRef("");
  const [selectedSemester, setSelectedSemester] = useState(0);
  const [selectedReportType, setSelectedReportType] = useState("room");
  const [selectedRoom, setSelectedRoom] = useState(0);
  const [currentSemester, setCurrentSemester] = useState(0);

  const [reportTypes, setReportTypes] = useState([
    {
      key: "room",
      value: "room",
      text: "Room Borrowing",
    },
    {
      key: "asset",
      value: "asset",
      text: "Asset Borrowing",
    },
  ]);

  useEffect(() => {
    Axios.defaults.withCredentials = true;
    let fetchHomeData = async () => {
      setIsLoad(true);
      let data;
      try {
        data = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + `staff/room/borrowing-pic/${props.userData.username}`
        );
        data = data.data.payload;

        let assetRoomsWithDesc = [];
        let assetRooms = [];
        let tempRoomsWithDesc = [];
        let tempRooms = [];

        data.data.forEach((roomObj) => {
          if (roomObj.assets.length > 0) {
            assetRoomsWithDesc.push({
              key: roomObj.id,
              value: roomObj.id,
              text: roomObj.room_code + " - " + roomObj.room_name,
            });
            assetRooms.push({
              key: roomObj.id,
              value: roomObj.id,
              text: roomObj.room_code,
            });
          }
          tempRoomsWithDesc.push({
            key: roomObj.id,
            value: roomObj.id,
            text: roomObj.room_code + " - " + roomObj.room_name,
          });
          tempRooms.push({
            key: roomObj.id,
            value: roomObj.id,
            text: roomObj.room_code,
          });
        });

        if (assetRoomsWithDesc.length > 0) {
          assetRoomsWithDesc.unshift({
            key: 0,
            value: 0,
            text: "All",
          });
        } else {
          setReportTypes(reportTypes.slice(0, 1))
        }
        tempRoomsWithDesc.unshift({
          key: 0,
          value: 0,
          text: "All",
        });
        setAssetRooms({
          withDesc: assetRoomsWithDesc,
          plain: assetRooms
        })
        setBorrowingRooms({
          withDesc: tempRoomsWithDesc,
          plain: tempRooms,
        })

        setRooms({
          withDesc: tempRoomsWithDesc,
          plain: tempRooms,
        });
      } catch (error) {
        props.messageHandler(
          "Unexpected error, please contact the administrator",
          "error",
          true
        );
      }
      setIsLoad(false);
    };

    fetchHomeData();
  }, []);

  //! Ada Accept Success untuk props.messageHandler nya

  const updateFileName = () => {
    let _semester, _room;
    _semester = semester.find((s) => {
      return s.value === selectedSemester;
    });
    _room =
      selectedRoom === 0
        ? { text: "All" }
        : rooms.plain.find((r) => {
            return r.value === selectedRoom;
          });

    selectedOption.current = {
      semester: _semester
        ? _semester.text.replaceAll("/", "-")
        : selectedOption.current.semester,
      type:
        selectedReportType.charAt(0).toUpperCase() +
        selectedReportType.slice(1),
      room: _room ? _room.text : selectedOption.current.room,
    };

    filename.current =
      "Report_" +
      selectedOption.current.type +
      "_" +
      selectedOption.current.semester +
      "_" +
      selectedOption.current.room +
      ".xlsx";
  };

  useEffect(() => {
    filename.current =
      "Report_" +
      selectedOption.current.type +
      "_" +
      selectedOption.current.semester +
      "_" +
      selectedOption.current.room +
      ".xlsx";
  }, [selectedOption]);

  const downloadHandler = async () => {
    let roomIds = []
    if (selectedRoom === 0) {
      if (selectedReportType === "room") {
        roomIds = borrowingRooms.plain.map((o) => {
          return o.key
        })
      } else {
        roomIds = assetRooms.plain.map((o) => {
          return o.key
        })
      }
    } else {
      roomIds = selectedRoom
    }
    axios({
      url: process.env.REACT_APP_DEFAULT_API + "staff/reporting/generate",
      method: "GET",
      responseType: "blob",
      params: {
        semesterId: selectedSemester,
        roomId: roomIds,
        reportType: selectedReportType,
      },
    }).then((response) => {
      const fr = new FileReader();
      if (response.data.type === "application/json") {
        fr.onload = (e) => {
          console.log(e.target);
          const error = e.target.result.replaceAll('"', "");
          props.messageHandler(error, "error", true);
        };
        fr.readAsText(response.data);
      } else {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename.current);
        document.body.appendChild(link);
        link.click();
      }
    });
  };

  const fetchSemester = async () => {
    props.loadingHandler(true);
    try {
      let results = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + "semester"
      );
      let semesterData = results.data.semester.data;
      if (semesterData.length !== 0) {
        let temp = [];
        temp = semesterData.map((semesterObj) => {
          return {
            key: semesterObj.id,
            value: semesterObj.id,
            text: semesterObj.description,
          };
        });
        setSemester(temp);
      }
    } catch (e) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
      setSemester([]);
    }
    props.loadingHandler(false);
  };

  const getCurrentSemester = async () => {
    try {
      let result = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + "semester/current"
      );
      setSelectedSemester(result.data.currentSemester.id);
      setCurrentSemester(result.data.currentSemester);
      selectedOption.current = {
        semester: result.data.currentSemester.description.replaceAll("/", "-"),
        type:
          selectedReportType.charAt(0).toUpperCase() +
          selectedReportType.slice(1),
        location: selectedOption.current.location,
        room: selectedOption.current.room,
      };
    } catch (error) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
      setSelectedSemester(1);
    }
    updateFileName();
  };
  useEffect(() => {
    updateFileName();
  }, [selectedSemester, selectedRoom, selectedReportType]);

  useEffect(() => {
    getCurrentSemester();
    fetchSemester();
  }, []);

  return (
    <>
      {isLoad ? <Loading /> : ""}
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {rooms.plain.length > 0 ? (
          <>
            {currentSemester.id ? (
              <Dropdown
                selection
                fluid
                header
                defaultValue={selectedSemester}
                onChange={(evt, { value }) => {
                  setSelectedSemester(value);
                }}
                style={{ margin: "0 0 20px 0" }}
                options={semester}
              />
            ) : (
              <div>
                <Dropdown
                  selection
                  fluid
                  header
                  options={semester}
                  style={{ margin: "0 0 20px 0" }}
                />
              </div>
            )}
            <Dropdown
              selection
              fluid
              header
              defaultValue={selectedReportType}
              onChange={(evt, { value }) => {
                setRooms(value === "room" ? borrowingRooms : assetRooms)
                setSelectedReportType(value);
              }}
              style={{ margin: "0 0 20px 0" }}
              options={reportTypes}
            />
            <Dropdown
              selection
              fluid
              header
              multiple={
                selectedRoom === 0
                  ? false
                  : Array.isArray(selectedRoom) && selectedRoom.includes(0)
                  ? false
                  : true
              }
              defaultValue={selectedRoom}
              onAddItem={(e, data) => {
                console.log(e);
                console.log(data);
              }}
              onChange={(evt, { value }) => {
                setSelectedRoom(value);
              }}
              style={{ margin: "0 0 20px 0" }}
              options={rooms.withDesc}
            />
            <Button
              fluid
              primary
              onClick={() => {
                downloadHandler();
              }}
            >
              <span style={{ fontWeight: "bold" }}>Download </span>{" "}
              {filename.current}
            </Button>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Image src={Sorry} width={500} />
            <h3 style={{ fontWeight: "bold" }}>Sorry! NOT a Room PIC</h3>
          </div>
        )}
      </div>
    </>
  );
};

export default StaffReporting;
