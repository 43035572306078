import React from "react";

import classes from "../../../../container/Home/Staff/StaffBorrowingHistory/StaffBorrowingHistory.module.css";

const SubMenu = (props) => {
    return (
        <div className={classes.SubMenuContainer}>
            {props.subMenuList.map((item, index) => {
                if (props.selectedSubMenu === index) {
                    item.cssClasses.push(classes.Active);
                }

                return (
                    <div
                        className={item.cssClasses.join(" ")}
                        onClick={item.handler}
                        key={index}
                    >
                        {item.name}
                    </div>
                );
            })}
        </div>
    );
};

export default SubMenu;
