import React, { useState, useEffect, useRef } from "react";
import Calendar from "react-calendar";
import Moment from "moment";
import { CSSTransition } from "react-transition-group";

import classes from "../../NewAssetBorrowing.module.css";
import VerticalListsMultiple from "../../../../../../component/UI/List/VerticalListsMultiple/VerticalListsMultiple";

const AssetBorrowingData = (props) => {
  // eslint-disable-next-line
  const [assets, setAssets] = useState([]);
  useEffect(() => {
    if (availShiftRef.current != null) {
      availShiftRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    if (props.availableShifts != null && !props.availableShifts.status) {
      props.messageHandler(props.availableShifts.message, "error", true);
    }
  }, [props.availableShifts]);

  const availShiftRef = useRef(null);

  const checkDateShiftsSelected = () => {
    return (
      props.selectedDate &&
      props.selectedReturnDate &&
      props.selectedShifts.length !== 0 &&
      props.selectedShifts2.length !== 0
    );
  };

  const calendarHandler = (date) => {
    props.dateHandler(Moment(date).format("YYYY-MM-DD").toString());
  };

  const returnCalendarHandler = (date) => {
    props.returnDateHandler(Moment(date).format("YYYY-MM-DD").toString());
  };

  const backButtonHandler = () => {
    props.changeStateHandler(4);
  };

  const nextButtonHandler = () => {
    if (checkDateShiftsSelected()) {
      props.changeStateHandler(6);
    }
  };

  let renderAvailableShift = <div></div>;
  let renderReturnShift = <div></div>;
  let renderReturnDate = <div></div>;
  if (props.availableShifts != null) {
    renderAvailableShift = (
      <>
        <div className={classes.SubTitle__Container} ref={availShiftRef}>
          <div className={classes.SubTitle}>Asset Pick Up Shift</div>

          <p className={classes.Description} style={{ marginBottom: "30px" }}>
            Pick 1 shift you desire the most to pick up the asset. Don't forget
            to discuss with your teammate!
          </p>
          <VerticalListsMultiple
            items={props.availableShifts.shifts}
            selectedItems={props.selectedShifts}
            addItemHandler={props.pickOneShiftHandler}
            removeItemHandler={props.removeShiftHandler}
          />
        </div>
      </>
    );
  }

  renderReturnShift = (
    <>
      <div className={classes.SubTitle__Container} ref={availShiftRef}>
        <div className={classes.SubTitle}>Asset Return Shift</div>

        <p className={classes.Description} style={{ marginBottom: "30px" }}>
          Pick 1 shift you desire the most to return the assets you borrow.
          Don't forget to discuss with your teammate!
        </p>
        <VerticalListsMultiple
          items={props.availableShifts.shifts}
          selectedItems={props.selectedShifts2}
          addItemHandler={props.pickOneShiftHandler2}
          removeItemHandler={props.removeShiftHandler2}
        />
      </div>
    </>
  );

  let nextButtonClasses = [classes.NextButton];
  let nextButtonText = "Next";
  if (props.selectedReturnDate == null || props.selectedShifts == null) {
    nextButtonClasses.push(classes.ButtonDisabled);
  }

  if (props.selectedReturnDate != null) {
    nextButtonText = "Next";
  }

  return (
    <div className={classes.BorrowingDataContainer}>
      <div className={classes.Title} style={{ lineHeight: "20pt" }}>
        Okay, when will you use the asset(s)?
      </div>
      <div className={classes.SubTitle__Container}>
        <div className={classes.SubTitle}>Pick Up Date</div>
        <p className={classes.Description}>
          Tell us when you want to pick up the asset(s).
        </p>
      </div>

      <Calendar
        value={new Date(props.selectedDate)}
        calendarType="ISO 8601"
        onChange={calendarHandler}
        minDate={
          props.lastBorrowingDate !== null
            ? new Date(Moment().add(1, "days").toString())
            : new Date(Moment().add(1, "days").toString())
        }
        tileDisabled={({ date }) => date.getDay() === 0}
      />

      <CSSTransition
        unmountOnExit
        in={props.selectedDate != null}
        classNames="shift"
        timeout={500}
      >
        {renderAvailableShift}
      </CSSTransition>

      <CSSTransition
        unmountOnExit
        in={props.selectedDate != null && props.selectedShifts.length > 0}
        classNames="shift"
        timeout={500}
      >
        <>
          <div className={classes.SubTitle__Container}>
            <div className={classes.SubTitle}>Asset Return Date</div>
            <p className={classes.Description}>
              Tell us when you want to return the asset(s).
            </p>
          </div>
          <Calendar
            value={new Date(props.selectedReturnDate)}
            calendarType="ISO 8601"
            onChange={returnCalendarHandler}
            minDate={new Date(props.selectedDate)}
            tileDisabled={({ date }) => date.getDay() === 0}
          />
        </>
      </CSSTransition>

      <CSSTransition
        unmountOnExit
        in={props.selectedReturnDate != null && props.selectedShifts.length > 0}
        classNames="shift"
        timeout={500}
      >
        {renderReturnShift}
      </CSSTransition>

      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className="material-icons" style={{ marginRight: "8px" }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(" ")}
          onClick={nextButtonHandler}
        >
          {nextButtonText}
          <i className="material-icons" style={{ marginLeft: "8px" }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default AssetBorrowingData;
