import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import ReactTable from 'react-table';
import { Grid, Button, Segment } from 'semantic-ui-react';

import Backdrop from '../../../../../component/UI/Backdrop/Backdrop';

import 'react-table/react-table.css';
import './CustomReactTable.css';

const RoomLocation = (props) => {
  const [roomLocation, setRoomLocation] = useState([]);
  const [locationName, setLocationName] = useState('');
  const [dialogBoxShow, setDialogBoxShow] = useState(false);
  const [selectedRoomLocation, setSelectedRoomLocation] = useState(null);

  useEffect(() => {
    fetchRoomLocation();
  }, []);

  const fetchRoomLocation = async () => {
    try {
      props.loadingHandler(true);
      let data = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + 'admin/room-location'
      );

      if (data.data && data.data.payload.status) {
        setRoomLocation(data.data.payload.data);
      }
    } catch (e) {
      props.messageHandler(
        'Unexpected error please call the administrator',
        'error',
        true
      );
    }

    props.loadingHandler(false);
  };

  const updateRoomLocationButtonHandler = async (id, location = null) => {
    props.loadingHandler(true);
    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API + 'admin/room-location/' + id,
        {
          id: id,
          location: location,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler('Success Updating Room Location', 'success', true);
      } else {
        props.messageHandler('Cannot Update Room Location', 'error', true);
      }

      props.loadingHandler(false);

      fetchRoomLocation();
    } catch (e) {
      props.messageHandler('Unexpected error, please call the administrator');
      props.loadingHandler(false);
    }
  };

  const insertRoomLocationButtonHandler = async (location = null) => {
    props.loadingHandler(true);
    try {
      let result = await Axios.post(
        process.env.REACT_APP_DEFAULT_API + 'admin/room-location',
        {
          location: location,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler(
          'Success Inserting Room Location',
          'success',
          true
        );
      } else {
        props.messageHandler('Cannot Insert Room Location', 'error', true);
      }

      props.loadingHandler(false);

      fetchRoomLocation();
    } catch (e) {
      props.messageHandler('Unexpected error, please call the administrator');
      props.loadingHandler(false);
    }
  };

  const showHideDialogBox = (mode, roomLocation = null) => {
    setSelectedRoomLocation(roomLocation);

    setDialogBoxShow(mode);
  };

  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
    },
    {
      Header: 'Location',
      accessor: 'location',
    },
    {
      Header: 'Action',
      filterable: false,
      sortable: false,
      Cell: (props) => {
        let style = {
          fontWeight: 'bold',
          color: '#3c763d',
          backgroundColor: '#dff0d8',
          display: 'inline-block',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          cursor: 'pointer',
          userSelect: 'none',
        };

        let val = 'Edit';

        return (
          <span
            style={style}
            onClick={() => showHideDialogBox(true, props.original)}
          >
            {val}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Backdrop
        clickable={true}
        clickHandler={showHideDialogBox}
        show={dialogBoxShow}
      />
      <DialogBox
        messageHandler={props.messageHandler}
        selectedRoomLocation={selectedRoomLocation}
        updateRoomLocationButtonHandler={updateRoomLocationButtonHandler}
        show={dialogBoxShow}
      />
      <Segment>
        <h4>Insert New Room Location</h4>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column width={10} columns={2}>
              <input
                type='text'
                placeholder='Location Name'
                value={locationName}
                onChange={(evt) => {
                  setLocationName(evt.target.value);
                }}
              />
            </Grid.Column>
            <Grid.Column>
              <Button
                fluid
                positive
                onClick={() => {
                  if (locationName.length === 0) {
                    props.messageHandler(
                      'Location must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  insertRoomLocationButtonHandler(locationName);
                }}
              >
                Insert
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <ReactTable
        data={roomLocation}
        columns={columns}
        filterable={true}
        defaultPageSize={10}
        className='-striped -highlight'
      />
    </>
  );
};

const DialogBox = (props) => {
  let dialogBoxStyles = {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '105',
    padding: '16px 18px',
    minWidth: '260px',
    maxWidth: '300px',
    backgroundColor: 'white',
    boxShadow: '0 1px 0 rgba(12,13,14,0.1), 0 1px 6px rgba(59,64,69,0.1)',
    visibility: 'hidden',
  };

  let buttonStyle = {
    marginTop: '10px',
    fontWeight: 'bold',
    color: '#3c763d',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#dff0d8',
    display: 'inline-block',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  };

  if (props.show) {
    dialogBoxStyles.visibility = 'visible';
  }
  //editing form VVV
  const DialogBoxContent = () => {
    const [newLocationText, setNewLocation] = useState('');
    //kalo inactive berarti dia mo matiin usernya
    // if (props.dialogPurpose === 'Inactivate') {
    buttonStyle.color = '#3c763d';
    buttonStyle.backgroundColor = '#dff0d8';

    return (
      <>
        <div
          style={{
            marginTop: '10px',
            marginBottom: '10px',
            fontWeight: '600',
            textAlign: 'center',
          }}
        >
          Insert new location name
        </div>
        <input
          type='text'
          value={newLocationText}
          onChange={(evt) => {
            setNewLocation(evt.target.value);
          }}
        />
        <div
          style={buttonStyle}
          onClick={() => {
            if (newLocationText.length === 0) {
              props.messageHandler('Location must be filled', 'error', true);
              return;
            }

            props.updateRoomLocationButtonHandler(
              props.selectedRoomLocation.id,
              newLocationText
            );
          }}
        >
          {'Update'}
        </div>
      </>
    );
  };

  return <div style={dialogBoxStyles}>{DialogBoxContent()}</div>;
};

export default RoomLocation;
