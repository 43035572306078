import React, { useEffect, useState } from "react";
import Moment from "moment";
import Axios from "axios";
import ReactImageZoom from "react-image-zoom";
import notFound from "../../../../images/image-not-found.jpg";

import classes from "../NewAssetBorrowing/NewAssetBorrowing.module.css";
import classes2 from "./AssetBorrowingDetail.module.css";
import UpdateDateShift from "../../../../component/UI/UpdateDateShift/UpdateDateShift";

const AssetBorrowingDetail = (props) => {
  const prepareAssetCalibrations = (assets) => {
    let temp = [];
    if (assets) {
      for (let i = 0; i < assets.length; i++) {
        temp.push(
          assets[i].calibration === null ? 1 : assets[i].calibration.status
        );
      }
    }
    return temp;
  };

  const pad = (num, size) => {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  };

  const [currentCancellationNote, setCurrentCancellationNote] = useState(null);
  const [
    conflictingBorrowingTransactions,
    setConflictingBorrowingTransactions,
  ] = useState(null);
  const [
    conflictingAssetBorrowingTransactions,
    setConflictingAssetBorrowingTransactions,
  ] = useState(null);
  const [assetTransactions, setAssetTransactions] = useState(null);
  const [assetCalibrations, setAssetCalibrations] = useState([]);
  const [currentAssets, setCurrentAssets] = useState(null);
  const [currentCheckInShift, setCurrentCheckInShift] = useState(null);
  const [currentCheckOutShift, setCurrentCheckOutShift] = useState(null);
  const [currentActualCheckInShift, setCurrentActualCheckInShift] =
    useState(null);
  const [currentActualCheckOutShift, setCurrentActualCheckOutShift] =
    useState(null);
  const [currentCheckInNotes, setCurrentCheckInNotes] = useState(null);
  const [currentCheckOutNotes, setCurrentCheckOutNotes] = useState(null);

  const [allShifts, setAllShifts] = useState(null);

  const toggleAssetCalibrations = async (index, e) => {
    let temp = [...assetCalibrations];
    // temp[index] = a
    // setAssetCalibrations(assetCalibrations[index] => assetCalibrations[index] === 1? 0 : 1);
    temp[index] = assetCalibrations[index] === 1 ? 0 : 1;
    setAssetCalibrations(temp);
  };

  const checkInNotesChangeHandler = async (index, evt) => {
    let temp = [...currentCheckInNotes];
    temp[index] = evt.target.value;
    setCurrentCheckInNotes(temp);
  };

  const checkOutNotesChangeHandler = async (index, evt) => {
    let temp = [...currentCheckOutNotes];
    temp[index] = evt.target.value;

    setCurrentCheckOutNotes(temp);
  };

  // Fetch All Shifts
  useEffect(() => {
    const fetchAllShifts = async () => {
      props.loadingHandler(true);

      try {
        let data = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + "shifts/all"
        );

        setAllShifts(data.data.payload.data);
      } catch (error) {
        props.messageHandler("Failed to load data", true);
      }

      props.loadingHandler(false);
    };

    if (props.pic) fetchAllShifts();
  }, []);

  useEffect(() => {
    setCurrentAssets(props.assets);
    setAssetCalibrations(prepareAssetCalibrations(props.assets));
    setCurrentCheckInShift(props.checkInShift);
    setCurrentCheckOutShift(props.checkOutShift);
    setCurrentActualCheckInShift(props.actualCheckInShift);
    setCurrentActualCheckOutShift(props.actualCheckOutShift);
    setCurrentCheckInNotes(props.checkInNotes);
    setCurrentCheckOutNotes(props.checkOutNotes);

    if (props.pic && currentAssets) {
      let assetIDs = [];

      for (let i = 0; i < currentAssets.length; i++) {
        assetIDs.push(currentAssets[i].id);
      }
    }
  }, [props]);

  useEffect(() => {
    const fetchConflictingBorrowingTransaction = async () => {
      try {
        let data = await Axios.post(
          process.env.REACT_APP_DEFAULT_API +
            "staff/asset-borrowing/get-current-borrowing",
          {
            asset_ids: currentAssets,
            check_in_shift_id: currentCheckInShift.id,
            check_in_date: props.checkInDate,
            check_out_shift_id: currentCheckOutShift.id,
            check_out_date: props.checkOutDate,
          }
        );

        setConflictingBorrowingTransactions(data.data.payload.data.data);
      } catch (error) {
        props.messageHandler(
          "Failed to load existing borrowing transaction",
          "error",
          true
        );
        setConflictingBorrowingTransactions(null);
      }
    };

    const fetchConflictingAssetBorrowingTransaction = async () => {
      try {
        let data = await Axios.post(
          process.env.REACT_APP_DEFAULT_API +
            "staff/asset-borrowing/get-current-asset-borrowing",
          {
            asset_ids: currentAssets,
            check_in_shift_id: currentCheckInShift.id,
            check_in_date: props.checkInDate,
            check_out_shift_id: currentCheckOutShift.id,
            check_out_date: props.checkOutDate,
          }
        );

        setConflictingAssetBorrowingTransactions(data.data.payload.data.data);
      } catch (error) {
        props.messageHandler(
          "Failed to load existing asset borrowing transaction",
          "error",
          true
        );
        setConflictingAssetBorrowingTransactions(null);
      }
    };

    if (
      props.status === "Pending" &&
      currentAssets &&
      Object.keys(currentAssets).length !== 0 &&
      !conflictingBorrowingTransactions &&
      props.pic
    ) {
      // fetchData();
      fetchConflictingBorrowingTransaction();
      fetchConflictingAssetBorrowingTransaction();
    }
  }, [props.pic, currentAssets]);

  let shifts = "";

  if (props.shifts != null && props.shifts.length > 0) {
    shifts = props.shifts.map((item, index) => {
      return <div key={index}>{item.name}</div>;
    });
  }

  const status = () => {
    if (props.pendingBorrowing || props.detailBorrowing) {
      let style = { color: "#d3d3d3" };

      if (props.status === "Rejected") {
        style.color = "#a94442";
      } else if (props.status === "Canceled") {
        style.color = "#8a6d3b";
      } else if (props.status === "Accepted") {
        style.color = "#3c763d";
      } else if (
        props.status === "Finalized" ||
        props.status === "Check-Out Finalized" ||
        props.status === "Check-In Finalized"
      ) {
        style.color = "#0a81f0";
      } else if (
        props.status === "Absent" ||
        props.status === "Check-Out Absent" ||
        props.status === "Check-In Absent"
      ) {
        style.color = "#b41311";
      }

      return (
        <>
          {" "}
          - <span style={style}>{props.status}</span>
        </>
      );
    }

    return "";
  };

  const backButton = () => {
    if (props.detailBorrowing) {
      return (
        <span
          style={{
            color: "#0098D7",
            textDecoration: "underline",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => props.backButtonHandler()}
        >
          Back
        </span>
      );
    }
  };

  const CancelButton = () => {
    const [selection, setSelection] = useState("Change schedule");
    const [options, setOptions] = useState([]);
    useEffect(() => {
      const fetchOption = async () => {
        props.loadingHandler(true);
        try {
          let option = await Axios.get(
            process.env.REACT_APP_DEFAULT_API + "reason",
            {
              params: {
                reason_type: "Canceled",
              },
            }
          );

          setOptions(option.data);
        } catch (e) {
          props.messageHandler("Failed to load data", "error", true);
        }

        props.loadingHandler(false);
      };

      fetchOption();
    }, []);

    const cancelButtonHandler = async () => {
      props.loadingHandler(true);

      //validate selection
      if (
        selection === "" ||
        (selection !== "Change schedule" &&
          selection !== "No longer interested" &&
          selection !== "No Response until the day of borrowing")
      ) {
        props.messageHandler(
          "Please select the valid cancellation reason",
          "error",
          true
        );
        return;
      }

      if (!currentCancellationNote || currentCancellationNote.length === 0) {
        props.messageHandler(
          "Please fill the cancellation additional reason",
          "error",
          true
        );

        props.loadingHandler(false);

        return;
      }

      // Concat status dan status reason
      let currReason = `${selection} (${currentCancellationNote})`;

      try {
        let result = await Axios.patch(
          process.env.REACT_APP_DEFAULT_API + "student/cancel-asset-borrowing",
          {
            id: props.id,
            reason: currReason,
          }
        );

        if (result.data.payload.status) {
          props.messageHandler(result.data.payload.message, "success", true);
        } else {
          props.messageHandler(result.data.payload.message, "error", true);
        }

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (e) {
        props.messageHandler(
          "Unexpected error please call the administrator",
          "error",
          true
        );
      }
      props.loadingHandler(false);
    };

    //validate selection
    if (
      selection === "" ||
      (selection !== "Change schedule" &&
        selection !== "No longer interested" &&
        selection !== "No Response until the day of borrowing")
    ) {
      props.messageHandler(
        "Please select the valid cancellation reason",
        "error",
        true
      );
    }

    const selectOptionHandler = (evt) => {
      setSelection(evt.target.value);
    };

    const optionRender =
      options.length > 0
        ? options.map((option, i) => {
            return (
              <option value={option.reason_description} key={i}>
                {option.reason_description}
              </option>
            );
          })
        : "";

    return (
      <div className={classes2.SubTitle__Container}>
        <div className={classes.SubSubTitle}>Reason for cancellation</div>

        <select
          className={classes.InputText}
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={selection}
          onChange={selectOptionHandler}
        >
          {optionRender}
        </select>

        <div className={classes.SubSubSubTitle}>Additional Reason</div>
        <textarea
          className={classes.InputText}
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={currentCancellationNote}
          onChange={(e) => setCurrentCancellationNote(e.target.value)}
        ></textarea>

        <span
          style={{
            display: "inline-block",
            padding: "14px 16px",
            backgroundColor: "#fcf8e3",
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#8a6d3b",
            borderColor: "#faebcc",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
          }}
          onClick={cancelButtonHandler}
        >
          Cancel Asset Borrowing Request
        </span>
      </div>
    );
  };

  let checkInShiftDetail = currentCheckInShift
    ? currentCheckInShift.shift_name
      ? currentCheckInShift.shift_name +
        " (" +
        currentCheckInShift.shift_start_time +
        " - " +
        currentCheckInShift.shift_end_time +
        ")"
      : currentCheckInShift.name
    : "";

  let checkOutShiftDetail = currentCheckOutShift
    ? currentCheckOutShift.shift_name
      ? currentCheckOutShift.shift_name +
        " (" +
        currentCheckOutShift.shift_start_time +
        " - " +
        currentCheckOutShift.shift_end_time +
        ")"
      : currentCheckOutShift.name
    : "";

  let noNoteStatus =
    props.status !== "Pending" &&
    props.status !== "Canceled" &&
    props.status !== "Rejected";
  let absentStatus =
    props.status === "Check-Out Absent" || props.status === "Check-In Absent";

  let lateStatus =
    props.status === "Check-Out Late" || props.status === "Check-In Late";

  let canceledStatus =
    props.status === "Check-Out Canceled" ||
    props.status === "Check-In Canceled";

  const handleDownloadPDF = () => {
    console.log("Download PDF");
  };

  const getAssetImage = (asset) => {
    if (asset.image && Array.isArray(asset.image)) {
      return asset.images[0].path ? asset.images[0].path : notFound;
    }
    return notFound;
  };

  return (
    <div className={classes.BorrowingDataContainer}>
      {backButton()}
      <div className={classes.Title} style={{ lineHeight: "20pt" }}>
        Asset Borrowing Detail
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          className={classes.SubTitle__Container}
          style={{
            minWidth: "25%",
          }}
        >
          <div className={classes.SubTitle}>
            {Moment(props.checkOutDate, "YYYY-MM-DD").format(
              "ddd, DD MMMM YYYY"
            )}
            {status()}
            <div className={classes.SubSubTitle}>
              {props.id ? props.id : ""}
            </div>
          </div>
        </div>
        {/* <div>
          <button className={classes2.Download__Btn} onClick={handleDownloadPDF}>
            Download PDF
          </button>
        </div> */}
      </div>
      <div className={classes2.DataContainer}>
        <div className={classes2.SubTitle__Container}>
          <div className={classes.SubTitle}>
            Borrower
            {props.newBorrowing ? (
              <span
                style={{
                  color: "#0098D7",
                  cursor: "pointer",
                  fontSize: "11pt",
                  marginLeft: "10pt",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  props.changeStateHandler(6);
                }}
              >
                Edit
              </span>
            ) : (
              ""
            )}
          </div>
          <div className={classes2.SubSubTitle__Container}>
            <div className={classes.SubSubTitle}>Name</div>
            <p className={classes.SubDescription}>
              {props.user ? props.user.name : props.userData.name}
            </p>
            <div className={classes.SubSubTitle}>Email</div>
            <p className={classes.SubDescription}>
              {props.user ? props.user.email : props.userData.email}
            </p>
            <div className={classes.SubSubTitle}>Phone Number</div>
            <p className={classes.SubDescription}>{props.userData.phone}</p>
            <div className={classes.SubSubTitle}>Purpose</div>
            <p className={classes.SubDescription}>{props.userData.purpose}</p>
          </div>
        </div>

        <div className={classes2.SubTitle__Container__Asset}>
          <div className={classes.SubTitle}>
            Assets Detail
            {props.newBorrowing ? (
              <span
                style={{
                  color: "#0098D7",
                  cursor: "pointer",
                  fontSize: "11pt",
                  marginLeft: "10pt",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  props.changeStateHandler(4);
                }}
              >
                Edit
              </span>
            ) : (
              ""
            )}
          </div>
          {false && (
            <div className={classes.SubSubTitle__Container}>
              {currentAssets.map((asset, idx) => {
                const imageProps = {
                  width: 80,
                  height: 80,
                  zoomPosition: "original",
                  img: getAssetImage(asset),
                };

                return (
                  <div className={classes2.FlexContainer} key={idx}>
                    <ReactImageZoom {...imageProps} />
                    <div className={classes.SubSubTitle__Container}>
                      <small>{asset.room.room_code}</small>
                      <div className={classes.SubSubTitle}>
                        {`LCAS${pad(asset.id, 5)}`}
                      </div>
                      <p className={classes.SubDescription}>{asset.name}</p>
                    </div>
                    {props.status === "Accepted" && (
                      <input type="checkbox"></input>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          <table className={classes2.AssetTable} style={{ marginTop: "1rem" }}>
            <tr>
              <th className={classes2.AssetTable}>Preview</th>
              <th className={classes2.AssetTable}>Detail</th>
              <th
                className={classes2.AssetTable}
                // colSpan={2}
                style={{ padding: "2px" }}
              >
                <span style={{ display: "block" }}>Calibration</span>
                Status
              </th>
              {props.pic && noNoteStatus && (
                <th
                  className={classes2.AssetTable}
                  style={{ marginLeft: "1rem" }}
                >
                  Check Out Note (Issues)
                </th>
              )}
              {props.pic && noNoteStatus && (
                <th
                  className={classes2.AssetTable}
                  style={{ marginLeft: "1rem" }}
                >
                  Check In Note (Issues)
                </th>
              )}
            </tr>
            {currentAssets &&
              Object.keys(currentAssets).length !== 0 &&
              currentAssets.map((asset, index) => {
                const imageProps = {
                  width: 80,
                  height: 80,
                  zoomPosition: "original",
                  img: getAssetImage(asset),
                };

                return (
                  <tr key={index}>
                    <td className={classes2.AssetTable}>
                      <ReactImageZoom {...imageProps} />
                    </td>
                    <td
                      className={classes2.AssetTable}
                      style={{
                        color: "#5e5e5e",
                        padding: "5px",
                      }}
                    >
                      <small>{asset.room.room_code}</small>
                      <div>{`LCAS${pad(asset.id, 5)}`}</div>
                      <span>{asset.name}</span>
                    </td>
                    {props.checkOutStatus && !props.checkInStatus ? (
                      <td
                        className={classes2.AssetTable}
                        style={{ color: "#5e5e5e" }}
                      >
                        <input
                          type="checkbox"
                          checked={assetCalibrations[index] === 1}
                          onChange={(e) => toggleAssetCalibrations(index, e)}
                        ></input>
                      </td>
                    ) : (
                      <td
                        className={classes2.AssetTable}
                        style={{
                          color: "#5e5e5e",
                          display: "flex",
                          flexWrap: "wrap",
                          alignItems: "center",
                          paddingTop: "1.5rem",
                        }}
                      >
                        <i
                          style={
                            assetCalibrations[index] === 1
                              ? { color: "green" }
                              : { color: "red" }
                          }
                          className="material-icons"
                        >
                          {assetCalibrations[index] === 1
                            ? "check_circle"
                            : "cancel"}
                        </i>

                        <div
                          title="Current asset usability"
                          style={{ marginLeft: "1rem" }}
                        >
                          <i
                            className="material-icons"
                            title="Calibrate current asset usability"
                            style={{
                              color: "#0098D7",
                              verticalAlign: "middle",
                            }}
                          >
                            info
                          </i>
                        </div>
                      </td>
                    )}
                    {/* {(props.status !== "Accepted" || !props.checkInStatus) && (
                      <td
                        className={classes2.AssetTable}
                        style={{ color: "#5e5e5e" }}
                      >
                        <i
                          style={
                            assetCalibrations[index] === 1
                              ? { color: "green" }
                              : { color: "red" }
                          }
                          className="material-icons"
                        >
                          {assetCalibrations[index] === 1
                            ? "check_circle"
                            : "cancel"}
                        </i>
                      </td>
                    )} */}

                    {noNoteStatus && currentCheckOutNotes && (
                      <td
                        className={classes2.AssetTable}
                        style={{
                          color: "#5e5e5e",
                          padding: "5px",
                          minWidth: "80px",
                        }}
                      >
                        <textarea
                          value={currentCheckOutNotes[index]}
                          style={{ fontWeight: "normal" }}
                          disabled={
                            props.checkOutStatus ||
                            absentStatus ||
                            lateStatus ||
                            canceledStatus
                          }
                          onChange={(e) => checkOutNotesChangeHandler(index, e)}
                          className={classes.InputText}
                        ></textarea>
                      </td>
                    )}

                    {noNoteStatus && currentCheckInNotes && (
                      <td
                        className={classes2.AssetTable}
                        style={{
                          color: "#5e5e5e",
                          padding: "5px",
                          // minWidth: "80px",
                        }}
                      >
                        <textarea
                          value={currentCheckInNotes[index]}
                          style={{ fontWeight: "normal" }}
                          disabled={
                            !props.checkOutStatus ||
                            props.checkInStatus ||
                            absentStatus ||
                            lateStatus ||
                            canceledStatus
                          }
                          onChange={(e) => checkInNotesChangeHandler(index, e)}
                          className={classes.InputText}
                        ></textarea>
                      </td>
                    )}
                  </tr>
                );
              })}
          </table>
        </div>

        <div className={classes2.SubTitle__Container}>
          <div className={classes.SubTitle}>
            Room Detail
            {props.newBorrowing ? (
              <span
                style={{
                  color: "#0098D7",
                  cursor: "pointer",
                  fontSize: "11pt",
                  marginLeft: "10pt",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  props.changeStateHandler(3);
                }}
              >
                Edit
              </span>
            ) : (
              ""
            )}
          </div>

          <div className={classes.SubSubTitle__Container}>
            <div className={classes.SubSubTitle}>Room Type</div>
            <p className={classes.SubDescription}>
              {props.room.room_type
                ? props.room.room_type.room_type_name
                : props.room.type
                ? props.room.type.name
                : ""}
            </p>
            <div className={classes.SubSubTitle}>Room Code</div>
            <div className={classes.SubDescription}>{props.room.room_code}</div>
          </div>

          <div style={{ marginTop: "1.5rem" }} className={classes.SubTitle}>
            Check Out / In Details
            {props.newBorrowing ? (
              <span
                style={{
                  color: "#0098D7",
                  cursor: "pointer",
                  fontSize: "11pt",
                  marginLeft: "10pt",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  props.changeStateHandler(5);
                }}
              >
                Edit
              </span>
            ) : (
              ""
            )}
          </div>

          <div className={classes.SubSubTitle__Container}>
            <div className={classes.SubSubTitle}>Check Out</div>
            <p className={classes.SubDescription}>
              <b>Date:</b> {props.checkOutDate}
            </p>
            {currentCheckInShift && (
              <p className={classes.SubDescription}>
                <b>Shift:</b> {checkOutShiftDetail}
              </p>
            )}
            <div className={classes.SubSubTitle}>Check In</div>
            <p className={classes.SubDescription}>
              <b>Date:</b> {props.checkInDate}
            </p>
            {currentCheckOutShift && (
              <p className={classes.SubDescription}>
                <b>Shift:</b> {checkInShiftDetail}
              </p>
            )}
          </div>
        </div>

        {props.borrower && props.status === "Pending" && <CancelButton />}
        {props.pic ? (
          <PicButton
            pic={props.pic}
            allShifts={allShifts}
            status={props.status}
            serverTime={props.serverTime}
            id={props.id}
            checkInID={props.checkInID}
            checkOutID={props.checkOutID}
            checkInStatus={props.checkInStatus}
            checkOutStatus={props.checkOutStatus}
            checkOutDate={props.checkOutDate}
            checkInDate={props.checkInDate}
            checkInShift={currentCheckInShift}
            checkOutShift={currentCheckOutShift}
            checkInNote={props.checkInNote}
            checkOutNote={props.checkOutNote}
            actualCheckInShift={currentActualCheckInShift}
            actualCheckOutShift={currentActualCheckOutShift}
            date={props.date}
            leaderId={
              props.user
                ? props.user.username
                : props.userData.student_nim_leader
            }
            currentAssets={currentAssets}
            assetTransactions={assetTransactions}
            assetCalibrations={assetCalibrations}
            currentCheckInNotes={currentCheckInNotes}
            currentCheckOutNotes={currentCheckOutNotes}
            loadingHandler={props.loadingHandler}
            messageHandler={props.messageHandler}
            acceptButtonHandler={props.acceptButtonHandler}
            rejectButtonHandler={props.rejectButtonHandler}
            finalizeCheckInButtonHandler={props.finalizeCheckInButtonHandler}
            finalizeCheckOutButtonHandler={props.finalizeCheckOutButtonHandler}
            conflictingBorrowingTransactions={conflictingBorrowingTransactions}
            conflictingAssetBorrowingTransactions={
              conflictingAssetBorrowingTransactions
            }
          />
        ) : (
          ""
        )}
      </div>
      {props.newBorrowing ? <BackNextButton {...props} /> : ""}
    </div>
  );
};

const BackNextButton = (props) => {
  const backButtonHandler = () => {
    props.changeStateHandler(6);
  };

  const nextButtonHandler = () => {
    // props.changeStateHandler(5);
    props.submitFormHandler();
  };

  return (
    <div className={classes.ButtonContainer__SpaceBetween}>
      <div className={classes.BackButton} onClick={backButtonHandler}>
        <i className="material-icons" style={{ marginRight: "8px" }}>
          arrow_back
        </i>
        Back
      </div>
      <div className={classes.NextButton} onClick={nextButtonHandler}>
        Submit
        <i className="material-icons" style={{ marginLeft: "8px" }}>
          arrow_forward
        </i>
      </div>
    </div>
  );
};

const PicOtherButton = (props) => {
  const [currID, setCurrID] = useState(null);
  const [selectedCheckOutDate, setSelectedCheckOutDate] = useState(null);
  const [selectedCheckInDate, setSelectedCheckInDate] = useState(null);
  const [selectedCheckOutShiftID, setSelectedCheckOutShiftID] = useState(null);
  const [selectedCheckInShiftID, setSelectedCheckInShiftID] = useState(null);
  const [currentAssets, setCurrentAssets] = useState(null);
  const [existingBorrowingTransactions, setExistingBorrowingTransactions] =
    useState(null);
  const [
    existingAssetBorrowingTransactions,
    setExistingAssetBorrowingTransactions,
  ] = useState(null);

  const fetchExistingBorrowingTransactions = async () => {
    props.loadingHandler(true);
    try {
      let data = await Axios.post(
        process.env.REACT_APP_DEFAULT_API +
          "staff/asset-borrowing/get-current-borrowing",
        {
          asset_ids: currentAssets,
          check_out_shift_id: selectedCheckOutShiftID,
          check_out_date: selectedCheckOutDate,
          check_in_shift_id: selectedCheckInShiftID,
          check_in_date: selectedCheckInDate,
        }
      );

      setExistingBorrowingTransactions(data.data.payload.data.data);
    } catch (error) {
      props.messageHandler(
        "Failed to load existing borrowing transaction",
        "error",
        true
      );
      setExistingBorrowingTransactions(null);
    }

    props.loadingHandler(false);
  };

  const fetchExistingAssetBorrowingTransactions = async () => {
    props.loadingHandler(true);
    try {
      let data = await Axios.post(
        process.env.REACT_APP_DEFAULT_API +
          "staff/asset-borrowing/get-current-asset-borrowing",
        {
          asset_ids: currentAssets,
          check_out_shift_id: selectedCheckOutShiftID,
          check_out_date: selectedCheckOutDate,
          check_in_shift_id: selectedCheckInShiftID,
          check_in_date: selectedCheckInDate,
        }
      );

      let currTransactions = data.data.payload.data.data;

      setExistingAssetBorrowingTransactions(
        currTransactions.filter((transaction) => transaction.id !== currID)
      );
    } catch (error) {
      props.messageHandler(
        "Failed to load existing asset borrowing transaction",
        "error",
        true
      );
      setExistingAssetBorrowingTransactions(null);
    }

    props.loadingHandler(false);
  };

  useEffect(() => {
    setCurrID(props.id);
    setSelectedCheckInDate(props.checkInDate);
    setSelectedCheckOutDate(props.checkOutDate);
    if (props.checkInShift) setSelectedCheckInShiftID(props.checkInShift.id);
    if (props.checkOutShift) setSelectedCheckOutShiftID(props.checkOutShift.id);
    setCurrentAssets(props.currentAssets);
  }, [props]);

  useEffect(() => {
    if (
      currentAssets &&
      selectedCheckInDate &&
      selectedCheckOutDate &&
      selectedCheckOutShiftID &&
      selectedCheckInShiftID
    ) {
      if (currentAssets && currentAssets.length !== 0) {
        fetchExistingBorrowingTransactions();
        fetchExistingAssetBorrowingTransactions();
      }
    }

    props.loadingHandler(false);
  }, [
    currentAssets,
    selectedCheckInDate,
    selectedCheckInShiftID,
    selectedCheckOutDate,
    selectedCheckOutShiftID,
  ]);

  const updateDateShiftHandler = async (type) => {
    if (!currID || currID.length === 0) {
      props.messageHandler("ID is empty!", "error", true);
      return;
    }

    if (!selectedCheckInDate || selectedCheckInDate.length === 0) {
      props.messageHandler("Date must be filled!", "error", true);
      return;
    }

    if (!selectedCheckInShiftID) {
      props.messageHandler("Shift must be selected!", "error", true);
      return;
    }

    if (
      selectedCheckInDate < selectedCheckOutDate ||
      (selectedCheckInDate === selectedCheckOutDate &&
        selectedCheckInShiftID < selectedCheckOutShiftID)
    ) {
      props.messageHandler(
        "Check in date and shift must be after check out date and shift",
        "error",
        true
      );
      return;
    }

    const data =
      type === "check-out"
        ? {
            id: currID,
            check_out_date: selectedCheckOutDate,
            check_out_shift_id: selectedCheckOutShiftID,
          }
        : {
            id: currID,
            check_in_date: selectedCheckInDate,
            check_in_shift_id: selectedCheckInShiftID,
          };

    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API +
          "staff/update-asset-borrowing-date-shift/" +
          type,
        data
      );

      if (result.data.payload.status) {
        props.messageHandler(result.data.payload.message, "success", true);
      } else {
        props.messageHandler(result.data.payload.message, "error", true);
      }

      setTimeout(() => {
        props.loadingHandler(false);
        window.location.reload();
      }, 1000);
    } catch (e) {
      props.messageHandler(
        "Unexpected error please call the administrator",
        "error",
        true
      );
    }
    props.loadingHandler(false);
  };
  let renderPicButton;
  renderPicButton =
    !props.checkOutStatus ||
    props.status === "Check-Out Absent" ||
    props.status === "Check-Out Canceled"
      ? [
          <CheckInOutDetails
            key={"check-out"}
            type={"check-out"}
            id={props.id}
            allShifts={props.allShifts}
            checkOutID={props.checkOutID}
            checkOutStatus={props.checkOutStatus}
            checkOutShift={props.checkOutShift}
            checkOutNote={props.checkOutNote}
            actualCheckOutShift={props.actualCheckOutShift}
            finalizeCheckOutButtonHandler={props.finalizeCheckOutButtonHandler}
            currentCheckOutNotes={props.currentCheckOutNotes}
            currentAssets={props.currentAssets}
            assetCalibrations={props.assetCalibrations}
          />,
        ]
      : [
          <CheckInOutDetails
            key={"check-out"}
            type={"check-out"}
            id={props.id}
            allShifts={props.allShifts}
            checkOutID={props.checkOutID}
            checkOutStatus={props.checkOutStatus}
            checkOutShift={props.checkOutShift}
            checkOutNote={props.checkOutNote}
            actualCheckOutShift={props.actualCheckOutShift}
            finalizeCheckOutButtonHandler={props.finalizeCheckOutButtonHandler}
            currentCheckOutNotes={props.currentCheckOutNotes}
            currentAssets={props.currentAssets}
            assetCalibrations={props.assetCalibrations}
          />,
          <CheckInOutDetails
            key={"check-in"}
            type={"check-in"}
            id={props.id}
            allShifts={props.allShifts}
            checkInID={props.checkInID}
            checkInStatus={props.checkInStatus}
            checkInShift={props.checkInShift}
            checkInNote={props.checkInNote}
            actualCheckInShift={props.actualCheckInShift}
            finalizeCheckInButtonHandler={props.finalizeCheckInButtonHandler}
            currentCheckInNotes={props.currentCheckInNotes}
            currentAssets={props.currentAssets}
            assetCalibrations={props.assetCalibrations}
          />,
        ];

  if (props.status === "Accepted") {
    renderPicButton.push([
      <ExistingTransactions
        existingBorrowingTransactions={existingBorrowingTransactions}
        existingAssetBorrowingTransactions={existingAssetBorrowingTransactions}
      />,
      <UpdateDateShift
        key={"check-out-dateshift"}
        type={"check-out"}
        selectedDate={selectedCheckOutDate}
        dateHandler={setSelectedCheckOutDate}
        allShifts={props.allShifts}
        selectedShiftID={selectedCheckOutShiftID}
        setSelectedShiftID={setSelectedCheckOutShiftID}
        submitButtonHandler={updateDateShiftHandler.bind(this, "check-out")}
      />,
      <UpdateDateShift
        key={"check-in-dateshift"}
        type={"check-in"}
        selectedDate={selectedCheckInDate}
        dateHandler={setSelectedCheckInDate}
        allShifts={props.allShifts}
        selectedShiftID={selectedCheckInShiftID}
        setSelectedShiftID={setSelectedCheckInShiftID}
        submitButtonHandler={updateDateShiftHandler.bind(this, "check-in")}
      />,
    ]);
  } else if (props.status === "Check-Out Finalized") {
    renderPicButton.push([
      <ExistingTransactions
        existingBorrowingTransactions={existingBorrowingTransactions}
        existingAssetBorrowingTransactions={existingAssetBorrowingTransactions}
      />,
      <UpdateDateShift
        key={"check-in-dateshift"}
        type={"check-in"}
        selectedDate={selectedCheckInDate}
        dateHandler={setSelectedCheckInDate}
        allShifts={props.allShifts}
        selectedShiftID={selectedCheckInShiftID}
        setSelectedShiftID={setSelectedCheckInShiftID}
        submitButtonHandler={updateDateShiftHandler.bind(this, "check-in")}
      />,
    ]);
  }
  return renderPicButton;
};

const PicPendingButton = (props) => {
  const [reason, setReason] = useState("");

  const [options, setOptions] = useState([]);
  const reasonChangeHandler = (evt) => {
    setReason(evt.target.value);
  };
  let currentConflictingBorrowings =
    !props.conflictingBorrowingTransactions ||
    props.conflictingBorrowingTransactions.length === 0 ? (
      <span>There is no conflicting borrowing transaction.</span>
    ) : (
      props.conflictingBorrowingTransactions.map((item, idx) => {
        return (
          <li key={idx}>
            There is a conflicting borrowing transaction with ID{" "}
            <strong>{item.id}</strong> (at room{" "}
            <strong>{item.room.room_code}</strong>) on{" "}
            <strong>{item.date}</strong> with borrowing shifts:
            <ul>
              {item.detail_shifts.map((shift, idx) => {
                return <li key={idx}>{shift.name}</li>;
              })}
            </ul>
          </li>
        );
      })
    );

  let currentConflictingAssetBorrowings =
    !props.conflictingAssetBorrowingTransactions ||
    props.conflictingAssetBorrowingTransactions.length === 0 ? (
      <span>There is no conflicting asset borrowing transaction.</span>
    ) : (
      props.conflictingAssetBorrowingTransactions.map((item, idx) => {
        return (
          <li key={idx}>
            There is a conflicting asset borrowing transaction with ID{" "}
            <strong>{item.id}</strong> with details:
            <ul>
              <li>
                <strong>Check Out Date: </strong>
                {item.check_out_date}
              </li>
              <li>
                <strong>Check Out Shift: </strong>
                {item.check_out_shift.shift_name}
              </li>
              <li>
                <strong>Check In Date: </strong>
                {item.check_in_date}
              </li>
              <li>
                <strong>Check In Shift: </strong>
                {item.check_in_shift.shift_name}
              </li>
            </ul>
          </li>
        );
      })
    );

  const [selection, setSelection] = useState("Day off");

  useEffect(() => {
    const fetchOption = async () => {
      props.loadingHandler(true);
      try {
        let option = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + "reason",
          {
            params: {
              reason_type: "Rejected",
            },
          }
        );

        setOptions(option.data);
      } catch (e) {
        props.messageHandler("Failed to load data", "error", true);
      }
      props.loadingHandler(false);
    };

    fetchOption();
  }, []);

  const selectOptionHandler = (evt) => {
    setSelection(evt.target.value);
  };

  const optionRender =
    options.length > 0
      ? options.map((option, i) => {
          return (
            <option value={option.reason_description} key={i}>
              {option.reason_description}
            </option>
          );
        })
      : "";

  const submitButtonHandler = () => {
    //kodi
    let result = selection;
    if (reason) {
      result += " (" + reason + ")";
    } else {
      //kalo dia ga isi reason
      result = "ISI DONG REASONNYA";
    }
    props.rejectButtonHandler(props.id, result);
  };

  //acc / reject
  return (
    <div
      className={classes2.SubTitle__Container}
      // style={{ maxWidth: "25rem" }}
    >
      <div className={classes.SubTitle}>Action</div>
      <div className={classes.SubSubTitle__Container}>
        <div className={classes.SubSubTitle}>Asset Transaction</div>
        <div className={classes.SubSubSubTitle}>
          Existing Borrowing Transaction
        </div>
        <div>{currentConflictingBorrowings}</div>
        <div className={classes.SubSubSubTitle}>
          Existing Asset Borrowing Transaction
        </div>
        <div>{currentConflictingAssetBorrowings}</div>
        <span
          style={{
            display: "inline-block",
            padding: "14px 16px",
            backgroundColor: "#dff0d8",
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#3c763d",
            borderColor: "#d6e9c6",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
            marginBottom: "10px",
            marginTop: "10px",
          }}
          onClick={() => props.acceptButtonHandler(props.id)}
        >
          Accept
        </span>

        <div className={classes.SubSubTitle}>Reason for Rejection</div>
        <select
          className={classes.InputText}
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={selection}
          onChange={selectOptionHandler}
        >
          {optionRender}
        </select>

        <div className={classes.SubSubSubTitle}>Additional Note</div>
        <textarea
          style={{ margin: "1rem 0rem" }}
          className={classes.InputText}
          value={reason}
          onChange={reasonChangeHandler}
        />

        <span
          style={{
            display: "inline-block",
            padding: "14px 16px",
            backgroundColor: "#f2dede",
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#a94442",
            borderColor: "#ebccd1",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
          }}
          onClick={submitButtonHandler} //kodi
          // onClick={() => props.rejectButtonHandler(props.id, reason)}//kodi
        >
          Reject
        </span>
      </div>
    </div>
  );
};

const PicButton = (props) => {
  const condition =
    props.status === "Accepted" ||
    props.status === "Check-Out Finalized" ||
    props.status === "Check-In Finalized" ||
    props.status === "Check-Out Absent" ||
    props.status === "Check-In Absent" ||
    props.status === "Check-Out Canceled" ||
    props.status === "Check-In Canceled";
  const render = props.status === "Pending" ? <PicPendingButton {...props} /> : condition ? <PicOtherButton  {...props}/> : null;


  return render;
};

const ExistingTransactions = (props) => {
  let currentExistingRoomBorrowings =
    !props.existingBorrowingTransactions ||
    props.existingBorrowingTransactions.length === 0 ? (
      <span>There is no conflicting borrowing transaction.</span>
    ) : (
      props.existingBorrowingTransactions.map((item, idx) => {
        return (
          <li key={idx}>
            There is a conflicting borrowing transaction with ID{" "}
            <strong>{item.id}</strong> (at room{" "}
            <strong>{item.room.room_code}</strong>) on{" "}
            <strong>{item.date}</strong> with borrowing shifts:
            <ul>
              {item.detail_shifts.map((shift, idx) => {
                return <li key={idx}>{shift.name}</li>;
              })}
            </ul>
          </li>
        );
      })
    );

  let currentExistingAssetBorrowings =
    !props.existingAssetBorrowingTransactions ||
    props.existingAssetBorrowingTransactions.length === 0 ? (
      <span>There is no conflicting asset borrowing transaction.</span>
    ) : (
      props.existingAssetBorrowingTransactions.map((item, idx) => {
        return (
          <li key={idx}>
            There is a conflicting asset borrowing transaction with ID{" "}
            <strong>{item.id}</strong> with details:
            <ul>
              <li>
                <strong>Check Out Date: </strong>
                {item.check_out_date}
              </li>
              <li>
                <strong>Check Out Shift: </strong>
                {item.check_out_shift.shift_name}
              </li>
              <li>
                <strong>Check In Date: </strong>
                {item.check_in_date}
              </li>
              <li>
                <strong>Check In Shift: </strong>
                {item.check_in_shift.shift_name}
              </li>
            </ul>
          </li>
        );
      })
    );

  return (
    <div className={classes2.SubTitle__Container}>
      <div className={classes.SubTitle}>Existing Transactions</div>
      <div className={classes.SubSubSubTitle}>
        Existing Borrowing Transaction
      </div>
      <div>{currentExistingRoomBorrowings}</div>
      <div className={classes.SubSubSubTitle}>
        Existing Asset Borrowing Transaction
      </div>
      <div>{currentExistingAssetBorrowings}</div>
    </div>
  );
};

const CheckInOutDetails = (props) => {
  const [selectStatus, setSelectStatus] = useState("present");
  const [description, setDescription] = useState("");
  const [allShifts, setAllShifts] = useState(null);

  const [currSelectedShift, setCurrSelectedShift] = useState(null);
  const [currentCheckInNotes, setCurrentCheckInNotes] = useState(null);
  const [currentCheckOutNotes, setCurrentCheckOutNotes] = useState(null);
  const [currentActualCheckInShift, setCurrentActualCheckInShift] =
    useState(null);
  const [currentActualCheckOutShift, setCurrentActualCheckOutShift] =
    useState(null);

  useEffect(() => {
    setAllShifts(props.allShifts);
    setCurrentCheckInNotes(props.currentCheckInNotes);
    setCurrentCheckOutNotes(props.currentCheckOutNotes);
    setCurrentActualCheckInShift(props.actualCheckInShift);
    setCurrentActualCheckOutShift(props.actualCheckOutShift);

    if (props.allShifts) setCurrSelectedShift(props.allShifts[0].id);
  }, [props]);

  let realizationStatus =
    props.type === "check-in" ? props.checkInStatus : props.checkOutStatus;

  let isNotFinalized =
    (props.type === "check-in" && !props.checkInStatus) ||
    (props.type === "check-out" && !props.checkOutStatus);

  let shiftIsNotSet =
    (props.type === "check-in" && !currentActualCheckInShift) ||
    (props.type === "check-out" && !currentActualCheckOutShift);

  let buttonHandler =
    props.type === "check-out"
      ? () =>
          props.finalizeCheckOutButtonHandler(
            props.id,
            props.checkOutID,
            currSelectedShift,
            selectStatus,
            description,
            currentCheckOutNotes
            // props.currentAssets
            // props.assetCalibrations
          )
      : () =>
          props.finalizeCheckInButtonHandler(
            props.id,
            props.checkInID,
            currSelectedShift,
            selectStatus,
            description,
            currentCheckInNotes,
            props.currentAssets,
            props.assetCalibrations
          );
  return (
    <div className={classes2.SubTitle__Container}>
      <div className={classes.SubTitle}>
        {props.type === "check-out" ? "Check Out" : "Check In"} Action
      </div>
      <div className={classes.SubSubTitle__Container}>
        <div className={classes.SubSubTitle} style={{ margin: "1rem 0rem" }}>
          Realization
        </div>
        {isNotFinalized ? (
          <select
            value={selectStatus}
            className={classes.InputText}
            onChange={(evt) => {
              setSelectStatus(evt.target.value);
            }}
          >
            <option value="present">Present</option>
            <option value="late">Late</option>
            <option value="absent">Absent</option>
            {props.type === "check-out" && (
              <option value="canceled">Canceled</option>
            )}
          </select>
        ) : (
          <p className={classes.SubDescription}>{realizationStatus}</p>
        )}

        <div className={classes.SubSubTitle} style={{ margin: "1rem 0rem" }}>
          Shift
        </div>
        {shiftIsNotSet ? (
          <select
            className={classes.InputText}
            value={currSelectedShift ? currSelectedShift : ""}
            onChange={(evt) => {
              setCurrSelectedShift(evt.target.value);
            }}
          >
            {allShifts &&
              allShifts.map((shift, i) => {
                return (
                  <option key={i} value={shift.id}>
                    {shift.name}
                  </option>
                );
              })}
          </select>
        ) : props.type === "check-in" ? (
          <p className={classes.SubDescription}>
            {currentActualCheckInShift.shift_name} (
            {currentActualCheckInShift.shift_start_time} -{" "}
            {currentActualCheckInShift.shift_end_time})
          </p>
        ) : (
          <p className={classes.SubDescription}>
            {currentActualCheckOutShift.shift_name} (
            {currentActualCheckOutShift.shift_start_time} -{" "}
            {currentActualCheckOutShift.shift_end_time})
          </p>
        )}
        <div className={classes.SubSubTitle} style={{ margin: "1rem 0rem" }}>
          Note
        </div>
        {isNotFinalized ? (
          <textarea
            className={classes.InputText}
            onChange={(evt) => {
              setDescription(evt.target.value);
            }}
          ></textarea>
        ) : (
          <p className={classes.SubDescription}>
            {props.type === "check-in" ? props.checkInNote : props.checkOutNote}
          </p>
        )}
      </div>
      <div className={classes.SubSubTitle__Container}>
        {isNotFinalized && (
          <span
            style={{
              display: "inline-block",
              padding: "14px 16px",
              backgroundColor: "#dff0d8",
              textDecoration: "underline",
              fontWeight: "bold",
              color: "#3c763d",
              borderColor: "#d6e9c6",
              cursor: "pointer",
              width: "100%",
              textAlign: "center",
            }}
            onClick={buttonHandler}
          >
            Finalize
          </span>
        )}
      </div>
    </div>
  );
};

const FinalizeData = (props) => {
  let style = { color: "#a94442", fontWeight: "bold", fontSize: "12pt" };

  if (props.finalizeData.status === "Absent") {
    style.color = "#a94442";
  } else if (props.finalizeData.status === "Canceled") {
    style.color = "#8a6d3b";
  } else if (props.finalizeData.status === "Present") {
    style.color = "#3c763d";
  }

  return (
    <div className={classes2.SubTitle__Container}>
      <div className={classes.SubTitle}>Finalize Data</div>
      <div className={classes.SubSubTitle__Container}>
        <div className={classes.SubSubTitle}>Attendance Status</div>
        <span style={{ ...style, marginLeft: "10px" }}>
          {props.finalizeData.status}
        </span>
        <div className={classes.SubSubTitle}>Finalize Note</div>
        <span style={{ marginLeft: "10px" }}>
          {props.finalizeData.description}
        </span>
      </div>
    </div>
  );
};

export default AssetBorrowingDetail;
