import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import ReactTable from 'react-table';

import 'react-table/react-table.css';
import './CustomReactTable.css';

const Feedback = (props) => {
  const [feedback, setFeedback] = useState([]);

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        props.loadingHandler(true);
        let data = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + 'admin/feedback/all'
        );
        if (data.data.payload.data && data.data.payload.status) {
          setFeedback(data.data.payload.data);
        }
      } catch (e) {
        props.messageHandler(
          'Unexpected error please call the administrator',
          'error',
          true
        );
      }

      props.loadingHandler(false);
    };

    fetchStudent();
  }, []);

  const columns = [
    {
      Header: 'Reporter name',
      accessor: 'reporter_name',
    },
    {
      Header: 'Feedback',
      accessor: 'message',
    },
    {
      Header: 'Reporting date',
      accessor: 'created_at',
    },
  ];

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <ReactTable
        data={feedback}
        columns={columns}
        filterable={true}
        defaultPageSize={10}
        className='-striped -highlight'
      />
    </div>
  );
};

export default Feedback;
