import React from 'react';
import Moment from 'moment';

import classes from './AssetBorrowingList.module.css';

const AssetBorrowingList = (props) => {
  // eslint-disable-next-line
  const { check_out_date, status, student_email } = props.data;
  const room = props.data.check_in.details[0].asset.room;

  let statusClasses = [classes.HeadContainer_Status];

  let containerClasses = [classes.Container];

  if (status === 'Accepted') {
    statusClasses.push(classes.HeadContainer_Status__Accepted);
    containerClasses.push(classes.Container__Accepted);
  } else if (status === 'Rejected') {
    statusClasses.push(classes.HeadContainer_Status__Rejected);
    containerClasses.push(classes.Container__Rejected);
  } else if (status === 'Canceled') {
    statusClasses.push(classes.HeadContainer_Status__Canceled);
    containerClasses.push(classes.Container__Canceled);
  } else if (status === "Absent" || status === "Check-Out Absent" || status === "Check-In Absent") {
    statusClasses.push(classes.HeadContainer_Status__Absent);
    containerClasses.push(classes.Container__Absent);
  } else if (status === "Finalized" || status === "Check-Out Finalized" || status === "Check-In Finalized") {
    statusClasses.push(classes.HeadContainer_Status__Finalized);
    containerClasses.push(classes.Container__Finalized);
  } else {
    statusClasses.push(classes.HeadContainer_Status__Pending);
    containerClasses.push(classes.Container__Pending);
  }

  return (
    <div
      className={containerClasses.join(' ')}
      onClick={() => props.onClickHandler(props.data)}
    >
      <div className={classes.HeadContainer}>
        <div style={{ fontWeight: 'bold', fontSize: '11pt' }}>
          {Moment(check_out_date, 'YYYY-MM-DD').format('ddd, DD MMMM YYYY')}
        </div>
        <div>
          <span className={statusClasses.join(' ')}>{status}</span>
        </div>
      </div>
      <div className={classes.BodyContainer}>
        <div>{room.room_code + ' - ' + room.room_type.room_type_name}</div>
        <div>{student_email}</div>
      </div>
    </div>
  );
};

export default AssetBorrowingList;
