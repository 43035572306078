import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import './Message.css';

const Message = (props) => {
    useEffect(() => {
        if (props.show) {
            setTimeout(() => {
                props.messageHandler(props.message, props.type, false);
            }, props.timeout);
        }
    }, [props.show]);

    let icon = "error";
    let classes = ["MessageContainer"];

    if (props.login) {
        classes.push("MessageContainerLogin");
    }

    if (props.type === "success") {
        classes.push("MessageContainer--Success");
        icon = "done";
    }

    return (
        <CSSTransition
            unmountOnExit
            in={props.show}
            timeout={500}
            classNames={"MessageContainer"}
            >
            <div className={classes.join(" ")} onClick={() => { props.messageHandler(props.message, props.type, false); }}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                }}>
                    <span>
                        <i className="material-icons">{icon}</i>
                    </span>
                    <span style={{
                        marginLeft: "8px",
                        marginBottom: "3px",
                    }}>
                        {props.message}
                    </span>
                </div>
                <span style={{cursor: 'pointer'}}>
                    <i className="material-icons">clear</i>
                </span>
            </div>
        </CSSTransition>
    );
}

export default Message;