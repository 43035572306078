// import React from 'react';
import React, { useState, useEffect, useRef } from "react";

import VerticalLists from '../../../../../../component/UI/List/VerticalLists/VerticalLists';
import VerticalList from '../../../../../../component/UI/List/VerticalLists/VerticalList/VerticalList';

import classes from '../../NewAssetBorrowing.module.css';

const BorrowingLocation = (props) => {
  let renderLocationList = props.locations.map((item, index) => {
    return (
      <VerticalList
        key={index}
        item={item}
        onClick={props.locationHandler}
        active={
          props.selectedLocation != null &&
          props.selectedLocation.id === item.id
        }
      >
        {item.location}
      </VerticalList>
    );
  });

  const onContinueClick = () => {
    if (props.selectedLocation != null) {
      if (props.selectedLocation.location !== '') props.changeStateHandler(1);
      else props.changeStateHandler(1);
    } else {
      props.messageHandler('Please select the laboratory type', 'error', true);
    }
  };

  useEffect(() => {
    // if (props.availableAssets.length === 0) props.changeStateHandler(0);
    // else setAssets(props.availableAssets);
    // console.log(props.banStatus);
  }, [props.banStatus]);

  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;


  let nextButtonClasses = [classes.NextButton];

  if (props.selectedLocation == null) {
    nextButtonClasses.push(classes.ButtonDisabled);
  }

  return (
    <div>
      {props.banStatus? <div>
        <h2>YOU HAVE BEEN BANNED FROM BORROWING ASSET, PLEASE WAIT FOR YOUR BAN COOL DOWN.</h2>
        You can contact room PIC to remove the ban or wait for 1 week since you are banned. 
        <p>Your are banned on <b>{props.banStatus.created_at}</b></p>
      </div>:
      
      <div>
        <div className={classes.Title}>Tell us what you need.</div>
      <div className={classes.SubTitle__Container}>
        <div className={classes.SubTitle}>Campus</div>
        <p className={classes.Description}>
          Choose one of Campus room location you want to borrow.
        </p>
      </div>
      <VerticalLists>{renderLocationList}</VerticalLists>
      <div className={classes.ButtonContainer} onClick={onContinueClick}>
        <span className={nextButtonClasses.join(' ')}>
          Continue
          <i className='material-icons' style={{ marginLeft: '8px' }}>
            arrow_forward
          </i>
        </span>
      </div>
      </div>
      
      }

      
    </div>
  );
};

export default BorrowingLocation;
