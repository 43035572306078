import React from 'react';

import MainMenuItem from './MainMenuItem/MainMenuItem';

import classes from './MainMenuContainer.module.css';

const MainMenuContainer = (props) => {

    const menu = props.menu.map((m, i) => {
        return (
            <MainMenuItem to={m.link} sideDrawerHandler={props.sideDrawerHandler} key={i}>
                <i className="material-icons">{m.logo}</i>{m.name}
            </MainMenuItem>
        );
    });

    return (
        <nav className={classes.MainMenuContainer}>
            {menu}
        </nav>
    );
}

export default MainMenuContainer;