import React, { useState } from 'react';

import classes from '../../NewBorrowing.module.css';

const TermAndAgreement = (props) => {
    const [agree, setAgree] = useState(false);

    const checkboxHandler = (evt) => {
        const value = evt.target.checked;
        setAgree(value);
    }

    const backButtonHandler = () => {
        props.changeStateHandler(6);
    }

    const nextButtonHandler = () => {
        if (agree) {
            props.changeStateHandler(8);
        }
    }

    let nextButtonClasses = [classes.NextButton];

    if (!agree) {
        nextButtonClasses.push(classes.ButtonDisabled);
    }

    return (
        <div className={classes.BorrowingDataContainer}>
            <div className={classes.Title} style={{lineHeight: "20pt"}}>
                Terms and Conditions
            </div>
            <div className={classes.SubTitle__Container} style={{textAlign: "justify"}}>
                <p className={classes.Description}>1. <b>Borrower</b> must follow <b>rules and procedures</b> from each laboratory.</p>
                <p className={classes.Description}>2. If the borrower is <b>absent or late</b> at the borrowed schedule, there will be a <b>penalty.</b></p>
            </div>
            <div className={classes.SubTitle__Container}>
                <label style={{display: "flex", alignItems: "center"}}>
                    <input
                        type="checkbox"
                        onChange={checkboxHandler}/>
                    &nbsp;I agree with terms and conditions 
                </label>
            </div>
            <div className={classes.ButtonContainer__SpaceBetween}>
                <div className={classes.BackButton} onClick={backButtonHandler}>
                    <i className="material-icons" style={{marginRight: "8px"}}>
                        arrow_back
                    </i>
                    Back
                </div>
                <div className={nextButtonClasses.join(" ")} onClick={nextButtonHandler}>
                    Next
                    <i className="material-icons" style={{marginLeft: "8px"}}>
                        arrow_forward
                    </i>
                </div>
            </div>
        </div>
    );
}

export default TermAndAgreement;