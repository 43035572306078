import { useState, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import Axios from "axios";

export const useUserData = () => {
    // eslint-disable-next-line
    const [cookies, setCookie, removeCookie] = useCookies(["_s"]);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        Axios.defaults.withCredentials = true;
        let isSubscribed = true;
        const getUserData = async () => {
            let data;
            if (cookies._s === "undefined" || cookies._s === undefined) {
                data = false;
            } else {
                try {
                    data = await Axios.post(
                        process.env.REACT_APP_DEFAULT_API + "data",
                        {
                            data: cookies._s,
                        }
                    );
                    if (
                        data.data.payload.status &&
                        Object.keys(data.data.payload.data).length > 0
                    ) {
                        data = data.data.payload.data;
                    } else {
                        // clear invalid cookie
                        removeCookie("_s");
                        data = null;
                    }
                } catch (e) {
                    // clear invalid cookie
                    removeCookie("_s");
                    data = null;
                }
            }

            if (isSubscribed) {
                setUserData(data);
            }
        };

        getUserData();

        return () => (isSubscribed = false);
    }, []);

    return userData;
};

export const usePrevious = (value) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
};
