import React from 'react';

import Backdrop from '../Backdrop/Backdrop';
import LoadingImage from '../../../images/loading_eclipse.svg';

import classes from './Loading.module.css';

const loading = () => {
  return (
    <Backdrop center={true} show={true} style={{ zIndex: '1000' }}>
      <img className={classes.LoadingImage} src={LoadingImage} alt='loading' />
    </Backdrop>
  );
};

export default loading;
