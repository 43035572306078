import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import {
  TabularHeader,
  TabularBody,
} from '../../../../component/UI/Tabular/Tabular';
import BorrowingList from '../../../../component/UI/List/BorrowingList/BorrowingList';
import BorrowingDetail from '../BorrowingDetail/BorrowingDetail';
import Loading from '../../../../component/UI/Loading/Loading';

import classes from '../NewBorrowing/NewBorrowing.module.css';
import Pagination from '../../../../component/Pagination/Pagination';

const BorrowingHistory = (props) => {
  const [pagination, setPagination] = useState({
    current_page: 1,
    total_page: 1,
  })
  const [tabState, setTabState] = useState(0);
  const [isDetailShow, setDetailShow] = useState({
    show: false,
    data: null,
  });

  const [borrowingData, setBorrowingData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const fetchData = async (page, tab) => {
    try {
      setIsLoad(true);
      let data = null;
      let range = '';

      if (tab === 0) {
        range = 'week';
      } else if (tab === 1) {
        range = 'month';
      }
      data = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + 'student/history/all',
        {
          params: {
            number: props.userData.username,
            range: range,
            page: page,
          },
        }
      );
      const payload = data.data.payload;
      if (data != null) {
        if (payload.status) {
          setBorrowingData(payload.data);
          setPagination({
            current_page: payload.current_page,
            total_page: payload.last_page,
          })
        }
      } else {
        setBorrowingData([]);
      }
    } catch (error) {
      props.messageHandler(
        'Unexpected error please try again later',
        'error',
        true
      );
    }
    setIsLoad(false);
  };

  const onClickDataHandler = (data) => {
    setDetailShow({
      show: true,
      data: data,
    });
  };
  const backButtonHandler = () => {
    setDetailShow({
      show: false,
      data: null,
    });
  };

  
  const list =
  Array.isArray(borrowingData) && borrowingData.length > 0 ? (
    borrowingData.map((item, index) => {
      return (
        <BorrowingList
        onClickHandler={onClickDataHandler}
        key={index}
        data={item}
          />
          );
        })
    ) : (
      <div
        style={{
          fontStyle: 'italic',
          fontSize: '12pt',
          padding: '14px 16px',
        }}
        >
        No data available
      </div>
    );
  
  const handleClick = (state) => {
    setTabState(state);
    fetchData(pagination.current_page, state)
  }
  const fetchNext = () => {
    if (pagination.current_page < pagination.total_page) {
      const n = pagination.current_page+1;
      setPagination({
        ...pagination,
        current_page: n,
      })
      fetchData(n, tabState);
    }
  };
  const fetchPrev = () => {
    if (pagination.current_page > 1) {
      const n = pagination.current_page-1;
      setPagination({
        ...pagination,
        current_page: n,
      })
      fetchData(n, tabState);
    }
  };

  const fetchPage = (page) => {
    if (page >= 1 && page <= pagination.total_page) {
      const n = page
      setPagination({
        ...pagination,
        current_page: n,
      })
      fetchData(n, tabState);
    }
  };
  
  useEffect(() => {
    fetchData(1, 0);
  }, []);

  return (
    <div className={classes.Container}>
      {isLoad ? <Loading /> : ''}
      {isDetailShow.show ? (
        <BorrowingDetail
          detailBorrowing={true}
          borrower={true}
          backButtonHandler={backButtonHandler}
          history={props.history}
          messageHandler={props.messageHandler}
          id={isDetailShow.data.id}
          status={isDetailShow.data.borrowing_status}
          date={isDetailShow.data.date}
          user={props.userData}
          reason={isDetailShow.data.reason}
          userData={isDetailShow.data.userData}
          room={isDetailShow.data.room}
          loadingHandler={setIsLoad}
          laboratoryType={isDetailShow.data.room.type}
          shifts={isDetailShow.data.detail_shifts}
          assets={isDetailShow.data.assets}
          teammateData={isDetailShow.data.teammateData}
        />
      ) : (
        <>
          <div className={classes.Title}>Borrowing History</div>
          <span
            style={{
              fontStyle: 'italic',
              fontSize: '10pt',
              marginTop: '15px',
            }}
          >
            *) click the list to see the detail
          </span>
          <TabularHeader
            menus={['This Week', 'This Month', 'All']}
            active={tabState}
            onTabClickHandler={handleClick}
          />
          <TabularBody>{list}</TabularBody>
          <Pagination
            totalPage={pagination.total_page}
            currPage={pagination.current_page}
            fetchNext={fetchNext}
            fetchPrev={fetchPrev}
            fetchPage={fetchPage}
          />
        </>
      )}
    </div>
  );
};

export default BorrowingHistory;
