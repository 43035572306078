import React, { useEffect } from "react";

import VerticalListsMultiple from "../../../../../../component/UI/List/VerticalListsMultiple/VerticalListsMultiple";
import VerticalLists from "../../../../../../component/UI/List/VerticalLists/VerticalLists";
import VerticalList from "../../../../../../component/UI/List/VerticalLists/VerticalList/VerticalList";

import classes from "../../NewAssetBorrowing.module.css";
import "./Transition.css";

const RoomAndDate = (props) => {
  useEffect(() => {
    if (props.pendingBorrowing) {
      props.history.push("/room-borrowing");
    }
  }, [props.pendingBorrowing]);

  const backButtonHandler = () => {
    props.changeStateHandler(2);
  };

  let nextButtonClasses = [classes.NextButton];

  if (props.selectedRoom.length === 0) {
    nextButtonClasses.push(classes.ButtonDisabled);
  }

  const nextButtonHandler = () => {
    if (props.selectedRoom != null && props.selectedRoom.length !== 0) {
      props.changeStateHandler(4);
    }
  };

  let renderRoomListSpecial = <div></div>;

  if (props.rooms != null) {
    renderRoomListSpecial = (
      <VerticalListsMultiple
        items={props.rooms}
        selectedItems={props.selectedRoom}
        addItemHandler={props.addRoomHandler}
        removeItemHandler={props.removeRoomHandler}
      />
    );
  }

  let renderRoomList =
    props.rooms && props.rooms.length !== 0 ? (
      props.rooms.map((item, index) => (
        <VerticalList
          key={index}
          item={item}
          onClick={props.roomHandler}
          active={
            props.selectedRoom[0] != null &&
            item.id === props.selectedRoom[0].id
          }
        >
          {item.room_code}
          <div className={classes.MiniDescription}>{item.room_name}</div>
        </VerticalList>
      ))
    ) : (
      <div>
        Sorry, there is currently no room with borrowable assets for this room
        type.
      </div>
    );

  let renderRoomTransactions = <div></div>;
  let renderRoomAndDate = <div></div>;
  if (props.user.Special === 1) {
    let transactions = () => {
      if (props.roomTransactions != null) {
        let render = [];
        props.rooms.map((room, index) => {
          let row = [];
          for (let i = 0; i < 6; i++) {
            let item = (
              <td
                key={index + i}
                style={{ color: "white", userSelect: "none" }}
              >
                X
              </td>
            );
            let roomItem = props.roomTransactions.data[index];
            if (roomItem == null || roomItem.Status[i].RoomTransactionStatus) {
              item = (
                <td key={index + i} style={{ userSelect: "none" }}>
                  X
                </td>
              );
            }
            row.push(item);
          }
          render.push(
            <tr>
              <td style={{ color: "black" }} key={index}>
                {room.room_code}
              </td>
              {row}
            </tr>
          );
        });
        return render;
      }
    };
    renderRoomTransactions = (
      <>
        <div className={classes.SubTitle}>Room Transaction</div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
            </tr>
          </thead>
          <tbody>{transactions()}</tbody>
        </table>
        <div className={classes.SubTitle}>
          <span style={{ color: "red" }}>X </span>: Not Available
        </div>
      </>
    );
    renderRoomAndDate = (
      <>
        <div className={classes.SubTitle__Container}>
          {props.roomTransactions != null ? renderRoomTransactions : ""}
        </div>
        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Room</div>
          <p className={classes.Description}>
            Choose one of Laboratory Room you want to borrow.
          </p>
        </div>
        <VerticalLists>
          {props.user.Special === 1 ? renderRoomListSpecial : renderRoomList}
        </VerticalLists>
      </>
    );
  } else {
    renderRoomAndDate = (
      <>
        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Room</div>
          <p className={classes.Description}>
            Choose one of Laboratory Room you want to borrow.
          </p>
        </div>
        <VerticalLists>
          {props.user.Special === 1 ? renderRoomListSpecial : renderRoomList}
        </VerticalLists>
      </>
    );
  }

  return (
    <div className={classes.BorrowingDataContainer}>
      <div className={classes.Title}>
        Okay, from which room do you want to borrow the assets?
      </div>
      {renderRoomAndDate}
      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className="material-icons" style={{ marginRight: "8px" }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(" ")}
          onClick={nextButtonHandler}
        >
          Next
          <i className="material-icons" style={{ marginLeft: "8px" }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default React.memo(RoomAndDate);
