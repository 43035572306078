import React from 'react';

import VerticalLists from '../../../../../../component/UI/List/VerticalLists/VerticalLists';
import VerticalList from '../../../../../../component/UI/List/VerticalLists/VerticalList/VerticalList';

import classes from '../../NewAssetBorrowing.module.css';


const LaboratoryType = (props) => {
    let renderLaboratoryList = props.labTypes.map( (item, index) => {
        return (
            <VerticalList key={index} item={item} onClick={props.labTypeHandler} active={props.selectedLabType != null && props.selectedLabType.id === item.id}>
                {item.name}
                <div className={classes.MiniDescription}>
                    {item.description}
                </div>
            </VerticalList>
        );
    } );

    const nextButtonHandler = () => {
        if (props.selectedLabType != null) {
            props.changeStateHandler(3);
        }
    }

    const backButtonHandler = () => {
        props.changeStateHandler(1);
    }

    let nextButtonClasses = [classes.NextButton];

    if (props.selectedLabType === null) {
        nextButtonClasses.push(classes.ButtonDisabled);
    }

    return (
        <div>
            <div className={classes.Title}>Tell us what you need.</div>
            <div className={classes.SubTitle__Container}>
                <div className={classes.SubTitle}>Laboratory</div>
                <p className={classes.Description}>Choose one of Laboratory you want to borrow.</p>
            </div>
            <VerticalLists>
                {renderLaboratoryList}
            </VerticalLists>
            <div className={classes.ButtonContainer__SpaceBetween}>
                <div className={classes.BackButton} onClick={backButtonHandler}>
                    <i className="material-icons" style={{marginRight: "8px"}}>
                        arrow_back
                    </i>
                    Back
                </div>
                <div className={nextButtonClasses.join(" ")} onClick={nextButtonHandler}>
                    Next
                    <i className="material-icons" style={{marginLeft: "8px"}}>
                        arrow_forward
                    </i>
                </div>
            </div>
        </div>
    );
}

export default LaboratoryType;