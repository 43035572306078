import React from 'react';

import MainMenu from '../Menu/MainMenu/MainMenuContainer';
import BinusLogo from '../../UI/Logo/BinusLogo';
import Backdrop from '../../UI/Backdrop/Backdrop';

import classes from './SideDrawer.module.css';

const SideDrawer = (props) => {

    let sideDrawerClass = [classes.SideDrawer];

    if (props.active) {
        sideDrawerClass.push(classes.Open);
    } else {
        sideDrawerClass.push(classes.Close);
    }

    return (
        <>
            <Backdrop show={props.active} sideDrawerHandler={props.sideDrawerHandler} sideDrawer={true}/>
            <div className={sideDrawerClass.join(" ")}>
                <BinusLogo />
                <div className={classes.SideDrawer__UserName}>
                    Welcome, <br/> 
                    {props.user ? props.user.name : ""}
                </div>
                <MainMenu 
                    sideDrawerHandler={props.sideDrawerHandler} 
                    menu={props.menu}/>
            </div>
        </>
    );
}

export default SideDrawer;