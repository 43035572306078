import React, { Suspense, useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import Calendar from "react-calendar";
import Moment from "moment";

import VerticalListsMultiple from "../../../../../../component/UI/List/VerticalListsMultiple/VerticalListsMultiple";
import VerticalLists from "../../../../../../component/UI/List/VerticalLists/VerticalLists";
import VerticalList from "../../../../../../component/UI/List/VerticalLists/VerticalList/VerticalList";

import classes from "../../NewBorrowing.module.css";
import styles from "./RoomAndDate.module.css"
import "./Transition.css";
import "./Calendar.css";

const RoomAndDate = (props) => {
  useEffect(() => {
    if (availShiftRef.current != null) {
      availShiftRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    if (props.availableShifts != null && !props.availableShifts.status) {
      props.messageHandler(props.availableShifts.message, "error", true);
    }
  }, [props.availableShifts]);

  useEffect(() => {
    if (props.pendingBorrowing) {
      props.history.push("/room-borrowing");
    }
  }, [props.pendingBorrowing]);

  const availShiftRef = useRef(null);

  const backButtonHandler = () => {
    let hasSoftware = Boolean(props.selectedLabType.has_software);
    if(hasSoftware){
      props.changeStateHandler(9);
    }else{
      props.changeStateHandler(2);
    }

  };

  const calendarHandler = (date) => {
    props.dateHandler(Moment(date).format("YYYY-MM-DD").toString());
  };

  let nextButtonClasses = [classes.NextButton];

  if (props.selectedShifts.length === 0) {
    nextButtonClasses.push(classes.ButtonDisabled);
  }

  const nextButtonHandler = () => {
    if (props.selectedRoom != null && props.selectedDate != null) {
      if (props.selectedShifts.length > 0) props.changeStateHandler(4);
    }
  };

  let renderAvailableShift = <div></div>;
  if (props.availableShifts != null && props.availableShifts.status) {
    renderAvailableShift = (
      <>
        <div className={classes.SubTitle__Container} ref={availShiftRef}>
          <div className={classes.SubTitle}>Available Shift</div>

          <p className={classes.Description} style={{ marginBottom: "30px" }}>
            Pick shift you desire the most. Don't forget to discuss with your
            teammate!
          </p>
          
          <VerticalListsMultiple
            items={props.availableShifts.shifts}
            selectedItems={props.selectedShifts}
            addItemHandler={props.addShiftHandler}
            removeItemHandler={props.removeShiftHandler}
            height={"20rem"}
          />
        </div>
      </>
    );
  }

  let renderRoomListSpecial = <div></div>;

  if (props.rooms != null) {
    renderRoomListSpecial = (
      <VerticalListsMultiple
        items={props.rooms}
        selectedItems={props.selectedRoom}
        addItemHandler={props.addRoomHandler}
        removeItemHandler={props.removeRoomHandler}
        height="30rem"
      />
    );
  }

  const renderRoomList = props.rooms.map((item, index) => (
    <VerticalList
      key={index}
      item={item}
      onClick={props.roomHandler}
      active={
        props.selectedRoom[0] != null && item.id === props.selectedRoom[0].id
      }
    >
      {/* {console.log(item)} */}
      <div className={styles.RoomCodeContainer}>
        <div className={styles.RoomCode}>
          {item.room_code} 
        </div>
        <div className={styles.StatusPill}>
          {!item.hybrid_status ? "" : item.hybrid_status.name}
        </div>
      </div> 
      <div className={classes.MiniDescription}>{item.room_name}</div>
    </VerticalList>
  ));

  let renderRoomTransactions = <div></div>;
  let renderRoomAndDate = <div></div>;
  if (props.user.Special === 1) {
    let transactions = () => {
      if (props.roomTransactions != null) {
        let render = [];
        props.rooms.map((room, index) => {
          let row = [];
          for (let i = 0; i < 6; i++) {
            let item = (
              <td
                key={index + i}
                style={{ color: "white", userSelect: "none" }}
              >
                X
              </td>
            );
            let roomItem = props.roomTransactions.data[index];
            if (roomItem == null || roomItem.Status[i].RoomTransactionStatus) {
              item = (
                <td key={index + i} style={{ userSelect: "none" }}>
                  X
                </td>
              );
            }
            row.push(item);
          }
          render.push(
            <tr>
              <td style={{ color: "black" }} key={index}>
                {room.room_code}
              </td>
              {row}
            </tr>
          );
        });
        return render;
      }
    };
    renderRoomTransactions = (
      <>
        <div className={classes.SubTitle}>Room Transaction</div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
            </tr>
          </thead>
          <tbody>{transactions()}</tbody>
        </table>
        <div className={classes.SubTitle}>
          <span style={{ color: "red" }}>X </span>: Not Available
        </div>
      </>
    );
    renderRoomAndDate = (
      <>
        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Date</div>
          <p className={classes.Description}>
            Tell us when you want to use this Laboratory. 1
          </p>
        </div>
        {props.selectedRoom.length !== 0 ? (
          <Calendar
            value={new Date(props.selectedDate)}
            calendarType="ISO 8601"
            onChange={calendarHandler}
            minDate={
              props.lastBorrowingDate !== null
                ? new Date(props.lastBorrowingDate)
                : new Date(Moment().add(1, "days").toString())
            }
            tileDisabled={({ date }) => date.getDay() === 3}
          />
        ) : (
          ""
        )}

        <div className={classes.SubTitle__Container}>
          {props.roomTransactions != null ? renderRoomTransactions : ""}
        </div>
        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Room</div>
          <p className={classes.Description}>
            Choose one of Laboratory Room you want to borrow.
          </p>
        </div>
        <div>
          {props.rooms.length === 0 ? <h3 style={{textAlign: "center", fontWeight: "bold"}}>Room not found</h3> : ""} 
        </div>
        <VerticalLists>
          {props.user.Special === 1 ? renderRoomListSpecial : renderRoomList}
        </VerticalLists>
      </>
    );
  } else {
    renderRoomAndDate = (
      <>
        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Room</div>
          <p className={classes.Description}>
            Choose one of Laboratory Room you want to borrow.
          </p>
        </div>
        <div>
          {props.rooms.length === 0 ? <h3 style={{textAlign: "center", fontWeight: "bold"}}>Room not found</h3> : ""} 
        </div>
        <VerticalLists>
          {props.user.Special === 1 ? renderRoomListSpecial : renderRoomList}
        </VerticalLists>

        <div className={classes.SubTitle__Container}>
          <div className={classes.SubTitle}>Date</div>
          <p className={classes.Description}>
            Tell us when you want to use this Laboratory.
          </p>
        </div>
        {props.selectedRoom.length !== 0 ? (
          <Calendar
            value={new Date(props.selectedDate)}
            calendarType="ISO 8601"
            onChange={calendarHandler}
            minDate={new Date(Moment().add(1, "days").toString())}
            tileDisabled={({ date }) => {
              return date.getDay() === 0;
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <div className={classes.BorrowingDataContainer}>
      <div className={classes.Title}>
        Okay, when and where will you use the Laboratory?
      </div>
      {renderRoomAndDate}
      <CSSTransition
        unmountOnExit
        in={props.availableShifts != null || props.availableShifts != undefined}
        classNames="shift"
        timeout={500}
      >
        {renderAvailableShift}
      </CSSTransition>
      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className="material-icons" style={{ marginRight: "8px" }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(" ")}
          onClick={nextButtonHandler}
        >
          Next
          <i className="material-icons" style={{ marginLeft: "8px" }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default RoomAndDate;
