import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import Axios from 'axios';
import Loading from '../../../../../component/UI/Loading/Loading';
//urut sesuai sm urutan page nya.
import BorrowingLocation from './BorrowingLocation/BorrowingLocation';
import LaboratoryType from './LaboratoryType/LaboratoryType';
import AssetBorrowingData from './AssetBorrowingData/AssetBorrowingData';
import AssetDateShift from './AssetDateShift/AssetDateShift';
import StudentRules from '../../Rules/StudentRules';
import AssetBorrowingDetail from '../../AssetBorrowingDetail/AssetBorrowingDetail';
import BorrowerData from './BorrowerData/BorrowerData';
import AssetRoom from './AssetRoom/AssetRoom';


const AssetBorrowing = (props) => {
  const [isLoad, setIsLoad] = useState(false);
  
  const [borrowingDataState, setBorrowingDataState] = useState(0);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [selectedLabType, setSelectedLabType] = useState(null);

  const [selectedRoom, setSelectedRoom] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedReturnDate, setSelectedReturnDate] = useState(null);


  const [selectedShifts, setSelectedShifts] = useState([]);
  const [selectedShifts2, setSelectedShifts2] = useState([]);
  const [banStatus,setBanStatus] =useState(null);

  const [selectedReturnShifts, setSelectedReturnShifts] = useState([]);


  const [rooms, setRooms] = useState([]);

  const [availableShifts, setAvailableShift] = useState(null);
  const [availableShifts2, setAvailableShift2] = useState(null);
 

  const [availableAssets, setAvailableAssets] = useState([]);

  const [selectedAssets, setSelectedAssets] = useState([]);

  const [labTypes, setLabTypes] = useState([]);

  const [roomTransactions, setRoomTransactions] = useState(null);

  const [isRulesCheck, setRulesCheck] = useState(false);

  const [locations, setLocations] = useState([]);

  const [userData, setUserData] = useState({
    phone: '',
    email: '',
    purpose: '',
  });

  const [currentRoom, setCurrentRoom] = useState({});

  const setUserDataHandler = (type, data) => {
    let newState = { ...userData };

    if (type === 'phone') {
      newState.phone = data;
    } else if (type === 'email') {
      newState.email = data;
    } else if (type === 'purpose') {
      newState.purpose = data;
    }

    setUserData(newState);
  };

  const [teammateData, setTeammateData] = useState(
    props.user.binusian_id !== '-'
      ? [
          {
            binusian_id: props.user.binusian_id,
            name: props.user.name,
          },
        ]
      : []
  );

  const submitFormHandler = async () => {
    setIsLoad(true);

    const assetsId = selectedAssets.reduce((acc, item, index) => {
      acc.push(item.id);
      return acc;
    }, []);

    const formData = {
      selectedLabType: selectedLabType,
      selectedRoom: selectedRoom,
      selectedDate: selectedDate,
      selectedReturnDate: selectedReturnDate,
      selectedShifts: selectedShifts,
      // selectedReturnDate: selectedReturnDate,
      selectedReturnShifts: selectedShifts2,
      userData: { ...userData, ...props.user },
      teammateData: teammateData,
      assets: assetsId,
    };

    if (selectedLabType == null || selectedLabType === '') {
      props.messageHandler('Lab type must be selected', 'error', true);
      return;
    } else if (formData.selectedRoom == null || formData.selectedRoom === '') {
      props.messageHandler('Room must be selected', 'error', true);
      return;
    } else if (formData.selectedDate == null || formData.selectedDate === '') {
      props.messageHandler('Date must be selected', 'error', true);
      return;
    } else if (formData.selectedDate == null || formData.selectedDate === '') {
      props.messageHandler('Date must be selected', 'error', true);
      return;
    } else if (
      formData.userData.phone == null ||
      formData.userData.phone === ''
    ) {
      props.messageHandler('Phone must be filled', 'error', true);
      return;
    } else if (
      formData.userData.purpose == null ||
      formData.userData.purpose === ''
    ) {
      props.messageHandler('Purpose must be filled', 'error', true);
      return;
    }

    //check shift pickup asset apakah di isi?
    if (
      formData.selectedShifts == null ||
      formData.selectedShifts.length === 0
    ) {
      props.messageHandler('Pickup Shift(s) must be selected', 'error', true);
      return;
    }

    if(formData.selectedReturnShifts == null || formData.selectedShifts.length === 0)
    {
      props.messageHandler('Return Shift(s) must be selected', 'error', true);
      return;
    }
    
    try {
      let submit = await Axios.post(
        process.env.REACT_APP_DEFAULT_API + 'student/home/submit-asset-borrowing',
        formData
      );
      
      let message = submit.data.payload.status
        ? submit.data.payload.message + '. Please wait for email confirmation'
        : submit.data.payload.message;
      props.messageHandler(
        message,
        submit.data.payload.status ? 'success' : 'error',
        true
      );

      if (submit.data.payload.status) {
        setTimeout(() => {
          props.history.push('/home');
        }, 2000);
      }
    } catch (error) {
      props.messageHandler(
        'Unexpected error, please contact the administrator please',
        'error',
        true
      );

    }

    setIsLoad(false);
  };


  const addShiftHandler = (shift) => {
    let prevSelectedShifts = [...selectedShifts];
    prevSelectedShifts.push(shift);
    prevSelectedShifts.sort((a, b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    });
    setSelectedShifts(prevSelectedShifts);
  };

  const pickOneShiftHandler=(shift)=>{
    let prevSelectedShifts = [];
    prevSelectedShifts.push(shift);
    prevSelectedShifts.sort((a, b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    });
    setSelectedShifts(prevSelectedShifts);
  };

  const pickOneShiftHandler2=(shift)=>{
    let prevSelectedShifts = [];
    prevSelectedShifts.push(shift);
    prevSelectedShifts.sort((a, b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    });


    setSelectedShifts2(prevSelectedShifts);
  };


  const removeShiftHandler = (shift) => {
    let prevSelectedShifts = [...selectedShifts];

    let removedSelectedShift = prevSelectedShifts.filter(
      (item, index) => item.id !== shift.id
    );

    removedSelectedShift.sort();

    setSelectedShifts(removedSelectedShift);
  };

  const removeShiftHandler2 = (shift) => {
    let prevSelectedShifts = [...selectedShifts];

    let removedSelectedShift = prevSelectedShifts.filter(
      (item, index) => item.id !== shift.id
    );

    removedSelectedShift.sort();

    setSelectedShifts2(removedSelectedShift);
  };

  const addReturnShiftHandler = (shift) => {
    let prevSelectedReturnShifts = [...selectedReturnShifts];
    prevSelectedReturnShifts.push(shift);
    prevSelectedReturnShifts.sort((a, b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    });
    setSelectedReturnShifts(prevSelectedReturnShifts);
  };

  const removeReturnShiftHandler = (shift) => {
    let prevSelectedShifts = [...selectedShifts];

    let removedSelectedShift = prevSelectedShifts.filter(
      (item, index) => item.id !== shift.id
    );

    removedSelectedShift.sort();

    setSelectedShifts(removedSelectedShift);
  };

  const addAssetHandler = (asset) => {
    let prevSelectedAssets = [...selectedAssets];
    prevSelectedAssets.push(asset);

    setSelectedAssets(prevSelectedAssets);
  };

  const removeAssetHandler = (asset) => {
    let prevSelectedAssets = [...selectedAssets];

    let removedSelectedShift = prevSelectedAssets.filter(
      (item, index) => item.id !== asset.id
    );

    setSelectedAssets(removedSelectedShift);
  };

  useEffect(() => {
    Axios.defaults.withCredentials = true;
    let fetchHomeData = async () => {
      setIsLoad(true);
      let data;
      try {
        data = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + 'student/homeforasset'
        );
        data = data.data.payload;
        setLocations(data.locations);
      } catch (error) {
        props.messageHandler(
          'Unexpected error, please contact the administrator',
          'error',
          true
        );
      }
      setIsLoad(false);
    };

    fetchHomeData();
  }, []);





  let banStatusNow;
  useEffect(() => {//kodi ban status
    // console.log(props.user);
    Axios.defaults.withCredentials = true;

    let updateBanStatus = async () => {
      setIsLoad(true);
      let data;
      try {//kodi
        data = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + 'student/updateForBan',
          {
            params: {
              username:props.user.email, //penentu ban
            },
          }
        );
        data = data.data.payload;
        setBanStatus(data);
        // console.log(data);
      } catch (error) {
        props.messageHandler(
          'Unexpected error, please contact the administrator',
          'error',
          true
        );
      }
      setIsLoad(false);
    };

    let fetchBanStatus = async () => {
      setIsLoad(true);
      let data;
      try {//kodi
        data = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + 'student/checkForBan',
          {
            params: {
              username:props.user.email, //penentu ban
            },
          }
        );
        data = data.data.payload;
        setBanStatus(data);
        // console.log(data);
      } catch (error) {
        props.messageHandler(
          'Unexpected error, please contact the administrator',
          'error',
          true
        );
      }
      setIsLoad(false);
    };

    
    updateBanStatus();
    fetchBanStatus();
  }, []);

  useEffect(() => {
    if (borrowingDataState === 2) {
      Axios.defaults.withCredentials = true;
      let fetchRoomTypesData = async () => {
        setIsLoad(true);
        let data;
        try {
          data = await Axios.get(
            process.env.REACT_APP_DEFAULT_API + `student/home/room-types-asset`,
            {
              params: {
                email: props.user.email,
                room_location_id: selectedLocation.id,
              },
            }
          );
          data = data.data.payload;
          // console.log(data.room_types);
          setLabTypes(data.room_types);
        } catch (error) {
          props.messageHandler(
            'Unexpected error, please contact the administrator',
            'error',
            true
          );
        }
        setIsLoad(false);
      };

      fetchRoomTypesData();
    }
  }, [selectedLocation, borrowingDataState]);

  useEffect(() => {//fetch seluruh room dari lab type X pas udah pilih lab type
    if (
      borrowingDataState === 3
    ) {
      setIsLoad(true);
      setSelectedAssets([]);
      let fetchRoom = async () => {
        let data=null;
        
        try {
          data = await Axios.get(
            process.env.REACT_APP_DEFAULT_API + 'student/home/rooms-have-asset',
            {
              params: {
                email: props.user.email,
                room_type_id: selectedLabType.id,
              },
            }
          );
          // console.log(selectedLabType.id);
        } catch (e) {
          alert(e);
          props.messageHandler(
            'Unexpected error, please call the administrator',
            'error',
            true
          );
        }


        // console.log(resultend);
        setRooms(data.data.payload.data);
        setIsLoad(false);

      };
      fetchRoom();

    }
  }, [borrowingDataState]);

  let fetchAssets = async () => {
    let selectedRoomsId = selectedRoom.reduce((acc, item, index) => {
      acc.push(item.id);
      return acc;
    }, []);
    
    if(selectedRoomsId.length !== 0)
    {
      let assets = await Axios.post(
        process.env.REACT_APP_DEFAULT_API + 'student/home/borrowable-assets',
        {
          rooms: selectedRoomsId,
        }
      );
      assets = assets.data;
      setAvailableAssets(assets);
    }

  }


  useEffect(() => { //sudah di state 3 maka tampilin seluruh asset dari LAB type yg dipilih
    if (
      borrowingDataState === 3 && selectedRoom.length>0
    ) {
      
      setIsLoad(true);
      setSelectedAssets([]);
      let fetchShifts = async () => {
        try {

          let selectedRoomsId = rooms.reduce((acc, item, index) => {
            // console.log(item.id);//di sini
            return acc;
          }, []);

            selectedRoomsId.push(selectedRoom[0].id);


          let assets = await Axios.post(
            process.env.REACT_APP_DEFAULT_API + 'student/home/borrowable-assets',
            {
              email: props.user.email,
              rooms: selectedRoomsId,
            }
          );
          assets = assets.data;
          setAvailableAssets(assets);

          let data = await Axios.get(
            process.env.REACT_APP_DEFAULT_API + 'student/home/available-shifts',
            {
              params: { //Ambil contoh shift aja, tampilin shift general
                room_id: 5,
                date: new Date('2022-03-30'),
              },
            }
          );
          data = data.data.payload;
          setAvailableShift(data);

        } catch (e) {
          alert(e);
          props.messageHandler(
            'Unexpected error, please call the administrator',
            'error',
            true
          );
        }
        setIsLoad(false);

      };

      fetchShifts();
    }

  }, [borrowingDataState,selectedRoom]);


  const setSelectedRoomFromList = (room) => {
    let selected = [];
    selected.push(room);

    setSelectedRoom(selected);
    setCurrentRoom(room);
  };

  const addRoomHandler = (room) => {
    let prevSelectedRooms = [selectedRoom];
    prevSelectedRooms.push(room);

    prevSelectedRooms.sort((a, b) => {
      return a.room_code > b.room_code ? 1 : a.room_code < b.room_code ? -1 : 0;
    });

    setSelectedRoom(prevSelectedRooms);
  };

  const removeRoomHandler = (room) => {
    let prevSelectedRooms = [...selectedRoom];

    let removedRoomHandler = prevSelectedRooms.filter(
      (item, index) => item.id !== room.id
    );

    removedRoomHandler.sort();

    setSelectedRoom(removedRoomHandler);
  };

  return (
    <>
      {isLoad ? <Loading /> : ''}
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 0}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <BorrowingLocation
          locations={locations}
          banStatus={banStatus}
          locationHandler={(data) => {
            setSelectedLocation(data);
            setSelectedLabType(null);
            setSelectedShifts([]);
            setSelectedRoom([]);
            setSelectedDate(null);
            setAvailableShift(null);
          }}
          selectedLocation={selectedLocation}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 1}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <StudentRules
          setRulesCheck={setRulesCheck}
          isRulesCheck={isRulesCheck}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 2}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <LaboratoryType
          labTypes={labTypes}
          labTypeHandler={(data) => {
            setSelectedLabType(data);
            setSelectedRoom([]);
            setSelectedDate(null);
            setAvailableShift(null);
          }}
          selectedLabType={selectedLabType}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 3}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <AssetRoom
          user={props.user}
          rooms={rooms}
          roomTransactions={roomTransactions}
          selectedRoom={selectedRoom}
          roomHandler={setSelectedRoomFromList}
          addRoomHandler={addRoomHandler}
          removeRoomHandler={removeRoomHandler}
          changeStateHandler={setBorrowingDataState}
        />
      </CSSTransition>

      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 4}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <AssetBorrowingData
          rooms={rooms}
          availableAssets={availableAssets}
          selectedAssets={selectedAssets}
          addAssetHandler={addAssetHandler}
          removeAssetHandler={removeAssetHandler}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>

      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 5}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <AssetDateShift
          dateHandler={setSelectedDate}
          returnDateHandler={setSelectedReturnDate}
          selectedDate={selectedDate}
          selectedReturnDate={selectedReturnDate}
          availableShifts={availableShifts}
          availableShifts2={availableShifts2}
          selectedShifts={selectedShifts}
          selectedShifts2={selectedShifts2}
          pickOneShiftHandler={pickOneShiftHandler}
          pickOneShiftHandler2={pickOneShiftHandler2}
          removeShiftHandler={removeShiftHandler}
          removeShiftHandler2={removeShiftHandler2}
          selectedAssets={selectedAssets}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 6}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <BorrowerData
          userData={userData}
          setUserDataHandler={setUserDataHandler}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />

      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 7}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
         <AssetBorrowingDetail
          newBorrowing={true}
          submitFormHandler={submitFormHandler}
          date={selectedDate}
          user={props.user}
          userData={userData}
          // laboratoryType={selectedLabType}
          rooms={selectedRoom}
          room={currentRoom}
          assets={selectedAssets}
          checkOutDate={selectedDate}
          checkInDate={selectedReturnDate}
          checkOutShift={selectedShifts[0]}
          checkInShift={selectedShifts2[0]}
          // shifts={selectedShifts}
          loadingHandler={setIsLoad}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
    </>
  );
};

export default AssetBorrowing;
