import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUserData } from '../../hooks/CustomHooks';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const userData = useUserData();

  const authRender = (props) => {
    //kalo null dia lagi nunggu ngambil data, false kalo dia gk dapet data
    // return <Component {...props} userData={userData} />;
    if (userData === null) {
      return '';
    } else if (!userData) {
      return (
        <Redirect
          to={{ pathname: '/login', state: { from: props.location } }}
        />
      );
    } else if (userData.username !== undefined && userData.username) {
      return <Component {...props} userData={userData} />;
    }
  };

  return <Route {...rest} render={(props) => authRender(props)} />;
};

export default PrivateRoute;
