import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import Axios from 'axios';

import Loading from '../../../../../component/UI/Loading/Loading';
//urut sesuai sm urutan page nya.
import BorrowingLocation from './BorrowingLocation/BorrowingLocation';
import LaboratoryType from './LaboratoryType/LaboratoryType';
import RoomAndDate from './RoomAndDate/RoomAndDate';
import BorrowerData from './BorrowerData/BorrowerData';
import BorrowerTeamData from './BorrowerTeamData/BorrowerTeamData';
import AssetBorrowingData from './AssetBorrowingData/AssetBorrowingData';
import BorrowingDetail from '../../BorrowingDetail/BorrowingDetail';
import TermAndAgreement from './TermAndCondition/TermAndCondition';
import StudentRules from '../../Rules/StudentRules';
import SoftwareFilter from './SoftwareFilter/SoftwareFilter';

const BorrowingData = (props) => {
  const [isLoad, setIsLoad] = useState(false);

  const [borrowingDataState, setBorrowingDataState] = useState(0);

  const [selectedLocation, setSelectedLocation] = useState(null);

  const [selectedLabType, setSelectedLabType] = useState(null);

  const [selectedRoom, setSelectedRoom] = useState([]);

  const [selectedDate, setSelectedDate] = useState(null);

  const [selectedShifts, setSelectedShifts] = useState([]);

  const [rooms, setRooms] = useState([]);

  const [softwares, setSoftwares] = useState([]);

  const [filterSoftwares, setFilterSoftware] = useState([]);

  const [holidays, setHolidays] = useState([]);

  const [availableShifts, setAvailableShift] = useState(null);

  const [availableAssets, setAvailableAssets] = useState([]);

  const [selectedAssets, setSelectedAssets] = useState([]);

  const [labTypes, setLabTypes] = useState([]);

  const [roomTransactions, setRoomTransactions] = useState(null);

  const [isRulesCheck, setRulesCheck] = useState(null);

  const addFilterSoftwareHandler = (software) => {
    let prevfilterSoftwares = [...filterSoftwares];
    prevfilterSoftwares.push(software);
    prevfilterSoftwares.sort((a, b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    });
    setFilterSoftware(prevfilterSoftwares);
  };

  const removeFilterSoftwareHandler = (software) => {
    let prevfilterSoftwares = [...filterSoftwares];
    let removedfilterSoftwares = prevfilterSoftwares.filter(
      (item, index) => item.id !== software.id
    );
    removedfilterSoftwares.sort();
    setFilterSoftware(removedfilterSoftwares);
  };

  const addShiftHandler = (shift) => {
    let prevSelectedShifts = [...selectedShifts];
    prevSelectedShifts.push(shift);
    prevSelectedShifts.sort((a, b) => {
      return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
    });
    setSelectedShifts(prevSelectedShifts);
  };

  const removeShiftHandler = (shift) => {
    let prevSelectedShifts = [...selectedShifts];

    let removedSelectedShift = prevSelectedShifts.filter(
      (item, index) => item.id !== shift.id
    );

    removedSelectedShift.sort();

    setSelectedShifts(removedSelectedShift);
  };

  const addAssetHandler = (asset) => {
    let prevSelectedAssets = [...selectedAssets];
    prevSelectedAssets.push(asset);

    setSelectedAssets(prevSelectedAssets);
  };

  const removeAssetHandler = (asset) => {
    let prevSelectedAssets = [...selectedAssets];

    let removedSelectedShift = prevSelectedAssets.filter(
      (item, index) => item.id !== asset.id
    );

    setSelectedAssets(removedSelectedShift);
  };

  const addRoomHandler = (room) => {
    let prevSelectedRooms = [...selectedRoom];
    prevSelectedRooms.push(room);

    prevSelectedRooms.sort((a, b) => {
      return a.room_code > b.room_code ? 1 : a.room_code < b.room_code ? -1 : 0;
    });

    setSelectedRoom(prevSelectedRooms);
  };

  const setSelectedRoomFromList = (room) => {
    let selected = [];
    selected.push(room);
    setSelectedRoom(selected);
  };

  const removeRoomHandler = (room) => {
    let prevSelectedRooms = [...selectedRoom];

    let removedRoomHandler = prevSelectedRooms.filter(
      (item, index) => item.id !== room.id
    );

    removedRoomHandler.sort();

    setSelectedRoom(removedRoomHandler);
  };

  const [userData, setUserData] = useState({
    phone: '',
    email: '',
    purpose: '',
    chemicals: '',
    need_internet: '',
    borrower_count: 1,
  });

  const setUserDataHandler = (type, data) => {
    let newState = { ...userData };

    if (type === 'phone') {
      newState.phone = data;
    } else if (type === 'email') {
      newState.email = data;
    } else if (type === 'purpose') {
      newState.purpose = data;
    }else if (type === 'chemicals') {
      newState.chemicals = data;
    }else if(type === 'need_internet'){
      newState.need_internet = data;
    } else if (type === 'borrower_count') {
      newState.borrower_count = Number(data)
    }
    // console.log(newState)
    setUserData(newState);
  };

  const deleteTeammateDataHandler = (index) => {
    let prevData = [...teammateData];
    prevData.splice(index, 1);

    setTeammateData(prevData);
    return true;
  };

  const [teammateData, setTeammateData] = useState(
    props.user.binusian_id !== '-'
      ? [
          {
            binusian_id: props.user.binusian_id,
            name: props.user.name,
          },
        ]
      : []
  );

  const setTeammateDataHandler = async (data) => {
    let prevData = [...teammateData];
    setIsLoad(true);
    data = data.trim();
    if (selectedRoom != null && selectedRoom.group_setting != null) {
      if (
        prevData.length + 2 >
        selectedRoom.group_setting.maximum_member_per_group
      ) {
        props.messageHandler(
          'the number of members has reached the limit',
          'error',
          true
        );
        setIsLoad(false);
        return false;
      }
    }
    //cek apakah nim valid!
    try {
      let request = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + 'binusian/' + data
      );

      const noData = !request.data || !request.data.BinusianID;
      if (noData && request.data.BinusianID === '-') {
        props.messageHandler('Credential Invalid', 'error', true);
        setIsLoad(false);
        return false;
      }

      if (request.data.BinusianID === props.user.binusian_id) {
        props.messageHandler(
          "Teammate ID can't be the same with leader ID",
          'error',
          true
        );
        setIsLoad(false);
        return false;
      } else {
        for (let i = 0; i < prevData.length; i++) {
          if (prevData[i].binusian_id === request.data.BinusianID) {
            props.messageHandler('Teammate ID already exist', 'error', true);
            setIsLoad(false);
            return false;
          }
        }
      }
      prevData.push({
        binusian_id: request.data.BinusianID,
        name: request.data.Name,
      });

      setTeammateData(prevData);
      setIsLoad(false);
      return true;
    } catch (error) {
      props.messageHandler(
        'Unexpected error, please contact the administrator',
        'error',
        true
      );
      setIsLoad(false);
      return false;
    }
  };

  const submitFormHandler = async () => {
    setIsLoad(true);

    const assetsId = selectedAssets.reduce((acc, item, index) => {
      acc.push(item.id);
      return acc;
    }, []);

    const formData = {
      selectedLabType: selectedLabType,
      selectedRoom: selectedRoom,
      selectedDate: selectedDate,
      selectedShifts: selectedShifts,
      userData: { ...userData, ...props.user },
      teammateData: teammateData,
      assets: assetsId,
    };

    if (selectedLabType == null || selectedLabType === '') {
      props.messageHandler('Lab type must be selected', 'error', true);
      return;
    } else if (formData.selectedRoom == null || formData.selectedRoom === '') {
      props.messageHandler('Room must be selected', 'error', true);
      return;
    } else if (formData.selectedDate == null || formData.selectedDate === '') {
      props.messageHandler('Date must be selected', 'error', true);
      return;
    } else if (formData.selectedDate == null || formData.selectedDate === '') {
      props.messageHandler('Date must be selected', 'error', true);
      return;
    } else if (
      formData.userData.phone == null ||
      formData.userData.phone === ''
    ) {
      props.messageHandler('Phone must be filled', 'error', true);
      return;
    } else if (
      formData.userData.purpose == null ||
      formData.userData.purpose === ''
    ) {
      props.messageHandler('Purpose must be filled', 'error', true);
      return;
    }

    //check end shift or end date
    if (
      formData.selectedShifts == null ||
      formData.selectedShifts.length === 0
    ) {
      props.messageHandler('Shift(s) must be selected', 'error', true);
      return;
    }
    try {
      let submit = await Axios.post(
        process.env.REACT_APP_DEFAULT_API + 'student/home/submit-borrowing',
        formData
      );
      // let msg = submit.data.status
      //   ? submit.data.message + '. Please wait for email confirmation'
      //   : submit.data.message;
      // props.messageHandler(
      //   msg,
      //   'error',
      //   true
      // )
      // setIsLoad(false);
      // return
      let message = submit.data.payload.status
        ? submit.data.payload.message + '. Please wait for email confirmation'
        : submit.data.payload.message;
      props.messageHandler(
        message,
        submit.data.payload.status ? 'success' : 'error',
        true
      );

      if (submit.data.payload.status) {
        setTimeout(() => {
          props.history.push('/home');
        }, 2000);
      }
    } catch (error) {
      
      props.messageHandler(
        'Unexpected error, please contact the administrator',
        'error',
        true
      );
    }

    setIsLoad(false);
  };

  const [locations, setLocations] = useState([]);

  useEffect(() => {
    Axios.defaults.withCredentials = true;
    let fetchHomeData = async () => {
      setIsLoad(true);
      let data;
      try {
        data = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + 'student/home'
        );
        data = data.data.payload;
        setLocations(data.locations);
      } catch (error) {
        props.messageHandler(
          'Unexpected error, please contact the administrator',
          'error',
          true
        );
      }
      setIsLoad(false);
    };

    fetchHomeData();
  }, []);

  useEffect(() => {
    if (borrowingDataState === 2) {
      Axios.defaults.withCredentials = true;
      let fetchRoomTypesData = async () => {
        setIsLoad(true);
        let data;
        try {
          data = await Axios.get(
            process.env.REACT_APP_DEFAULT_API + `student/home/room-types`,
            {
              params: {
                room_location_id: selectedLocation.id,
              },
            }
          );
          data = data.data.payload;
          setLabTypes(data.room_types);
        } catch (error) {
          props.messageHandler(
            'Unexpected error, please contact the administrator',
            'error',
            true
          );
        }
        setIsLoad(false);
      };

      fetchRoomTypesData();
    }
  }, [selectedLocation, borrowingDataState]);

  useEffect(() => {
    if (selectedDate !== null) {
      let roomCodes = [];
      rooms.forEach((element) => {
        roomCodes.push(element.room_code);
      });
      Axios.defaults.withCredentials = true;
      let fetchRoomTransaction = async () => {
        setIsLoad(true);
        let data;
        try {
          data = await Axios.get(
            process.env.REACT_APP_DEFAULT_API + `student/home/room-transaction`,
            {
              params: {
                room_codes: roomCodes,
                date: selectedDate,
              },
            }
          );
          data = data.data.payload;
          setRoomTransactions(data);
        } catch (error) {
          props.messageHandler(
            'Unexpected error, please contact the administrator',
            'error',
            true
          );
        }
        setIsLoad(false);
      };

      fetchRoomTransaction();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (borrowingDataState === 3) {
      let fetchRoom = async () => {
        setIsLoad(true);
        let filterRoom = null;
        if(filterSoftwares.length > 0){
          let softwareIds= filterSoftwares.map(x=>x.id);
          let data;
          try {
            data = await Axios.post(
              process.env.REACT_APP_DEFAULT_API + 'software/room',
              {
                softwareIds: softwareIds
              }
            );
          } catch (error) {}

          filterRoom = data.data.payload;
        }
        
        {
          let data;
          try {
            data = await Axios.get(
              process.env.REACT_APP_DEFAULT_API + 'student/home/rooms',
              {
                params: {
                  room_type_id: selectedLabType.id,
                },
              }
            );
          } catch (error) {}

          data = data.data.payload.data;
          if(filterRoom !== null){
            data = data.filter(x=>filterRoom.find(y=>y.Name===x.room_code) !== undefined);
          }
          setRooms(data);
        }
        {
          let data;
          try {
            data = await Axios.get(
              process.env.REACT_APP_DEFAULT_API + 'holiday/'+new Date().getFullYear()
            );
          } catch (error) {}

          data = data.data.payload;
          setHolidays(data);
        }

        setIsLoad(false);
      };
      fetchRoom();
    }
  }, [selectedLabType, borrowingDataState]);

  useEffect(() => {
    if (borrowingDataState === 9) {
      let fetchRoom = async () => {
        setIsLoad(true);
        let data;
        try {
          data = await Axios.get(
            process.env.REACT_APP_DEFAULT_API + 'software/all'
          );
        } catch (error) {}
        
        data = data.data.payload.Software;
        
        if(data)
        {
          const newData = data.map(x=>{
            return {
              "id": x.SoftwareId,
              "name": x.Name + " " + x.Version
            }
          })
          setSoftwares(newData);
        }
        setIsLoad(false);
      };
      fetchRoom();
    }
  }, [selectedLabType, borrowingDataState]);

  useEffect(() => {
    if (
      selectedRoom != null &&
      Object.keys(selectedRoom).length !== 0 &&
      selectedDate != null
    ) {
      setIsLoad(true);
      setSelectedAssets([]);
      let fetchShifts = async () => {
        try {
          setSelectedShifts([]);
          let data = await Axios.get(
            process.env.REACT_APP_DEFAULT_API + 'student/home/available-shifts',
            {
              params: {
                room_id: Array.isArray(selectedRoom)
                  ? selectedRoom[0].id
                  : selectedRoom.id,
                date: selectedDate,
              },
            }
          );
          data = data.data.payload;
          setAvailableShift(data);
          let selectedRoomsId = selectedRoom.reduce((acc, item, index) => {
            acc.push(item.id);

            return acc;
          }, []);

          let assets = await Axios.post(
            process.env.REACT_APP_DEFAULT_API + 'student/home/assets',
            {
              rooms: selectedRoomsId,
            }
          );
          assets = assets.data;
          setAvailableAssets(assets);
        } catch (e) {
          alert(e);
          props.messageHandler(
            'Unexpected error, please call the administrator',
            'error',
            true
          );
        }
        setIsLoad(false);
      };

      fetchShifts();
    }
  }, [selectedRoom, selectedDate]);

  return (
    <>
      {isLoad ? <Loading /> : ''}
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 0}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <BorrowingLocation
          locations={locations}
          locationHandler={(data) => {
            setSelectedLocation(data);
            setSelectedLabType(null);
            setSelectedShifts([]);
            setSelectedRoom([]);
            setSelectedDate(null);
            setAvailableShift(null);
          }}
          selectedLocation={selectedLocation}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 1}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <StudentRules
          setRulesCheck={setRulesCheck}
          isRulesCheck={isRulesCheck}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 2}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <LaboratoryType
          labTypes={labTypes}
          labTypeHandler={(data) => {
            setSelectedLabType(data);
            setSelectedShifts([]);
            setSelectedRoom([]);
            setSelectedDate(null);
            setAvailableShift(null);
            setFilterSoftware([]);
          }}
          selectedLabType={selectedLabType}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 3}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <RoomAndDate
          user={props.user}
          rooms={rooms}
          availableShifts={availableShifts}
          roomTransactions={roomTransactions}
          selectedLabType={selectedLabType}
          selectedRoom={selectedRoom}
          selectedDate={selectedDate}
          selectedShifts={selectedShifts}
          holidays={holidays}
          roomHandler={setSelectedRoomFromList}
          dateHandler={setSelectedDate}
          addRoomHandler={addRoomHandler}
          removeRoomHandler={removeRoomHandler}
          addShiftHandler={addShiftHandler}
          removeShiftHandler={removeShiftHandler}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 4}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
        //onEntered={(element, isAppearing) => {}}
      >
        <AssetBorrowingData
          availableAssets={availableAssets}
          selectedAssets={selectedAssets}
          addAssetHandler={addAssetHandler}
          removeAssetHandler={removeAssetHandler}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 5}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <BorrowerData
          userData={userData}
          setUserDataHandler={setUserDataHandler}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
          selectedLabType={selectedLabType}
          borrowingDataState={availableAssets.length === 0 ? 2 : 3}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 6}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <BorrowerTeamData
          user={props.user}
          teammateData={teammateData}
          groupSetting={selectedRoom ? selectedRoom.group_setting : null}
          setTeammateDataHandler={setTeammateDataHandler}
          deleteTeammateDataHandler={deleteTeammateDataHandler}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 7}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <TermAndAgreement changeStateHandler={setBorrowingDataState} />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 8}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <BorrowingDetail
          newBorrowing={true}
          submitFormHandler={submitFormHandler}
          date={selectedDate}
          user={props.user}
          userData={userData}
          laboratoryType={selectedLabType}
          rooms={selectedRoom}
          assets={selectedAssets}
          shifts={selectedShifts}
          teammateData={teammateData}
          loadingHandler={setIsLoad}
          changeStateHandler={setBorrowingDataState}
          messageHandler={props.messageHandler}
        />
      </CSSTransition>
      <CSSTransition
        unmountOnExit
        in={borrowingDataState === 9}
        classNames='ContainerAnim'
        timeout={{
          enter: 500,
        }}
      >
        <SoftwareFilter 
          softwares={softwares}
          filterSoftwares={filterSoftwares}
          changeStateHandler={setBorrowingDataState}
          addFilterSoftwareHandler={addFilterSoftwareHandler}
          removeFilterSoftwareHandler={removeFilterSoftwareHandler}
        />
      </CSSTransition>   
    </>
  );
};

export default BorrowingData;
