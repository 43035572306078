import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import PrivateRoute from './component/PrivateRoute/PrivateRoute';
import HomeContainer from './container/Home/HomeContainer';
import Provider from "./Provider"
import classes from './App.module.css';
import Login from './container/Login/Login';
import Maintenance from './container/Maintenance/Maintenance';


class App extends Component {
  componentDidMount = () => {
    const el = document.getElementById('starting-load');
    window.onload = () => {
      if (el) {
        el.remove();
      }
    };
  };
  

  render() {
    return (
      <div className={classes.Borrowing_App}>
        <Provider>
          <Switch>
            {/* <Route path='/' exact component={Maintenance} /> */}
            <Route path='/login/microsoft' exact component={Login} />
            <Route path='/login' exact component={Login} />
            <PrivateRoute path='/' component={HomeContainer} />
            <Redirect to='/login' />
          </Switch>
        </Provider>
      </div>
    );
  }
}

export default App;
