import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import ReactTable from 'react-table';

import Backdrop from '../../../../../component/UI/Backdrop/Backdrop';

import 'react-table/react-table.css';
import './CustomReactTable.css';

const StudentSettings = (props) => {
  const [student, setStudent] = useState([]);

  const [dialogBoxShow, setDialogBoxShow] = useState(false);
  const [dialogPurpose, setDialogPurpose] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    fetchStudent();
  }, []);

  const fetchStudent = async () => {
    try {
      props.loadingHandler(true);
      let data = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + 'staff/student/all'
      );

      if (data.data && data.data.payload.status) {
        setStudent(data.data.payload.data);
      }
    } catch (e) {
      props.messageHandler(
        'Unexpected error please call the administrator',
        'error',
        true
      );
    }

    props.loadingHandler(false);
  };

  const activateInactivateButtonHandler = async (id, banReason = null) => {
    props.loadingHandler(true);
    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API + 'staff/student/toggle',
        {
          id: id,
          banReason: banReason,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler(result.data.payload.message, 'success', true);
      } else {
        props.messageHandler(result.data.payload.message, 'error', true);
      }

      props.loadingHandler(false);

      fetchStudent();
    } catch (e) {
      props.messageHandler('Unexpected error, please call the administrator');
      props.loadingHandler(false);
    }
  };

  const showHideDialogBox = (mode, student = null, purpose = null) => {
    setDialogPurpose(purpose);
    setSelectedStudent(student);

    setDialogBoxShow(mode);
  };

  const columns = [
    {
      Header: 'BinusianID',
      accessor: 'username',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (props) => {
        let style = { fontWeight: 'bold', color: '#3c763d' };
        if (props.value === 'Banned') {
          style.color = '#a94442';
        }
        return <span style={style}>{props.value}</span>;
      },
    },
    {
      Header: 'Action',
      filterable: false,
      sortable: false,
      Cell: (props) => {
        let style = {
          fontWeight: 'bold',
          color: '#3c763d',
          backgroundColor: '#dff0d8',
          display: 'inline-block',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          cursor: 'pointer',
          userSelect: 'none',
        };

        //kalo 1 berarti dia lagi ke ban!
        let val =
          props.original.status === 'Banned' ? 'Activate' : 'Inactivate';

        if (props.original.status === 'Active') {
          style.color = '#a94442';
          style.backgroundColor = '#f2dede';
        }

        return (
          <span
            style={style}
            onClick={() => showHideDialogBox(true, props.original, val)}
          >
            {val}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Backdrop
        clickable={true}
        clickHandler={showHideDialogBox}
        show={dialogBoxShow}
      />
      <DialogBox
        messageHandler={props.messageHandler}
        dialogPurpose={dialogPurpose}
        selectedStudent={selectedStudent}
        activateInactivateButtonHandler={activateInactivateButtonHandler}
        show={dialogBoxShow}
      />
      <ReactTable
        data={student}
        columns={columns}
        filterable={true}
        defaultPageSize={10}
        className='-striped -highlight'
      />
    </>
  );
};

const DialogBox = (props) => {
  let dialogBoxStyles = {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '105',
    padding: '16px 18px',
    minWidth: '260px',
    maxWidth: '300px',
    backgroundColor: 'white',
    boxShadow: '0 1px 0 rgba(12,13,14,0.1), 0 1px 6px rgba(59,64,69,0.1)',
    visibility: 'hidden',
  };

  let buttonStyle = {
    marginTop: '10px',
    fontWeight: 'bold',
    color: '#3c763d',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#dff0d8',
    display: 'inline-block',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  };

  if (props.show) {
    dialogBoxStyles.visibility = 'visible';
  }

  const DialogBoxContent = () => {
    const [banReasonText, setBanReasonText] = useState('');
    //kalo inactive berarti dia mo matiin usernya
    if (props.dialogPurpose === 'Inactivate') {
      buttonStyle.color = '#a94442';
      buttonStyle.backgroundColor = '#f2dede';

      return (
        <>
          <div
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            Please give a reason why we need to ban this person
          </div>
          <input
            type='text'
            value={banReasonText}
            onChange={(evt) => {
              setBanReasonText(evt.target.value);
            }}
          />
          <div
            style={buttonStyle}
            onClick={() => {
              if (banReasonText.length === 0) {
                props.messageHandler('Reason must be filled', 'error', true);
                return;
              }

              props.activateInactivateButtonHandler(
                props.selectedStudent.username,
                banReasonText
              );
            }}
          >
            {props.dialogPurpose}
          </div>
        </>
      );
    } else if (props.dialogPurpose === 'Activate') {
      return (
        <>
          <div style={{ fontWeight: 'bold' }}>BAN REASON</div>
          <div>
            {props.selectedStudent === null
              ? ''
              : props.selectedStudent.ban_reason}
          </div>
          <div
            style={buttonStyle}
            onClick={() => {
              props.activateInactivateButtonHandler(
                props.selectedStudent.username
              );
            }}
          >
            {props.dialogPurpose}
          </div>
        </>
      );
    } else {
      return '';
    }
  };

  return <div style={dialogBoxStyles}>{DialogBoxContent()}</div>;
};

export default StudentSettings;
