import React from 'react';
import Moment from 'moment';

import classes from './BorrowingList.module.css';

const BorrowingList = (props) => {
  // eslint-disable-next-line
  const { date, borrowing_status, room, userData, created_at } = props.data;

  let statusClasses = [classes.HeadContainer_Status];

  let containerClasses = [classes.Container];

  if (borrowing_status === 'Accepted') {
    statusClasses.push(classes.HeadContainer_Status__Accepted);
    containerClasses.push(classes.Container__Accepted);
  } else if (borrowing_status === 'Rejected') {
    statusClasses.push(classes.HeadContainer_Status__Rejected);
    containerClasses.push(classes.Container__Rejected);
  } else if (borrowing_status === 'Canceled') {
    statusClasses.push(classes.HeadContainer_Status__Canceled);
    containerClasses.push(classes.Container__Canceled);
  } else {
    statusClasses.push(classes.HeadContainer_Status__Pending);
    containerClasses.push(classes.Container__Pending);
  }

  return (
    <div
      className={containerClasses.join(' ')}
      onClick={() => props.onClickHandler(props.data)}
    >
      <div className={classes.HeadContainer}>
        <div className={classes.DateContainer}>
          <div style={{ fontWeight: 'bold', fontSize: '11pt' }}>
            Borrowing Date: <span style={{color:"#50C878"}}>{Moment(date, 'YYYY-MM-DD').format('ddd, DD MMMM YYYY')}</span>
          </div>
          <div style={{ fontWeight: 'light', fontSize: '9pt' }}>
            Request Date: <span style={{color:"#708090"}}>{created_at}</span>
          </div>
        </div>
        <div>
          <span className={statusClasses.join(' ')}>{borrowing_status}</span>
        </div>
      </div>
      <div className={classes.BodyContainer}>
        <div>{room.room_code + ' - ' + room.type.name}</div>
        <div>{userData.email}</div>
      </div>
    </div>
  );
};

export default BorrowingList;
