import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Dropdown } from "semantic-ui-react";

import BorrowingList from "../../../../component/UI/List/BorrowingList/BorrowingList";
import BorrowingDetail from "../../Student/BorrowingDetail/BorrowingDetail";
import SubMenu from "../../../../component/Navigation/Menu/SubMenu/SubMenu";

import classes from "./StaffBorrowingHistory.module.css";
import Pagination from "../../../../component/Pagination/Pagination";

const StaffBorrowingHistory = (props) => {
  const [data, setData] = useState([]);
  const [currPage, setCurrPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [semester, setSemester] = useState([]);
  const [roomPIC, setRoomPIC] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState(0);
  const [selectedRoomPIC, setSelectedRoomPIC] = useState("All");
  const [currentSemester, setCurrentSemester] = useState(0);
  const [serverTime, setServerTime] = useState(null);
  const [menuMode, setMenuMode] = useState(0);
  const [selectedSubMenu, setSelectedSubMenu] = useState(0);
  const [unfinalizedDataCount, setUnfinalizedDataCount] = useState(0);
  const [isDetailShow, setDetailShow] = useState({
    show: false,
    data: null,
  });

  const handleChangeRoomPIC = (room) => {
    setCurrPage(1);
    setSelectedRoomPIC(room);
  };

  const handleSelectedSemester = (num) => {
    setCurrPage(1);
    setSelectedSemester(num);
  };

  const changeSubMenu = (num) => {
    setCurrPage(1);
    setSelectedSubMenu(num);
  };
  const changeMenuHandler = (num) => {
    setCurrPage(1);
    setMenuMode(num);
  };

  const subMenuList = [
    {
      name: "All",
      cssClasses: [classes.All],
      handler: () => changeSubMenu(0),
    },
    {
      name: "Accepted[" + unfinalizedDataCount + " unfinalized]",
      cssClasses: [classes.Accepted],
      handler: () => changeSubMenu(1),
    },
    {
      name: "Rejected",
      cssClasses: [classes.Rejected],
      handler: () => changeSubMenu(2),
    },
    {
      name: "Canceled",
      cssClasses: [classes.Canceled],
      handler: () => changeSubMenu(3),
    },
    {
      name: "Finalized",
      cssClasses: [classes.Finalized],
      handler: () => changeSubMenu(4),
    },
  ];

  const onClickDataHandler = (data) => {
    console.log(data)
    setDetailShow({
      show: true,
      data: data,
    });
  };

  const backButtonHandler = () => {
    setDetailShow({
      show: false,
      data: null,
    });
  };

  const getUnfinalizedDataCount = async () => {
    let result = await Axios.get(
      process.env.REACT_APP_DEFAULT_API + "staff/borrowing/accepted",
      {
        params: {
          pic: props.userData.username,
          semesterId: selectedSemester,
        },
      }
    );
    setUnfinalizedDataCount(result.data.payload.data.length);
  };

  const acceptButtonHandler = async (id, reason) => {
    props.loadingHandler(true);
    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API + "staff/accept-borrowing",
        {
          id: id,
          username: props.userData.username,
          cid: props.userData.cid,
          reason: reason,
        }
      );
      if (result.data.payload.status) {
        props.messageHandler(
          result.data.payload.message,
          "Accept Success",
          true
        );
      } else {
        props.messageHandler(result.data.payload.message, "Error", true);
      }

      setTimeout(() => {
        props.loadingHandler(true);
      }, 1000);

      setTimeout(() => {
        props.loadingHandler(false);
        window.location.reload();
      }, 3000);
    } catch (e) {
      props.messageHandler(
        "Unexpected error please call the administrator",
        "error",
        true
      );
      props.loadingHandler(false);
    }
  };

  const rejectButtonHandler = async (id, reason) => {
    console.log(reason);

    if (reason == "ISI DONG REASONNYA") {
      props.messageHandler(
        "Additional reason must be filled to reject!",
        "error",
        true
      );
      return;
    }

    props.loadingHandler(true);
    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API + "staff/reject-borrowing",
        {
          id: id,
          username: props.userData.username,
          reason: reason,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler(result.data.payload.message, "success", true);
      } else {
        props.messageHandler(result.data.payload.message, "error", true);
      }

      setTimeout(() => {
        props.loadingHandler(true);
      }, 1000);

      setTimeout(() => {
        props.loadingHandler(false);
        window.location.reload();
      }, 3000);
    } catch (e) {
      props.messageHandler(
        "Unexpected error please call the administrator",
        "error",
        true
      );
      props.loadingHandler(false);
    }
  };

  const finalizeButtonHandler = async (
    id,
    description,
    status,
    leader_id,
    assetCalibrations
  ) => {
    props.loadingHandler(true);

    if (description !== "" && status !== "") {
      try {
        let result = await Axios.patch(
          process.env.REACT_APP_DEFAULT_API + "staff/finalize-borrowing",
          {
            id: id,
            username: props.userData.username,
            status: status,
            description: description,
            leader_id: leader_id,
            asset_calibrations: assetCalibrations,
          }
        );

        if (result.data.payload.status) {
          props.messageHandler(result.data.payload.message, "success", true);
          props.loadingHandler(false);
        } else {
          props.messageHandler(result.data.payload.message, "error", true);
          props.loadingHandler(false);
        }
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (e) {
        props.messageHandler(
          "Unexpected error please call the administrator",
          "error",
          true
        );
        props.loadingHandler(false);
      }
    } else {
      props.messageHandler(
        "Please input all the requested data",
        "error",
        true
      );
      props.loadingHandler(false);
    }
  };

  const getSubMenu = (subMenu) => {
    if (subMenu == 0) {
      return "all";
    } else if (subMenu == 1) {
      return "accepted";
    } else if (subMenu == 2) {
      return "rejected";
    } else if (subMenu == 3) {
      return "canceled";
    } else if (subMenu == 4) {
      return "finalized";
    }
  };

  const dataInput = (res) => {
    setData(res.payload.data);
    setServerTime(res.payload.server_time);
    setCurrPage(res.current_page);
    setLastPage(res.last_page);
    props.loadingHandler(false);
  };

  // dependent ke selectedRoomPIC ama selectedSemester
  const fetchData = async (page) => {
    // console.log("menu mode = " + menuMode);
    setData([]);
    let roomId;
    props.loadingHandler(true);
    if (selectedRoomPIC == 0) {
      roomId = "All";
    } else {
      roomId = selectedRoomPIC;
    }
    // console.log(roomId);
    if (menuMode === 0) {
      Axios.get(process.env.REACT_APP_DEFAULT_API + "staff/borrowing/list", {
        params: {
          pic: props.userData.username,
          semesterId: selectedSemester,
          roomId: roomId,
          filter: "pending",
          page: page,
        },
      })
        .then((result) => {
          if (result.data.payload.data) {
            console.log(result);
            const res = result.data;
            dataInput(res);
          }
          
        })
        .catch((e) => {
          props.messageHandler(
            "Unexpected error, please contact the administrator",
            "error",
            true
          );
          setData([]);
          
        }).finally(() => {
          props.loadingHandler(false);
        });
    } else if (selectedSubMenu >= 0 && selectedSubMenu <= 4) {
      Axios.get(process.env.REACT_APP_DEFAULT_API + "staff/borrowing/list", {
        params: {
          pic: props.userData.username,
          semesterId: selectedSemester,
          roomId: roomId,
          filter: getSubMenu(selectedSubMenu),
          page: page,
        },
      })
        .then((result) => {
          if (result.data.payload.data) {
            // console.log(result);
            const res = result.data;
            dataInput(res);
          }
        })
        .catch((e) => {
          props.messageHandler(
            "Unexpected error, please contact the administrator",
            "error",
            true
          );
          setData([]);
        
        }).finally(() => {
          props.loadingHandler(false);
        });
    }
  };

  const fetchSemester = async () => {
    props.loadingHandler(true);
    Axios.get(process.env.REACT_APP_DEFAULT_API + "semester")
      .then((res) => {
        const semData = res.data.semester.data;
        if (semData.length !== 0) {
          const temp = [];
          for (let i = 0; i < semData.length; i++) {
            temp.push({
              key: semData[i].id,
              value: semData[i].id,
              text: semData[i].description,
            });
          }
          setSemester(temp);
        }
      })
      .catch((error) => {
        props.messageHandler(
          "Unexpected error, please contact the administrator",
          "error",
          true
        );
        setSemester([]);
      }).finally(() => {
        props.loadingHandler(false);
      });
  };

  const fetchRoomPICRoom = async () => {
    props.loadingHandler(true);
    Axios.get(
      process.env.REACT_APP_DEFAULT_API +
        "staff/room/borrowing-pic/" +
        props.userData.username
    )
      .then((res) => {
        const roomPicData = res.data.payload.data;
        if (roomPicData.length !== 0) {
          const temp = [
            {
              key: 0,
              value: "All",
              text: "All",
            },
          ];
          for (let i = 0; i < roomPicData.length; i++) {
            temp.push({
              key: roomPicData[i].id,
              value: roomPicData[i].room_code,
              text: roomPicData[i].room_code,
            });
          }
          
          // setSelectedRoomPIC("All"); // INIGO, gk perlu karena default udh All 
          setRoomPIC(temp);
        }
      })
      .catch((error) => {
        props.messageHandler(
          "Unexpected error, please contact the administrator",
          "error",
          true
        );
        setRoomPIC([]);
        
      }).finally(() => {
        props.loadingHandler(false);
      });
  };

  const getCurrentSemester = async () => {
    try {
      let result = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + "semester/current"
      );
      setSelectedSemester(result.data.currentSemester.id);
      setCurrentSemester(result.data.currentSemester);
    } catch (error) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
      setSelectedSemester(1);
    }
  };

  useEffect(() => {
    getCurrentSemester();
    fetchSemester();
    fetchRoomPICRoom();
    getUnfinalizedDataCount();
  }, []);

  useEffect(() => {
    // console.log(selectedRoomPIC);
    if (selectedSemester !== 0 && selectedRoomPIC !== undefined) {
      // getUnfinalizedDataCount();
      fetchData(currPage);
    }
  }, [selectedSemester, selectedRoomPIC, menuMode, selectedSubMenu]);

  const fetchNext = () => {
    if (currPage < lastPage) {
      setCurrPage(currPage + 1);
      fetchData(currPage + 1);
    }
  };
  const fetchPrev = () => {
    if (currPage > 1) {
      setCurrPage(currPage - 1);
      fetchData(currPage - 1);
    }
  };

  const fetchPage = (page) => {
    if (page >= 1 && page <= lastPage) {
      setCurrPage(page);
      fetchData(page);
    }
  };

  const borrowingList = () => {
    if (data.length > 0) {
      const render = (
        <>
          {data.map((item, index) => (
            <BorrowingList
              onClickHandler={onClickDataHandler}
              key={index}
              data={item}
            />
          ))}
          <Pagination
            totalPage={lastPage}
            currPage={currPage}
            fetchNext={fetchNext}
            fetchPrev={fetchPrev}
            fetchPage={fetchPage}
          />
        </>
      );

      return render;
    }

    return (
      <div
        style={{
          textAlign: "center",
          height: "100%",
          fontWeight: "bold",
        }}
      >
        No data available
      </div>
    );
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {isDetailShow.show ? (
        ""
      ) : (
        <>
          {/* StaffBorrowingHistory */}
          {currentSemester.id ? (
            <Dropdown
              selection
              fluid
              header
              defaultValue={selectedSemester}
              onChange={(evt, { value }) => {
                handleSelectedSemester(value);
              }}
              options={semester}
            />
          ) : (
            <div>
              <Dropdown selection fluid header options={semester} />
            </div>
          )}
          {isDetailShow.show ? (
            <div></div>
          ) : (
            <div>
              <Dropdown
                selection
                fluid
                header
                defaultValue="All"
                onChange={(evt, { value }) => {
                  handleChangeRoomPIC(value);
                }}
                options={roomPIC}
              />
            </div>
          )}
          <MainMenu
            unfinalizedDataCount={unfinalizedDataCount}
            menu={menuMode}
            selectedSubMenu={selectedSubMenu}
            subMenuList={subMenuList}
            changeMenuHandler={changeMenuHandler}
          />
        </>
      )}

      <div style={{ width: "100%", marginTop: "24px" }}>
        {isDetailShow.show ? (
          <BorrowingDetail
            detailBorrowing={true}
            pic={true}
            picData={props.userData}
            backButtonHandler={backButtonHandler}
            acceptButtonHandler={acceptButtonHandler}
            rejectButtonHandler={rejectButtonHandler}
            finalizeButtonHandler={finalizeButtonHandler}
            loadingHandler={props.loadingHandler}
            history={props.history}
            messageHandler={props.messageHandler}
            id={isDetailShow.data.id}
            status={isDetailShow.data.borrowing_status}
            date={isDetailShow.data.date}
            reason={isDetailShow.data.reason}
            serverTime={serverTime}
            user={isDetailShow.data.user}
            userData={isDetailShow.data.userData}
            room={isDetailShow.data.room}
            laboratoryType={isDetailShow.data.room.type}
            assets={isDetailShow.data.assets}
            shifts={isDetailShow.data.detail_shifts}
            teammateData={isDetailShow.data.teammateData}
            finalizeData={{
              status: isDetailShow.data.attendance_status,
              description: isDetailShow.data.finalize_note,
            }}
          />
        ) : (
          borrowingList()
        )}
      </div>
    </div>
  );
};

const MainMenu = (props) => {
  return (
    <>
      <div className={classes.MenuContainer}>
        <span
          className={props.menu === 0 ? classes.Active : ""}
          onClick={() => props.changeMenuHandler(0)}
        >
          Recent
        </span>
        <span
          className={props.menu === 1 ? classes.Active : ""}
          onClick={() => props.changeMenuHandler(1)}
        >
          History [{props.unfinalizedDataCount} data unfinalized]
        </span>
      </div>
      {props.menu === 1 ? (
        <SubMenu
          subMenuList={props.subMenuList}
          selectedSubMenu={props.selectedSubMenu}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default StaffBorrowingHistory;
