import React from 'react';

import classes from '../../NewAssetBorrowing.module.css';

const BorrowerData = (props) => {
  const nextButtonHandler = () => {
    if (
      props.userData.phone != null &&
      props.userData.phone !== '' &&
      props.userData.purpose != null &&
      props.userData.purpose !== ''
    ) {
      props.changeStateHandler(7);
    }
  };

    const backButtonHandler = () => {
        props.changeStateHandler(5);
    }

  let nextButtonClasses = [classes.NextButton];

  if (
    props.userData.phone == null ||
    props.userData.phone === '' ||
    props.userData.purpose == null ||
    props.userData.purpose === ''
  ) {
    nextButtonClasses.push(classes.ButtonDisabled);
  }

  return (
    <div className={classes.BorrowingDataContainer}>
      <div className={classes.Title}>
        Next, we will need information about you...
      </div>
      <div className={classes.SubTitle__Container}>
        <div className={classes.SubTitle}>Phone</div>
        <p className={classes.Description}>
          Please provide your active number, so we can reach you if something
          happen!
        </p>
        <input
          type="tel"
          className={classes.InputText}
          value={props.userData.phone}
          placeholder="Ex: +62821xxxxxxxxx0"
          pattern="[0-9]{12}"
          onChange={(evt) => {
            props.setUserDataHandler('phone', evt.target.value);
          }}
          onKeyPress={(evt) => {
            let reg = new RegExp('\\d');
            if (!reg.test(evt.key)) {
              evt.preventDefault();
            }
          }}
        />
      </div>
      <div className={classes.SubTitle__Container}>
        <div className={classes.SubTitle}>Purpose</div>
        <p className={classes.Description}>
          Tell us briefly about your borrowing request purpose(s)!
        </p>
        <textarea
          className={classes.InputText}
          value={props.userData.purpose}
          placeholder="Ex: Homework for Photography and Design DSGN000"
          onChange={(evt) => {
            props.setUserDataHandler('purpose', evt.target.value);
          }}
        />
      </div>
      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className="material-icons" style={{ marginRight: '8px' }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(' ')}
          onClick={nextButtonHandler}
        >
          Next
          <i className="material-icons" style={{ marginLeft: '8px' }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default BorrowerData;
