import React, { useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Loading from '../../../component/UI/Loading/Loading';
import Home from './StaffBorrowingHistory/StaffBorrowingHistory';
import Settings from './StaffSettings/StaffSettings';
import Feedback from './Feedback/Feedback';
import SubmitFeedback from '../Student/Feedback/SubmitFeedback';
import LogOut from '../LogOut';
import StaffAssetBorrowingHistory from './NewStaffAssetBorrowingHistory/NewStaffAssetBorrowingHistory';
import StaffReporting from './StaffReporting/StaffReporting';

const StaffHome = (props) => {
  const [isLoad, setIsLoad] = useState(false);

  return (
    <>
      {isLoad ? <Loading /> : ''}
      <Switch>
        <Route
          path='/staff/room-borrowing'
          render={(_) => <Home {..._} {...props} loadingHandler={setIsLoad} />}
        />
        <Route
          path='/staff/asset-borrowing'
          render={(_) => <StaffAssetBorrowingHistory {..._} {...props} loadingHandler={setIsLoad} />}
        />
        <Route
          path='/staff/reporting'
          render={(_) => <StaffReporting {..._} {...props} loadingHandler={setIsLoad} />}
        />
        <Route
          path='/staff/settings'
          render={(_) => (
            <Settings {..._} {...props} loadingHandler={setIsLoad} />
          )}
        />
        <Route
          path='/staff/feedback'
          render={(_) => (
            <Feedback {..._} {...props} loadingHandler={setIsLoad} />
          )}
        />
        <Route
          path='/staff/submit-feedback'
          render={(_) => (
            <SubmitFeedback
              userData={props.userData}
              messageHandler={props.messageHandler}
              {..._}
            />
          )}
        />
        <Route
          path='/log-out'
          render={(_) => <LogOut messageHandler={props.messageHandler} />}
        />
        <Redirect to='/staff/room-borrowing' />
      </Switch>
      <br />
    </>
  );
};

export default StaffHome;
