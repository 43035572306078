import React from 'react';
import Asset from './Asset/Asset';

import classes from './Assets.module.css';

const VerticalListsMultipleAssetWithImage = (props) => {
  let assets = Array.isArray(props.assets)
    ? props.assets.reduce((acc, asset, index) => {
        let matchedAsset = props.selectedAssets.filter(
          (selectedAsset, index) => asset.id === selectedAsset.id
        );

        acc.push(
          <Asset
            key={index}
            asset={asset}
            active={matchedAsset.length > 0}
            addAssetHandler={props.addAssetHandler}
            removeAssetHandler={props.removeAssetHandler}
          />
        );
        return acc;
      }, [])
    : 'Data Not Available! (1)';

  // jika hasil reduce tdk ada datanya (semua asset qty == 0)
  if (Array.isArray(assets) && assets.length === 0) {
    assets = 'Data Not Available! (2)';
  }

  return (
    <div className={classes.Container} style={{ marginTop: '0' }}>
      <div className={classes.Container__Assets}>{assets}</div>
    </div>
  );
};

export default VerticalListsMultipleAssetWithImage;
