import React, { useEffect } from "react";
import "./StudentRules.css";
import classes from "../NewBorrowing/NewBorrowing.module.css";

const StudentRules = (props) => {
  const setChekRulesHandler = (e) => {
    if (e.target.checked) props.setRulesCheck(true);
    else props.setRulesCheck(false);
  };

  const nextButtonHandler = () => {
    if (props.isRulesCheck != null && props.isRulesCheck !== false) {
      props.changeStateHandler(2);
    }
  };

  const backButtonHandler = () => {
    props.changeStateHandler(0);
  };

  let nextButtonClasses = [classes.NextButton];

  if (props.isRulesCheck === null || props.isRulesCheck === false) {
    nextButtonClasses.push(classes.ButtonDisabled);
  }

  useEffect(() => {
    if (props.isRulesCheck === false) {
      nextButtonClasses.push(classes.ButtonDisabled);
    }
  }, [props.isRulesCheck]);

  return (
    <div>
      <h4 className="title">Laboratory Borrowing Terms and Conditions</h4>
      <div className="rules">
        <p className="subtitle">
          Binus University Student Activities Health Protocol
        </p>
        <p>
          Students are required to read the Health Protocol documents through <a href="https://binusmaya.binus.ac.id" target="_blank">
            {" Binusmaya "}</a> or <a href="https://lcas-media.apps.binus.ac.id/media/s/1625123017906.pdf" target="_blank">
            {" this link."}
          </a>
        </p>
      </div>
      <div className="checkbox-container">
        <label>
          <input
            type="checkbox"
            className="checkbox"
            onChange={setChekRulesHandler}
            defaultChecked={props.isRulesCheck}
          />
          I agree with terms and conditions
        </label>
      </div>
      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className="material-icons" style={{ marginRight: "8px" }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(" ")}
          onClick={nextButtonHandler}
        >
          Next
          <i className="material-icons" style={{ marginLeft: "8px" }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};
export default StudentRules;
