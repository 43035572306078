import React, { useState } from "react";
import ReactImageZoom from "react-image-zoom";
import notFound from "../../../../../images/image-not-found.jpg"
import classes from "./Asset.module.css";
import { useEffect } from "react";

const Asset = (props) => {
  const { asset } = props;

  const [active, setActive] = useState(props.active);

  let iconClasses = ["material-icons", classes.Icon];

  if (active) {
    iconClasses.push(classes.Active);
  }

  const pad = (num, size) => {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  };

  const addButtonHandler = () => {
    if (!active) {
      props.addAssetHandler(props.asset);
      setActive(true);
    } else {
      props.removeAssetHandler(props.asset);
      setActive(false);
    }
  };

  const getAssetImage = (asset) => {
    if (!asset || !asset.images[0]) return notFound;
    return asset.images[0].path ? asset.images[0].path : notFound;
  };


  const imageProp = {
    width: 150,
    height: 150,
    zoomPosition: "original",
    img: getAssetImage(asset),
  }

  return (
    <div className={classes.Container} onClick={addButtonHandler}>
      <div className={classes.Container_Sub}>
        <div>
          <ReactImageZoom {...imageProp} />
          {/* {asset.images[0].path} */}
          {/* <img src={asset.images[0].path} alt="Asset"  className={classes.img}/> */}
        </div>
        <div>
          <small className={classes.Description__AssetId}>
            {asset.room.room_code}
          </small>
          <div className={classes.Description__AssetId}>{`LCAS${pad(
            asset.id,
            5
          )}`}</div>
          <div className={classes.Description__AssetName}>{asset.name}</div>
          <p>{asset.description}</p>
        </div>
      </div>
      <i className={iconClasses.join(" ")}>{active ? "check" : "add"}</i>
    </div>
  );
};

export default Asset;
