import React, { useEffect, useState } from "react";
import styles from "./Pagination.module.css";
import arrowLeft from "../../images/arrow-left.svg";
import arrowRight from "../../images/arrow-right.svg";

// totalPage: number;
// currPage: number;
// fetchNext?: any;
// fetchPrev?: any;
// fetchPage?: any;

const Pagination = (props) => {
  const [array, setArray] = useState([]);
  const [page, setPage] = useState(0);
  useEffect(() => {
    if (props) {
      // console.log(props)
      const page = props.totalPage;
      const num = [];
      if (page < 5) {
        for (let index = 1; index <= page; index++) {
          num.push(index);
        }
        setArray([...num]);
      } else {
        let end = props.currPage + 2;
        let start = props.currPage - 2;
        if (end > page) {
          start = start - (end - page);
          end = page;
        } else if (start < 1) {
          end = end + Math.abs(start - 1);
          start = 1;
        }
        for (let index = start; index <= end; index++) {
          num.push(index);
        }
        setArray([...num]);
      }
    } else {
      setArray([1]);
    }
    // console.log(array)
  }, [props]);

  function toNumber(str) {
    if (!Number.isNaN(Number(str))) {
      return Number(str);
    }
    return -1;
  }

  const onChangePage = (e) => {
    const target = e.target;
    const p = toNumber(target.value);
    if (p != -1) {
      setPage(p);
    }
  };

  const goToPage = () => {
    props.fetchPage(page);
  };

  return (
    <>
      {props.totalPage > 1 && (
        <div className={styles.container}>
          <div className={styles.btnContainer}>
            <button
              className={
                styles.circularBtn + " " + styles.unselected + " " + styles.icon
              }
              onClick={props.fetchPrev}
            >
              <img
                src={arrowLeft}
                alt=""
                style={{
                  maxWidth: "1rem",
                }}
              />
            </button>
            {array ? (
              array.map((e, i) => (
                <button
                  className={
                    styles.circularBtn +
                    " " +
                    (e == props.currPage ? styles.selected : styles.unselected)
                  }
                  key={i}
                  onClick={() => {
                    props.fetchPage(e);
                  }}
                >
                  {e}
                </button>
              ))
            ) : (
              <></>
            )}
            <button
              className={
                styles.circularBtn + " " + styles.unselected + " " + styles.icon
              }
              onClick={props.fetchNext}
            >
              <img
                src={arrowRight}
                alt=""
                style={{
                  maxWidth: "1rem",
                }}
              />
            </button>
          </div>
          <div className={styles.inputContainer}>
            <input
              type="text"
              name="page"
              id="page"
              className={styles.inputField}
              onChange={onChangePage}
              placeholder={"1 - " + props.totalPage}
            />
            <button className={styles.btn} onClick={goToPage}>
              Go
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;
