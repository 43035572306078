import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Axios from 'axios';
import { useMsal } from '@azure/msal-react';

const LogOut = (props) => {
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isLogout, setIsLogout] = useState(false);
  const { instance, accounts } = useMsal();
  useEffect(() => {
    Axios.defaults.withCredentials = true;

    const logOut = async () => {
      try {
        await Axios.post(process.env.REACT_APP_DEFAULT_API + 'log-out');

        let keys = Object.keys(cookies);

        keys.forEach((data) => {
          removeCookie(data, {
            path: '/',
          });
        });
        if (accounts && accounts.length > 0) {
          await instance.logoutRedirect({
            account: accounts[0],
            postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
          });
        }

        setIsLogout(true);
      } catch (e) {
        props.messageHandler(
          'Unexpected error, please call the administrator',
          'error',
          true
        );
      }
    };

    logOut();
  }, []);

  const render = isLogout ? <Redirect to='/login' /> : '';

  return <>{render}</>;
};

export default LogOut;
