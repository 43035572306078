import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import BorrowingData from './BorrowingData/BorrowingData';
import Loading from '../../../../component/UI/Loading/Loading';

import classes from './NewBorrowing.module.css';
import './Transition.css';

const NewBorrowing = (props) => {
  const user = props.userData;
  const [pendingBorrowing, setPendingBorrowing] = useState({
    status: null,
    data: null,
  });

  useEffect(() => {
    if (user && pendingBorrowing.status == null) {
      let fetchData = async () => {
        let data;
        try {
          data = await Axios.get(
            process.env.REACT_APP_DEFAULT_API + 'student/pending-borrowing',
            {
              params: {
                username: user.username,
              },
            }
          );

          data = data.data.payload;

          if (
            (Object.keys(data).length === 0 && data.constructor === Object) ||
            data === 'nodata'
          ) {
            setPendingBorrowing({
              status: false,
              data: null,
            });
          } else {
            setPendingBorrowing({
              status: true,
              data: data,
            });
          }
        } catch (error) {
          props.messageHandler(
            'Unexpected error, please contact the administrator',
            'error',
            true
          );
        }
      };
      fetchData();
    }
  }, [user, pendingBorrowing.status]);

  let renderPage = <Loading />;

  renderPage = (
    <BorrowingData
      history={props.history}
      user={props.userData}
      messageHandler={props.messageHandler}
    />
  );

  return <div className={classes.Container}>{renderPage}</div>;
};

export default NewBorrowing;
