import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import VerticalListMultiple from "./VerticalListMultiple/VerticalListMultiple";

import classes from "./VerticalListsMultiple.module.css";

const VerticalListsMultiple = (props) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (props.items) {
      setItems(
        Array.isArray(props.items)
          ? props.items
          : typeof props.items === "object"
          ? [...Object.values(props.items)]
          : [...props.items]
      );
    }
  }, [props]);

  return (
    <div className={classes.Container}>
      <div
        style={{
          overflowY: "scroll",
          height: props.height ? props.height : "25rem",
        }}
      >
        {items && items.length > 0
          ? items.map((item, index) => {
              let isActive = false;
              // console.log(item);
              if (props.selectedItems.length > 0) {
                props.selectedItems.forEach((selectedItem, selectedIndex) => {
                  if (selectedItem.id === item.id) isActive = true;
                });
              }

              return (
                <VerticalListMultiple
                  key={index}
                  addItemHandler={props.addItemHandler}
                  removeItemHandler={props.removeItemHandler}
                  item={item}
                  active={isActive}
                />
              );
            })
          : (
            <div style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "2rem",
            }}>
              <p style={{fontWeight: "bold", fontSize:"1.25rem"}}>No Shifts available...</p>
            </div>
          )}
      </div>
      <div className={classes.SelectedItems}>
        {props.selectedItems.length !== 0 && Array.isArray(props.selectedItems)
          ? props.selectedItems.map((item, index) => (
              <div className={classes.SelectedItem} key={index}>
                {item.name || item.room_code}
                <i
                  className={"material-icons " + classes.Icon}
                  onClick={() => props.removeItemHandler(item)}
                >
                  clear
                </i>
              </div>
            ))
          : ""}
      </div>
    </div>
  );
};

VerticalListMultiple.propTypes = {
  items: PropTypes.array,
  selectedItems: PropTypes.array,
  addItemHandler: PropTypes.func,
};

export default VerticalListsMultiple;
