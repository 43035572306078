import React, { useState } from 'react';

import SubMenu from '../../../../component/Navigation/Menu/SubMenu/SubMenu';
import StudentSettings from './StudentSettings/StudentSettings';
import Rooms from './Rooms/Rooms';
import RoomLocations from './RoomLocations/RoomLocations';
import RoomTypes from './RoomTypes/RoomTypes';
import RoomSettings from './RoomSettings/RoomSettings';

const StaffSettings = (props) => {
  const [selectedSubMenu, setSelectedSubMenu] = useState(0);

  const adminSubMenuList = [
    {
      name: 'Students',
      cssClasses: [],
      handler: () => setSelectedSubMenu(0),
    },
    {
      name: 'Room Locations',
      cssClasses: [],
      handler: () => setSelectedSubMenu(1),
    },
    {
      name: 'Room Types',
      cssClasses: [],
      handler: () => setSelectedSubMenu(2),
    },
    {
      name: 'Rooms',
      cssClasses: [],
      handler: () => setSelectedSubMenu(3),
    },
    {
      name: 'Room Settings',
      cssClasses: [],
      handler: () => setSelectedSubMenu(4),
    },
  ];
  const staffSubMenuList = [
    {
      name: 'Students',
      cssClasses: [],
      handler: () => setSelectedSubMenu(0),
    },
  ];

  const subMenuList =
    props.userData.role === 'Operations & System Development'
      ? adminSubMenuList
      : staffSubMenuList;

  const renderBody = () => {
    if (selectedSubMenu === 0) {
      return (
        <StudentSettings
          messageHandler={props.messageHandler}
          loadingHandler={props.loadingHandler}
        />
      );
    } else if (selectedSubMenu === 1) {
      return (
        <RoomLocations
          messageHandler={props.messageHandler}
          loadingHandler={props.loadingHandler}
          userData={props.userData}
        />
      );
    } else if (selectedSubMenu === 2) {
      return (
        <RoomTypes
          messageHandler={props.messageHandler}
          loadingHandler={props.loadingHandler}
          userData={props.userData}
        />
      );
    } else if (selectedSubMenu === 3) {
      return (
        <Rooms
          messageHandler={props.messageHandler}
          loadingHandler={props.loadingHandler}
          userData={props.userData}
        />
      );
    } else if (selectedSubMenu === 4) {
      return (
        <RoomSettings
          messageHandler={props.messageHandler}
          loadingHandler={props.loadingHandler}
          userData={props.userData}
        />
      );
    }
  };

  return (
    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      <SubMenu subMenuList={subMenuList} selectedSubMenu={selectedSubMenu} />
      <>{renderBody()}</>
    </div>
  );
};

export default StaffSettings;
