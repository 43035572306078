import React, { useState } from "react";

import "../../themes/bm5/css/login.css";
import classes from "./Login.module.css";
import LogoImg from "../../images/logo.png";
import { CustomParticle } from "../../component/UI/Particle/CustomParticle";
import Loading from "../../component/UI/Loading/Loading";
import MessierForm from "./Messier/MessierForm";
import LoginMicrosoft from "./Microsoft/LoginMicrosoft";
import { withCookies } from "react-cookie";
import Message from "../../component/UI/Message/Message";

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    message: "",
    status: false,
    type: ""
  })  
  const { cookies } = props;

  const messageHandler = (msg, type, state) => {
    if (
      state !== message.status ||
      msg !== message.message
    ) {
      setMessage({
        message: msg,
        status: state,
        type: type,
      })
    }
  };

  return (
    <div className={classes.Login}>
      <CustomParticle />
      {loading && <Loading />}
      <div className={classes.LoginForm + " login"}>
        <div className="login-head">
          <div className="ribbon"></div>
          <div className="logo">
            <span className="site-name">
              <img id="imgLogo" src={LogoImg} alt="Logo" />
            </span>
            <span className="site-description">
              People Innovation Excellence
            </span>
          </div>
        </div>
        <div className={classes.LoginBody}>
          <LoginMicrosoft loading={loading} setLoading={setLoading} messageHandler={messageHandler} {...props}/>
          <MessierForm loading={loading} setLoading={setLoading} messageHandler={messageHandler} {...props}/>
        </div>
      </div>
      <Message
        login={true}
        message={message.message}
        type={message.type}
        show={message.status}
        timeout={2000}
        messageHandler={messageHandler}
      />
      <footer className={classes.Footer}>
        <span>
          Copyright &copy; 2022 - Operations and System Development, LCAS -
          Binus University
        </span>
      </footer>
    </div>
  );
};

export default withCookies(Login);
