import React from 'react';

import classes from './VerticalListMultiple.module.css';

const VerticalListMultiple = (props) => {
  let iconClasses = ['material-icons', classes.Icon];

  if (props.active) {
    iconClasses.push(classes.Active);
  }

  const addButtonHandler = () => {
    if (!props.active) {
      props.addItemHandler(props.item);
    } else {
      props.removeItemHandler(props.item);
    }
  };
  return (
    <div className={classes.Container} onClick={addButtonHandler}>
      {props.item.name || props.item.room_code}
      <i className={iconClasses.join(' ')}>{props.active ? 'check' : 'add'}</i>
    </div>
  );
};

export default VerticalListMultiple;
