import React, { useState, useEffect, useRef } from "react";

import List from "../../../../../../component/UI/List/VerticalListsMultipleAssetWithImage/VerticalListsMultipleAssetWithImage";

import classes from "../../NewAssetBorrowing.module.css";

const AssetBorrowingData = (props) => {
  // eslint-disable-next-line
  const [assets, setAssets] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const { availableAssets } = props;

  useEffect(() => {
    // if (props.availableAssets.length === 0) props.changeStateHandler(0);
    // else setAssets(props.availableAssets);

    setAssets(availableAssets);
  }, [availableAssets]);

  const backButtonHandler = () => {
    props.changeStateHandler(3);
  };

  const nextButtonHandler = () => {
    if (props.selectedAssets.length !== 0) props.changeStateHandler(5);
  };

  let nextButtonClasses = [classes.NextButton];
  let nextButtonText = "Next";
  if (props.selectedAssets.length === 0) {
    nextButtonClasses.push(classes.ButtonDisabled);
  }

  if (
    props.selectedAssets != null &&
    Array.isArray(props.selectedAssets) &&
    props.selectedAssets.length > 0
  ) {
    nextButtonText = "Next";
  }

  const searchInputChangeHandler = (event) => {
    setSearchInput(event.target.value);
  };

  useEffect(() => {

    const delaySearch = setTimeout(() => {
        if (!searchInput) {
          setAssets(availableAssets);
          return;
        }
        const filteredAssets = availableAssets.filter(
          (asset) =>
          asset.name.toLowerCase().includes(searchInput.toLowerCase()) ||
          asset.description.toLowerCase().includes(searchInput.toLowerCase())
          );
        setAssets(filteredAssets);
      }, 1000);

      return () => clearTimeout(delaySearch);
  }, [searchInput]);

  return (
    <div className={classes.BorrowingDataContainer}>
      <div className={classes.Title} style={{ lineHeight: "20pt" }}>
        If you need some equipment to help with your project!
      </div>
      <div className={classes.SubTitle__Container}>
        <div className={classes.SubTitle}>Equipment</div>
        <p className={classes.Description}>Choose assets that you need!</p>
      </div>

      <div>
        <input
          type="text"
          value={searchInput}
          placeholder="Search by the name or description of the item"
          onChange={searchInputChangeHandler}
        ></input>
      </div>

      <h4>{`Quantity: ${props.selectedAssets.length}`}</h4>

      <List
        assets={assets}
        selectedAssets={props.selectedAssets}
        addAssetHandler={props.addAssetHandler}
        removeAssetHandler={props.removeAssetHandler}
      />

      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className="material-icons" style={{ marginRight: "8px" }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(" ")}
          onClick={nextButtonHandler}
        >
          {nextButtonText}
          <i className="material-icons" style={{ marginLeft: "8px" }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default AssetBorrowingData;
