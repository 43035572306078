import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import SideDrawer from '../../component/Navigation/SideDrawer/SideDrawer';
import Toolbar from '../../component/Navigation/Toolbar/Toolbar';
import BottomToolBar from '../../component/Navigation/BottomToolBar/BottomToolBar';
import StaffHome from './Staff/StaffHome';
import StudentHome from './Student/StudentHome';
import Message from '../../component/UI/Message/Message';

import classes from './Home.module.css';

const HomeContainer = (props) => {
  const user = props.userData;
  const [showMessage, setShowMessage] = useState({
    state: false,
    message: '',
    type: '',
  });

  const [sideDrawer, setSideDrawer] = useState(false);

  const sideDrawerHandler = (status) => {
    setSideDrawer(status);
  };

  const messageHandler = (message, type, state) => {
    if (state !== showMessage.state || message !== showMessage.message) {
      let newState = { ...showMessage };

      if (message !== '') {
        newState.message = message;
      }
      newState.state = state;
      newState.type = type;

      setShowMessage(newState);
    }
  };
  const home =
    user != null && user.cid != null ? (
      <StaffHome userData={user} messageHandler={messageHandler} />
    ) : user != null && user.cid == null ? (
      <StudentHome userData={user} messageHandler={messageHandler} />
    ) : (
      <Redirect to='/' />
    );
    
  useEffect(() => {
    if (user) {
      console.log(user);
    }
  }, [user])
  
  const menu = {
    admin: [
      {
        link: '/staff/room-borrowing',
        logo: 'room',
        name: 'Room',
      },
      {
        link: '/staff/asset-borrowing',
        logo: 'camera',
        name: 'Asset',
      },
      {
        link: '/staff/reporting',
        logo: 'article',
        name: 'Reporting',
      },
      {
        link: '/staff/settings',
        logo: 'settings',
        name: 'Settings',
      },
      {
        link: '/staff/feedback',
        logo: 'feedback',
        name: 'Feedback',
      },
      {
        link: '/log-out',
        logo: 'exit_to_app',
        name: 'Log Out',
      },
    ],
    staff: [
      {
        link: '/staff/room-borrowing',
        logo: 'email',
        name: 'Room Borrowing',
      },
      {
        link: '/staff/asset-borrowing',
        logo: 'camera',
        name: 'Asset Borrowing',
      },
      {
        link: '/staff/reporting',
        logo: 'article',
        name: 'Reporting',
      },
      {
        link: '/staff/settings',
        logo: 'settings',
        name: 'Settings',
      },
      {
        link: '/staff/submit-feedback',
        logo: 'feedback',
        name: 'Feedback',
      },
      {
        link: '/log-out',
        logo: 'exit_to_app',
        name: 'Log Out',
      },
    ],
    student: [
      {
        link: '/student/room-borrowing',
        logo: 'room',
        name: 'Room',
      },
      {
        link: '/student/asset-borrowing',
        logo: 'camera',
        name: 'Asset',
      },
      {
        link: '/student/borrowing-history',
        logo: 'history',
        name: 'Room History',
      },
      {
        link: '/student/asset-borrowing-history',
        logo: 'access_time',
        name: 'Asset History',
      },
      {
        link: '/student/submit-feedback',
        logo: 'feedback',
        name: 'Feedback',
      },
      {
        link: '/log-out',
        logo: 'exit_to_app',
        name: 'Log Out',
      },
    ],
  };

  let selectedMenu =
    user != null && user.cid != null
      ? user.role === 'Operations & System Development'
        ? menu.admin
        : menu.staff
      : menu.student;

  return (
    <div className={classes.Home}>
      <Toolbar sideDrawerHandler={sideDrawerHandler} menu={selectedMenu} />
      <SideDrawer
        user={user}
        menu={selectedMenu}
        active={sideDrawer}
        sideDrawerHandler={sideDrawerHandler}
      />
      <div className={classes.Content}>{home}</div>
      <Message
        message={showMessage.message}
        type={showMessage.type}
        show={showMessage.state}
        timeout={2500}
        messageHandler={messageHandler}
      />
      <BottomToolBar menu={selectedMenu} />
    </div>
  );
};

export default HomeContainer;
