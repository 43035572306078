import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import {
  TabularHeader,
  TabularBody,
} from '../../../../component/UI/Tabular/Tabular';
import Loading from '../../../../component/UI/Loading/Loading';

import classes from '../NewBorrowing/NewBorrowing.module.css';
import AssetBorrowingList from '../../../../component/UI/List/AssetBorrowingList/AssetBorrowingList';
import AssetBorrowingDetail from '../AssetBorrowingDetail/AssetBorrowingDetail';

const AssetBorrowingHistory = (props) => {
  const [tabState, setTabState] = useState(0);
  const [isDetailShow, setDetailShow] = useState({
    show: false,
    data: null,
  });

  const [borrowingData, setBorrowingData] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const [serverTime, setServerTime] = useState(null);
  const [currentStudent, setCurrentStudent] = useState({});
  const [currentStudentData, setCurrentStudentData] = useState({});
  const [currentAssets, setCurrentAssets] = useState({});

  const fetchData = async () => {
    try {
      setIsLoad(true);
      let data = null;
      let range = '';

      if (tabState === 0) {
        range = 'week';
      } else if (tabState === 1) {
        range = 'month';
      }

      data = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + 'student/asset-history/all',
        {
          params: {
            binusian_id: props.userData.username,
            range: range,
          },
        }
      );

      if (data != null) {
        if (data.data.payload.status) {
          setBorrowingData(data.data.payload.data);
        }
      } else {
        setBorrowingData([]);
      }
    } catch (error) {
      props.messageHandler(
        'Unexpected error please try again later',
        'error',
        true
      );
    }
    setIsLoad(false);
  };

  const onClickDataHandler = (data) => {
    setDetailShow({
      show: true,
      data: data,
    });
  };
  const backButtonHandler = () => {
    setDetailShow({
      show: false,
      data: null,
    });
  };

  useEffect(() => {
    fetchData();
  }, [tabState]);

  useEffect(() => {
    if (isDetailShow.data) {
      setCurrentStudent({
        name: isDetailShow.data.student_name,
        email: isDetailShow.data.student_email,
        username: isDetailShow.data.student_id,
      });

      setCurrentStudentData({
        phone: isDetailShow.data.student_phone_number,
        purpose: isDetailShow.data.comments,
      });

      // Ambil Data Assets
      let assets = [];

      isDetailShow.data.check_in.details.forEach((item) => {
        assets.push(item.asset);
      });

      setCurrentAssets(assets);
    }
  }, [isDetailShow]);

  const list =
    Array.isArray(borrowingData) && borrowingData.length > 0 ? (
      borrowingData.map((item, index) => {
        return (
          <AssetBorrowingList
            onClickHandler={onClickDataHandler}
            key={index}
            data={item}
          />
        );
      })
    ) : (
      <div
        style={{
          fontStyle: 'italic',
          fontSize: '12pt',
          padding: '14px 16px',
        }}
      >
        No data available
      </div>
    );

  return (
    <div className={classes.Container}>
      {isLoad ? <Loading /> : ''}
      {isDetailShow.show ? (
        // <AssetBorrowingDetail
        //   detailBorrowing={true}
        //   borrower={true}
        //   backButtonHandler={backButtonHandler}
        //   history={props.history}
        //   messageHandler={props.messageHandler}
        //   id={isDetailShow.data.id}
        //   status={isDetailShow.data.borrowing_status}
        //   date={isDetailShow.data.date}
        //   user={props.userData}
        //   userData={isDetailShow.data.userData}
        //   room={isDetailShow.data.room}
        //   loadingHandler={setIsLoad}
        //   laboratoryType={isDetailShow.data.room.type}
        //   shifts={isDetailShow.data.detail_shifts}
        //   assets={isDetailShow.data.assets}
        //   teammateData={isDetailShow.data.teammateData}
        // />

        <AssetBorrowingDetail
            detailBorrowing={true}
            pic={false}
            borrower={true}
            backButtonHandler={backButtonHandler}
            loadingHandler={setIsLoad}
            // history={props.history}
            messageHandler={props.messageHandler}
            id={isDetailShow.data.id}
            status={isDetailShow.data.status}
            date={isDetailShow.data.check_out_date}
            serverTime={serverTime}
            user={currentStudent}
            userData={currentStudentData}
            room={isDetailShow.data.check_in.details[0].asset.room}
            // laboratoryType={isDetailShow.data.room.type}
            assets={currentAssets}
            checkInID={isDetailShow.data.check_in.id}
            checkOutID={isDetailShow.data.check_out.id}
            checkInDate={isDetailShow.data.check_in_date}
            checkOutDate={isDetailShow.data.check_out_date}
            checkInShift={isDetailShow.data.check_in_shift}
            checkOutShift={isDetailShow.data.check_out_shift}
            checkInStatus={isDetailShow.data.check_in.realization}
            checkOutStatus={isDetailShow.data.check_out.realization}
            // shifts={isDetailShow.data.detail_shifts}
            // teammateData={isDetailShow.data.teammateData}
            finalizeData={{
              status: isDetailShow.data.attendance_status,
              description: isDetailShow.data.finalize_note,
            }}
          />
      ) : (
        <>
          <div className={classes.Title}>Asset Borrowing History</div>
          <span
            style={{
              fontStyle: 'italic',
              fontSize: '10pt',
              marginTop: '15px',
            }}
          >
            *) click the list to see the detail
          </span>
          <TabularHeader
            menus={['This Week', 'This Month', 'All']}
            active={tabState}
            onTabClickHandler={setTabState}
          />
          <TabularBody>{list}</TabularBody>
        </>
      )}
    </div>
  );
};

export default AssetBorrowingHistory;
