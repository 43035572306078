import React from 'react';

import Logo from '../../../images/logo_lcas.png';

import classes from './BinusLogo.module.css';

const BinusLogo = () => {
    return (
        <div className={classes.BinusLogo__Container}>
            <img alt="Binus Logo" src={Logo} className={classes.BinusLogo}/>
        </div>
    );
}

export default BinusLogo;