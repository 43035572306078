import React, { useEffect, useState } from "react";
import Moment from "moment";
import Axios from "axios";
import ReactImageZoom from "react-image-zoom";
import notFound from "../../../../images/image-not-found.jpg";
import classes from "../NewBorrowing/NewBorrowing.module.css";
import classes2 from "./BorrowingDetail.module.css";

const BorrowingDetail = (props) => {
  const getAssetImage = (asset) => {
    if (!asset || !asset.images[0]) return notFound;
    return asset.images[0].path ? asset.images[0].path : notFound;
  };

  const prepareAssetCalibrations = (assets) => {
    let temp = [];
    if (assets && assets.length > 0) {
      for (let i = 0; i < assets.length; i++) {
        temp.push(
          assets[i].calibration === null ? 1 : assets[i].calibration.status
        );
      }
    }
    return temp;
  };

  const pad = (num, size) => {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  };

  const [roomTransactions, setRoomTransactions] = useState(null);
  const [assetCalibrations, setAssetCalibrations] = useState(
    prepareAssetCalibrations(props.assets)
  );
  const [groupSettings, setGroupSettings] = useState(null);

  const [currentCancellationNote, setCurrentCancellationNote] = useState(null);

  const toggleAssetCalibrations = async (index, e) => {
    let temp = [...assetCalibrations];
    temp[index] = assetCalibrations[index] === 1 ? 0 : 1;
    setAssetCalibrations(temp);
  };

  useEffect(() => {
    const fetchData = async () => {
      props.loadingHandler(true);

      try {
        let data = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + "staff/room-transaction",
          {
            params: {
              start_date: props.date,
              end_date: props.date,
              room_id: props.room.id,
              room_name: props.room.room_code,
            },
          }
        );

        setRoomTransactions(data.data.payload.data);
        setGroupSettings(data.data.payload.group_settings);
      } catch (e) {
        props.messageHandler(
          "Failed to load room transaction data or No transaction data",
          "error",
          true
        );
        setRoomTransactions([]);
      }
      props.loadingHandler(false);
    };

    if (props.status === "Pending" && roomTransactions == null && props.pic) {
      fetchData();
    }
  }, [props.pic, roomTransactions]);

  let shifts = "";

  if (props.shifts != null && props.shifts.length > 0) {
    shifts = props.shifts.map((item, index) => {
      return <div key={index}>{item.name}</div>;
    });
  }

  let room_code = "";
  if (
    props.rooms != null &&
    Array.isArray(props.rooms) &&
    props.rooms.length > 0
  ) {
    room_code = props.rooms.map((item, index) => {
      return <div key={index}>{item.room_code}</div>;
    });
  } else {
    room_code = <div>{props.room.room_code}</div>;
  }

  const teammate = () => {
    let render = "";
    if (props.teammateData != null && props.teammateData.length > 0) {
      const dynamicRender = props.teammateData.map((item, index) => {
        return (
          <div key={index}>
            {item.username ? item.username : item.binusian_id}
            {" - " + item.name}
          </div>
        );
      });
      render = (
        <div className={classes2.SubTitle__Container}>
          <div className={classes.SubTitle}>
            Teammate
            {props.newBorrowing ? (
              <span
                style={{
                  color: "#0098D7",
                  cursor: "pointer",
                  fontSize: "11pt",
                  marginLeft: "10pt",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  props.changeStateHandler(6);
                }}
              >
                Edit
              </span>
            ) : (
              ""
            )}
          </div>
          <div className={classes.SubSubTitle__Container}>{dynamicRender}</div>
        </div>
      );
    }

    return render;
  };

  const status = () => {
    if (props.pendingBorrowing || props.detailBorrowing) {
      let style = { color: "#d3d3d3" };

      if (props.status === "Rejected") {
        style.color = "#a94442";
      } else if (props.status === "Canceled") {
        style.color = "#8a6d3b";
      } else if (props.status === "Accepted") {
        style.color = "#3c763d";
      } else if (props.status === "Finalized") {
        style.color = "black";
      }

      return (
        <>
          {" "}
          - <span style={style}>{props.status}</span>
        </>
      );
    }

    return "";
  };

  const backButton = () => {
    if (props.detailBorrowing) {
      return (
        <span
          style={{
            color: "#0098D7",
            textDecoration: "underline",
            fontWeight: "bold",
            cursor: "pointer",
          }}
          onClick={() => props.backButtonHandler()}
        >
          Back
        </span>
      );
    }
  };

  const [cancelOptions, setCancelOptions] = useState([]);
  useEffect(() => {
    const fetchOption = async () => {
      props.loadingHandler(true);
      try {
        let option = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + "reason",
          {
            params: {
              reason_type: "Canceled",
            },
          }
        );

        setCancelOptions(option.data);
      } catch (e) {
        props.messageHandler("Failed to load data", "error", true);
      }

      props.loadingHandler(false);
    };
    if (props.borrower === true && props.status === "Pending") {
      fetchOption();
    }
  }, [props.borrower, props.status]);

  const CancelButton = () => {
    const [selection, setSelection] = useState("Change schedule");
    const cancelButtonHandler = async () => {
      props.loadingHandler(true);

      //validate selection
      if (
        selection === "" ||
        (selection !== "Change schedule" &&
          selection !== "No longer interested" &&
          selection !== "No Response until the day of borrowing")
      ) {
        props.messageHandler(
          "Please select the valid cancellation reason",
          "error",
          true
        );
        return;
      }

      if (!currentCancellationNote || currentCancellationNote.length === 0) {
        props.messageHandler(
          "Please fill the cancellation additional reason",
          "error",
          true
        );

        props.loadingHandler(false);

        return;
      }

      // Concat status dan status reason
      let currReason = `${selection} (${currentCancellationNote})`;

      try {
        let result = await Axios.patch(
          process.env.REACT_APP_DEFAULT_API + "student/cancel-borrowing",
          {
            id: props.id,
            reason: currReason,
          }
        );

        if (result.data.payload.status) {
          props.messageHandler(result.data.payload.message, "success", true);
        } else {
          props.messageHandler(result.data.payload.message, "error", true);
        }

        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } catch (e) {
        props.messageHandler(
          "Unexpected error please call the administrator",
          "error",
          true
        );
      }
      props.loadingHandler(false);
    };

    const selectOptionHandler = (evt) => {
      setSelection(evt.target.value);
    };

    const optionRender =
      cancelOptions.length > 0
        ? cancelOptions.map((option, i) => {
            return (
              <option value={option.reason_description} key={i}>
                {option.reason_description}
              </option>
            );
          })
        : "";

    return (
      <div className={classes2.SubTitle__Container}>
        <div className={classes.SubSubTitle}>Reason for cancellation</div>

        <select
          className={classes.InputText}
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={selection}
          onChange={selectOptionHandler}
        >
          {optionRender}
        </select>

        <div className={classes.SubSubSubTitle}>Additional Reason</div>
        <textarea
          className={classes.InputText}
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={currentCancellationNote}
          onChange={(e) => setCurrentCancellationNote(e.target.value)}
        ></textarea>

        <span
          style={{
            display: "inline-block",
            padding: "14px 16px",
            backgroundColor: "#fcf8e3",
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#8a6d3b",
            borderColor: "#faebcc",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
          }}
          onClick={cancelButtonHandler}
        >
          Cancel Borrowing Request
        </span>
      </div>
    );
  };

  return (
    <div className={classes.BorrowingDataContainer}>
      {backButton()}
      <div className={classes.Title} style={{ lineHeight: "20pt" }}>
        Borrowing Detail
      </div>

      <div className={classes.SubTitle__Container} style={{ border: "none" }}>
        <div className={classes.SubTitle}>
          {Moment(props.date, "YYYY-MM-DD").format("ddd, DD MMMM YYYY")}
          {status()}
          <div className={classes.SubSubTitle}>
            {props.id ? `LCASR${pad(props.id, 10)}` : ""}
          </div>
        </div>
      </div>
      <div className={classes2.DataContainer}>
        <div className={classes2.SubTitle__Container}>
          <div className={classes.SubTitle}>
            Leader
            {props.newBorrowing ? (
              <span
                style={{
                  color: "#0098D7",
                  cursor: "pointer",
                  fontSize: "11pt",
                  marginLeft: "10pt",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  props.changeStateHandler(5);
                }}
              >
                Edit
              </span>
            ) : (
              ""
            )}
          </div>
          <div className={classes.SubSubTitle__Container}>
            <div className={classes.SubSubTitle}>Name</div>
            <p className={classes.SubDescription}>
              {props.user ? props.user.name : props.userData.name}
            </p>
            <div className={classes.SubSubTitle}>Email</div>
            <p className={classes.SubDescription}>
              {props.user && props.user.email
                ? props.user.email
                : props.userData.email}
            </p>
            <div className={classes.SubSubTitle}>Phone Number</div>
            <p className={classes.SubDescription}>{props.userData.phone}</p>
            <div className={classes.SubSubTitle}>Purpose</div>
            <p className={classes.SubDescription}>{props.userData.purpose}</p>
            {props.laboratoryType.name.includes("Food Tech") ? (
              <>
                <div className={classes.SubSubTitle}>Laboratory Materials</div>
                <p className={classes.SubDescription}>
                  {/* 
                    TEMP FIX
                    TODO: Tambahin lab materials di DB
                  */}
                  {props.userData.purpose.includes("Lab Materials : Yes") ||
                  props.userData.chemicals === "Yes"
                    ? "Yes"
                    : "No"}
                </p>
              </>
            ) : null}
            {props.laboratoryType.name.includes("Computer") ||
            props.laboratoryType.name.includes("Software") ? (
              <>
                <div className={classes.SubSubTitle}>
                  Estimated Borrower Count
                </div>
                <p className={classes.SubDescription}>
                  {props.userData.borrower_count
                    ? props.userData.borrower_count
                    : 1}
                </p>
                <div className={classes.SubSubTitle}>Internet</div>
                <p className={classes.SubDescription}>
                  {props.userData.need_internet === "1" ? "Yes" : "No"}
                </p>
              </>
            ) : null}
          </div>
        </div>
        <div className={classes2.SubTitle__Container}>
          <div className={classes.SubTitle}>
            Room Detail
            {props.newBorrowing ? (
              <span
                style={{
                  color: "#0098D7",
                  cursor: "pointer",
                  fontSize: "11pt",
                  marginLeft: "10pt",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  props.changeStateHandler(0);
                }}
              >
                Edit
              </span>
            ) : (
              ""
            )}
          </div>
          <div className={classes.SubSubTitle__Container}>
            <div className={classes.SubSubTitle}>Room Type</div>
            <p className={classes.SubDescription}>
              {props.laboratoryType.name}
            </p>
            <div className={classes.SubSubTitle}>Room Code</div>
            <div className={classes.SubDescription}>{room_code}</div>
            <div className={classes.SubSubTitle}>Shift(s)</div>
            <div className={classes.SubDescription}>{shifts}</div>
          </div>
          {!props.newBorrowing ? (
            <>
              <div className={classes.SubTitle}>Borrowing Notes</div>
              <div className={classes.SubSubTitle__Container}>
                <p className={classes.SubDescription}>
                  {props.reason && props.reason !== "" ? props.reason : "None"}
                  {/* {props.reason} */}
                </p>
              </div>
            </>
          ) : null}
        </div>
        {teammate()}
        <div className={classes2.SubTitle__Container}>
          <div className={classes.SubTitle}>
            Assets Detail
            {props.newBorrowing ? (
              <span
                style={{
                  color: "#0098D7",
                  cursor: "pointer",
                  fontSize: "11pt",
                  marginLeft: "10pt",
                  textDecoration: "underline",
                }}
                onClick={() => {
                  props.changeStateHandler(4);
                }}
              >
                Edit
              </span>
            ) : (
              ""
            )}
          </div>
          {false && (
            <div className={classes.SubSubTitle__Container}>
              {props.assets.map((asset) => {
                const imageProps = {
                  width: 80,
                  height: 80,
                  zoomPosition: "original",
                  img: getAssetImage(asset),
                };

                return (
                  <div className={classes2.FlexContainer}>
                    <ReactImageZoom {...imageProps} />
                    <div className={classes.SubSubTitle__Container}>
                      <small>{room_code}</small>
                      <div className={classes.SubSubTitle}>
                        {`LCAS${pad(asset.id, 5)}`}
                      </div>
                      <div className={classes.SubDescription}>{asset.name}</div>
                    </div>
                    {props.status === "Accepted" && (
                      <input type="checkbox"></input>
                    )}
                  </div>
                );
              })}
            </div>
          )}

          {props.assets && props.assets.length !== 0 ? (
            <table className={classes2.AssetTable}>
              <tr>
                <th className={classes2.AssetTable}>Preview</th>
                <th className={classes2.AssetTable}>Detail</th>
                <th
                  className={classes2.AssetTable}
                  colSpan={2}
                  style={{ padding: "2px" }}
                >
                  Useable
                </th>
              </tr>
              {props.assets.map((asset, index) => {
                const imageProps = {
                  width: 80,
                  height: 80,
                  zoomPosition: "original",
                  img: getAssetImage(asset),
                };

                return (
                  <tr key={index}>
                    <td className={classes2.AssetTable}>
                      <ReactImageZoom {...imageProps} />
                    </td>
                    <td
                      className={classes2.AssetTable}
                      style={{
                        color: "#5e5e5e",
                        padding: "5px",
                      }}
                    >
                      <small>{room_code}</small>
                      <div>{`LCAS${pad(asset.id, 5)}`}</div>
                      <div>{asset.name}</div>
                    </td>
                    {props.status === "Accepted" && (
                      <td
                        className={classes2.AssetTable}
                        style={{ color: "#5e5e5e" }}
                      >
                        <input
                          type="checkbox"
                          checked={assetCalibrations[index] === 1}
                          onChange={(e) => toggleAssetCalibrations(index, e)}
                        ></input>
                      </td>
                    )}
                    {props.status !== "Accepted" && (
                      <td
                        className={classes2.AssetTable}
                        style={{ color: "#5e5e5e" }}
                      >
                        <i
                          style={
                            assetCalibrations[index] === 1
                              ? { color: "green" }
                              : { color: "red" }
                          }
                          className="material-icons"
                        >
                          {assetCalibrations[index] === 1
                            ? "check_circle"
                            : "cancel"}
                        </i>
                      </td>
                    )}
                    {props.status === "Accepted" && (
                      <td
                        className={classes2.AssetTable}
                        style={{ color: "#5e5e5e" }}
                      >
                        <div title="Current asset usability">
                          <i
                            className="material-icons"
                            title="Calibrate current asset usability"
                            style={{
                              color: "#0098D7",
                              verticalAlign: "middle",
                            }}
                          >
                            info
                          </i>
                        </div>
                      </td>
                    )}
                    {props.status !== "Accepted" && (
                      <td
                        className={classes2.AssetTable}
                        style={{ color: "#5e5e5e" }}
                      >
                        <div title="Current asset usability">
                          <i
                            className="material-icons"
                            title="Current asset usability"
                            style={{ color: "#0098D7" }}
                          >
                            info
                          </i>
                        </div>
                      </td>
                    )}
                  </tr>
                );
              })}
            </table>
          ) : (
            <p className={classes.SubDescription}>
              No assets borrowed in this transaction
            </p>
          )}
        </div>

        {props.borrower === true && props.status === "Pending" ? (
          <CancelButton/>
        ) : null};
        {props.pic &&
          (props.status === "Pending" ? (
            <PendingPicButton
              pic={props.pic}
              status={props.status}
              serverTime={props.serverTime}
              id={props.id}
              date={props.date}
              leaderId={
                props.user
                  ? props.user.username
                  : props.userData.student_nim_leader
              }
              roomTransactions={roomTransactions}
              roomGroupSettings={groupSettings}
              assetCalibrations={assetCalibrations}
              loadingHandler={props.loadingHandler}
              acceptButtonHandler={props.acceptButtonHandler}
              rejectButtonHandler={props.rejectButtonHandler}
              finalizeButtonHandler={props.finalizeButtonHandler}
            />
          ) : props.status === "Accepted" ? (
            <AcceptedPicButton
              pic={props.pic}
              status={props.status}
              serverTime={props.serverTime}
              id={props.id}
              date={props.date}
              leaderId={
                props.user
                  ? props.user.username
                  : props.userData.student_nim_leader
              }
              roomTransactions={roomTransactions}
              roomGroupSettings={groupSettings}
              assetCalibrations={assetCalibrations}
              loadingHandler={props.loadingHandler}
              acceptButtonHandler={props.acceptButtonHandler}
              rejectButtonHandler={props.rejectButtonHandler}
              finalizeButtonHandler={props.finalizeButtonHandler}
            />
          ) : null)}
        {props.status === "Finalized" && props.pic ? (
          <FinalizeData finalizeData={props.finalizeData} />
        ) : (
          ""
        )}
      </div>
      {props.newBorrowing ? <BackNextButton {...props} /> : ""}
    </div>
  );
};

const BackNextButton = (props) => {
  const backButtonHandler = () => {
    props.changeStateHandler(1);
  };

  const nextButtonHandler = () => {
    props.submitFormHandler();
  };

  return (
    <div className={classes.ButtonContainer__SpaceBetween}>
      <div className={classes.BackButton} onClick={backButtonHandler}>
        <i className="material-icons" style={{ marginRight: "8px" }}>
          arrow_back
        </i>
        Back
      </div>
      <div className={classes.NextButton} onClick={nextButtonHandler}>
        Submit
        <i className="material-icons" style={{ marginLeft: "8px" }}>
          arrow_forward
        </i>
      </div>
    </div>
  );
};

const AcceptedPicButton = (props) => {
  const [selectStatus, setSelectStatus] = useState("present");
  const [description, setDescription] = useState("");

  return (
    <div className={classes2.SubTitle__Container}>
      <div className={classes.SubTitle}>Action</div>
      <div className={classes.SubSubTitle__Container}>
        <select
          value={selectStatus}
          className={classes.InputText}
          onChange={(evt) => {
            setSelectStatus(evt.target.value);
          }}
        >
          <option value="present">Present</option>
          <option value="absent">Absent</option>
          <option value="canceled">Canceled</option>
        </select>
        <div
          className={`${classes.SubSubTitle}`}
          style={{
            marginTop: "1.5rem",
          }}
        >
          Description
        </div>
        <textarea
          className={classes.InputText}
          onChange={(evt) => {
            setDescription(evt.target.value);
          }}
        ></textarea>
      </div>
      <div className={classes.SubSubTitle__Container}>
        <span
          style={{
            display: "inline-block",
            padding: "14px 16px",
            backgroundColor: "#dff0d8",
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#3c763d",
            borderColor: "#d6e9c6",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
          }}
          onClick={() =>
            props.finalizeButtonHandler(
              props.id,
              description,
              selectStatus,
              props.leaderId,
              props.assetCalibrations
            )
          }
        >
          Finalize
        </span>
      </div>
    </div>
  );
};



const PendingPicButton = (props) => {
  const [rejectOptions, setRejectOptions] = useState([]);
  useEffect(() => {
    const fetchOption = async () => {
      props.loadingHandler(true);
      try {
        let option = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + "reason",
          {
            params: {
              reason_type: "Rejected",
            },
          }
        );
  
        setRejectOptions(option.data);
      } catch (e) {
        props.messageHandler("Failed to load data", "error", true);
      }
      props.loadingHandler(false);
    };
    if (props.pic && props.status === "Pending") {
      fetchOption();
    }
  }, [props.pic, props.status]);
  
  const [selection, setSelection] = useState("Day off");

  const [symbolRender, setSymbolRender] = useState([]);

  const [additionalReason, setAdditionalReason] = useState(null);



  const selectOptionHandler = (evt) => {
    setSelection(evt.target.value);
  };

  const submitRejectHandler = () => {
    let reason = selection;

    if (additionalReason) {
      reason += " (" + additionalReason + ")";
    } else {
      reason = "ISI DONG REASONNYA";
    }

    props.rejectButtonHandler(props.id, reason);
  };

  const optionRender =
    rejectOptions.length > 0
      ? rejectOptions.map((option, i) => {
          return (
            <option value={option.reason_description} key={i}>
              {option.reason_description}
            </option>
          );
        })
      : "";

  let transactions = () => {
    let render = [];
    if (props.roomTransactions != null) {
      //respons dr room transaction mulai dari shift 0-7
      //jadi mulai nya pakai dari shift 1-6
      for (let i = 1; i <= 6; i++) {
        let item = (
          <td key={i} style={{ userSelect: "none" }}>
            X
          </td>
        );

        // Cek jika belum ada transaksi (untuk room tanpa group settings) dan transaksi belum melebihi max grup (untuk room dengan group settings)
        let currentCapacity = !props.roomTransactions[i]
          ? 0
          : !props.roomTransactions[i].RoomTransactionStatus
          ? 0
          : Array.isArray(props.roomTransactions[i].RoomTransactionStatus)
          ? props.roomTransactions[i].RoomTransactionStatus.length
          : 1;

        if (
          props.roomTransactions[i] &&
          ((Object.keys(props.roomTransactions[i]).length === 0 &&
            props.roomTransactions[i].constructor === Object) ||
            (props.roomGroupSettings !== null &&
              currentCapacity <
                props.roomGroupSettings.maximum_group_per_shift))
        ) {
          item = (
            <td key={i} style={{ color: "green", userSelect: "none" }}>
              V
            </td>
          );
        }

        if (props.roomTransactions.length === 0) {
          item = (
            <td key={i} style={{ color: "green", userSelect: "none" }}>
              V
            </td>
          );
        }
        render.push(item);
      }
    }
    return render;
  };

  useEffect(() => {
    let render = transactions();
    setSymbolRender(render);
  }, [props.roomGroupSettings, props.roomTransactions]);

  //acc / reject
  return (
    <div className={classes2.SubTitle__Container}>
      <div className={classes.SubTitle}>Action</div>
      <div className={classes.SubSubTitle__Container}>
        <div className={classes.SubTitle}>Room Transaction</div>
        <table>
          <thead>
            <tr>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
            </tr>
          </thead>
          <tbody>
            <tr>{symbolRender}</tr>
          </tbody>
        </table>
        <span
          style={{
            display: "inline-block",
            padding: "14px 16px",
            backgroundColor: "#dff0d8",
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#3c763d",
            borderColor: "#d6e9c6",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
            marginBottom: "10px",
          }}
          onClick={() => {
            const reason = additionalReason ? additionalReason : "";
            props.acceptButtonHandler(props.id, reason);
          }}
        >
          Accept
        </span>

        <div className={classes.SubSubTitle}>Reason for Rejection</div>

        <select
          className={classes.InputText}
          style={{ marginTop: "15px", marginBottom: "15px" }}
          value={selection}
          onChange={selectOptionHandler}
        >
          {optionRender}
        </select>
        <span
          style={{
            display: "inline-block",
            padding: "14px 16px",
            backgroundColor: "#f2dede",
            textDecoration: "underline",
            fontWeight: "bold",
            color: "#a94442",
            borderColor: "#ebccd1",
            cursor: "pointer",
            width: "100%",
            textAlign: "center",
            marginTop: "15px",
          }}
          onClick={submitRejectHandler}
        >
          Reject
        </span>
        <div
          className={classes.SubSubTitle}
          style={{
            paddingTop: "1rem",
          }}
        >
          Additional Notes
        </div>
        <textarea
          className={classes.InputText}
          onChange={(evt) => setAdditionalReason(evt.target.value)}
        ></textarea>
      </div>
    </div>
  );
};

const FinalizeData = (props) => {
  let style = { color: "#a94442", fontWeight: "bold", fontSize: "12pt" };

  if (props.finalizeData.status === "Absent") {
    style.color = "#a94442";
  } else if (props.finalizeData.status === "Canceled") {
    style.color = "#8a6d3b";
  } else if (props.finalizeData.status === "Present") {
    style.color = "#3c763d";
  }

  return (
    <div className={classes2.SubTitle__Container}>
      <div className={classes.SubTitle}>Finalize Data</div>
      <div className={classes.SubSubTitle__Container}>
        <div className={classes.SubSubTitle}>Attendance Status</div>
        <span style={{ ...style, marginLeft: "10px" }}>
          {props.finalizeData.status}
        </span>
        <div className={classes.SubSubTitle}>Finalize Note</div>
        <span style={{ marginLeft: "10px" }}>
          {props.finalizeData.description}
        </span>
      </div>
    </div>
  );
};

export default BorrowingDetail;
