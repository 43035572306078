import React from 'react';
import AssetBorrowing from './AssetBorrowing/AssetBorrowing';
import Loading from '../../../../component/UI/Loading/Loading';

import classes from './NewAssetBorrowing.module.css';

const NewAssetBorrowing = (props) => {
  let renderPage = <Loading />;

  renderPage = (
    <AssetBorrowing
      history={props.history}
      user={props.userData}
      messageHandler={props.messageHandler}
    />
  );

  return <div className={classes.Container}>{renderPage}</div>;
};

export default NewAssetBorrowing;
