import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './MainMenuItem.module.css';

const MainMenuItem = (props) => {

    const handleClick = () => {
        if (props.sideDrawerHandler) {
            props.sideDrawerHandler(false);
        }
    }

    return (
        <NavLink exact={props.exact} to={props.to} onClick={handleClick} activeClassName={classes.Active}>
            <div className={classes.MainMenuItem}> 
                {props.children}
            </div>
        </NavLink>
    );
}

export default MainMenuItem;