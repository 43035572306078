import React from 'react';

import AddRowContainer from '../../../../../../component/UI/Text/AddRowText/AddRowContainer';

import classes from '../../NewBorrowing.module.css';

const BorrowerTeamData = (props) => {
  const onRowButtonHandler = async (value) => {
    await props.setTeammateDataHandler(value);
  };

  const onRowButtonDeleteHandler = (value) => {
    props.deleteTeammateDataHandler(value);
  };

  const backButtonHandler = () => {
    props.changeStateHandler(5);
  };

  const nextButtonHandler = () => {
    props.changeStateHandler(7);
  };

  let buttonText = 'Skip';
  if (props.teammateData.length > 0) {
    buttonText = 'Next';
  }

  let nextButtonClasses = [classes.NextButton];

  const groupQuota =
    props.groupSetting && props.groupSetting.maximum_group_per_shift !== 0 ? (
      <span>
        Maximum member : {props.groupSetting.maximum_member_per_group}
      </span>
    ) : (
      ''
    );

  return (
    <div className={classes.BorrowingDataContainer}>
      <div className={classes.Title}>Add your teammate!</div>
      {/* <Team></Team> */}
      <div className={classes.SubTitle__Container}>
        <p className={classes.Description}>
          Please provide your teammate student number, lecturer code, or binusian email
        </p>
        {groupQuota}
        <AddRowContainer
          user={props.user}
          teammateData={props.teammateData}
          placeholder={'Ex: 210xxx2x800 or D3XX8 or john.doe@binus.ac.id'}
          onRowButtonHandler={onRowButtonHandler}
          onRowButtonDeleteHandler={onRowButtonDeleteHandler}
        />
      </div>
      <div className={classes.ButtonContainer__SpaceBetween}>
        <div className={classes.BackButton} onClick={backButtonHandler}>
          <i className='material-icons' style={{ marginRight: '8px' }}>
            arrow_back
          </i>
          Back
        </div>
        <div
          className={nextButtonClasses.join(' ')}
          onClick={nextButtonHandler}
        >
          {buttonText}
          <i className='material-icons' style={{ marginLeft: '8px' }}>
            arrow_forward
          </i>
        </div>
      </div>
    </div>
  );
};

export default BorrowerTeamData;
