import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { Accordion, Icon } from 'semantic-ui-react';
import { Button, Dropdown } from 'semantic-ui-react';

import classes from '../../../Student/NewBorrowing/NewBorrowing.module.css';

const RoomSettings = (props) => {
  const [accordionState, setAccordionState] = useState(0);

  const [rooms, setRooms] = useState([]);
  const [specialShifts, setSpecialShifts] = useState([]);

  const fetchRoom = async () => {
    try {
      props.loadingHandler(true);
      let data = await Axios.get(
        process.env.REACT_APP_DEFAULT_API +
          'staff/room/borrowing-pic/' +
          props.userData.username
      );

      if (data.data && data.data.payload.status) {
        setRooms(data.data.payload.data);
      }
    } catch (e) {
      props.messageHandler(
        'Unexpected error please call the administrator',
        'error',
        true
      );
    }

    props.loadingHandler(false);
  };
  useEffect(() => {
    fetchRoom();
    const fetchSpecialShifts = async () => {
      try {
        props.loadingHandler(true);
        let data = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + 'staff/master-special-shift'
        );
        if (data.data && data.data.payload.status) {
          data.data.payload.data = data.data.payload.data.map((e) => {
            return { value: e.name, text: e.name };
          });
          setSpecialShifts(data.data.payload.data);
        }
      } catch (e) {
        props.messageHandler(
          'Unexpected error please call the administrator',
          'error',
          true
        );
      }

      props.loadingHandler(false);
    };
    // fetchSpecialShifts();
    fetchRoom();
  }, []);
  // const insertRoomSpecialShiftButtonHandler = async (room = null) => {
  //   props.loadingHandler(true);
  //   let names = [];
  //   let duplicate = false;
  //   let zero = false;
  //   room.room_special_shifts.forEach((e) => {
  //     if (names.includes(e.special_shift.name)) {
  //       props.messageHandler('Duplicate Special Shift', 'error', true);
  //       duplicate = true;
  //     }
  //     if (e.maximum_capacity == 0) {
  //       props.messageHandler('Please Input Maximum Capacity', 'error', true);
  //       zero = true;
  //     }
  //     names.push(e.special_shift.name);
  //   });
  //   if (duplicate || zero) {
  //     props.loadingHandler(false);
  //     return;
  //   }

  //   try {
  //     let result = await Axios.post(
  //       process.env.REACT_APP_DEFAULT_API + 'staff/room-special-shift',
  //       {
  //         room: room,
  //       }
  //     );

  //     if (result.data.payload.status) {
  //       props.messageHandler(
  //         'Success Inserting Room Special Shift',
  //         'success',
  //         true
  //       );
  //     } else {
  //       props.messageHandler('Cannot Insert Room Special Shift', 'error', true);
  //     }

  //     props.loadingHandler(false);
  //   } catch (e) {
  //     props.messageHandler('Unexpected error, please call the administrator');
  //     props.loadingHandler(false);
  //   }
  //   fetchRoom();
  // };

  const accordionClickHandler = (e, props) => {
    const newIndex = accordionState === props.index ? -1 : props.index;

    setAccordionState(newIndex);
  };

  const renderRooms = rooms.map((room, i) => {
    return (
      <>
        <Accordion.Title
          active={accordionState === room.id}
          index={room.id}
          onClick={accordionClickHandler}
          key={room.id}
        >
          <Icon name='dropdown' />
          {room.room_code}
        </Accordion.Title>
        <Accordion.Content active={accordionState === room.id}>
          <div style={{ marginLeft: '20px', paddingRight: '20px' }}>
            <div style={{ fontWeight: 'bold' }}>
              Maximum group per shift <br />
            </div>
            <input
              type='text'
              className={classes.InputText}
              style={{ margin: 0 }}
              value={
                room.group_setting
                  ? room.group_setting.maximum_group_per_shift
                  : 0
              }
            />
          </div>
          <div
            style={{
              marginLeft: '20px',
              paddingRight: '20px',
              marginTop: '10px',
            }}
          >
            <div style={{ fontWeight: 'bold' }}>
              Maximum member per group <br />
            </div>
            <input
              type='text'
              className={classes.InputText}
              style={{ margin: 0 }}
              value={
                room.group_setting
                  ? room.group_setting.maximum_member_per_group
                  : 0
              }
            />
          </div>
          {/* <div>Room Special Shifts </div>
          <Button
            positive
            onClick={() => {
              let temp = [...rooms];

              temp[i].room_special_shifts.push({
                special_shift: {
                  name: specialShifts[0].value,
                  maximum_capacity: 0,
                },
              });
              setRooms(temp);
            }}
          >
            Add New Row
          </Button>
          {room.room_special_shifts.map((e, index) => {
            return (
              <div>
                <div style={{ textAlign: 'right' }}>
                  <Icon
                    style={{ fontSize: '2em', cursor: 'pointer' }}
                    name='window close'
                    onClick={(evt) => {
                      let temp = [...rooms];
                      temp[i].room_special_shifts.splice(index, 1);
                      setRooms(temp);
                    }}
                  />
                </div>
                <div style={{ marginLeft: '20px', paddingRight: '20px' }}>
                  <div style={{ fontWeight: 'bold' }}>
                    Special Shift Name
                    <br />
                  </div>
                  <Dropdown
                    selection
                    fluid
                    placeholder='Special Shift Name'
                    options={specialShifts}
                    defaultValue={e.special_shift.name}
                    onChange={(evt, value) => {
                      let temp = [...rooms];
                      temp[i].room_special_shifts[index].special_shift.name =
                        value.value;
                      setRooms(temp);
                    }}
                  />
                </div>
                <div style={{ marginLeft: '20px', paddingRight: '20px' }}>
                  <div style={{ fontWeight: 'bold' }}>
                    Maximum Capacity
                    <br />
                  </div>
                  <input
                    type='text'
                    className={classes.InputText}
                    style={{ margin: 0 }}
                    value={e.maximum_capacity}
                    onChange={(evt) => {
                      let temp = [...rooms];
                      temp[i].room_special_shifts[index].maximum_capacity =
                        evt.target.value;
                      setRooms(temp);
                    }}
                  />
                </div>
              </div>
            );
          })} */}
          {/* <div>
            <Button
              positive
              style={{ marginTop: '10px' }}
              onClick={() => {
                // insertRoomSpecialShiftButtonHandler(room);
              }}
            >
              Save
            </Button>
          </div> */}
        </Accordion.Content>
      </>
    );
  });

  return (
    <>
      <div
        style={{
          fontStyle: 'italic',
          fontWeight: 'bold',
          marginLeft: '10px',
          marginBottom: '10px',
        }}
      >
        *) if group per shift is 0, the maximum borrowing transaction per shift is limited to 1 borowing.
        <br />
        else if group per shift is not 0, the maximum borrowing transaction per shift is limited to the group per shift value.
      </div>
      <div style={{ marginBottom: '28px' }}>
        <Accordion>{renderRooms}</Accordion>
      </div>
    </>
  );
};

export default RoomSettings;
