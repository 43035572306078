import React, { useState, useEffect } from 'react';

import classes from '../../../../container/Home/Student/NewBorrowing/NewBorrowing.module.css';
import classes2 from '../../List/VerticalListsMultiple/VerticalListMultiple/VerticalListMultiple.module.css';

const AddRowText = (props) => {

    const [text, setText] = useState('');

    useEffect(() => {
        if (props.value != null) {
            setText(props.value.binusian_id + ' - ' + props.value.name);
        } else {
            setText('');
        }
    }, [props.value]);

    const onTextChange = (evt) => {
        setText(evt.target.value);
    }

    const buttonHandler = () => {
        if (props.value == null) {
            if (text !== '' && text != null) {
                let status = props.onRowButtonHandler(text);
                if (status) {
                    setText('');
                }
            }
        } else {
            props.onRowButtonDeleteHandler(props.index);
        }
    }

    let iconClasses = ["material-icons", classes2.Icon];

    if (props.value != null) {
        iconClasses.push(classes2.IconBackground__Red);
    }

    return (
        <div style={{
            display: "flex",
            alignItems: "center",
        }}>
            <input 
                type="text"
                className={classes.Test + " " + classes.InputText} 
                placeholder={props.placeholder}
                value={text}
                onChange={onTextChange}
                disabled={props.value != null}
                />
            {
                // check apakah ini AddRowText milik ketua kelompok
                (props.user != null && props.user.binusian_id === props.value.binusian_id)?
                <i></i>
                :
                <i className={iconClasses.join(" ")} style={{marginTop: "15px", marginLeft:"15px"}} onClick={buttonHandler}>
                    {props.value != null ? "delete" : "add"}
                </i>
            }
        </div>
    );
}

export default AddRowText;