import React  from 'react';

import AddRowText from './AddRowText';

const AddRowContainer = (props) => {

    let renderRows = props.teammateData.map((item, index) => {
        
        return (
            <AddRowText
                user={props.user}
                key={item.binusian_id}
                index={index}
                value={item}
                placeholder={props.placeholder}
                onRowButtonHandler={props.onRowButtonHandler}
                onRowButtonDeleteHandler={props.onRowButtonDeleteHandler} />
        );
    });


    return (
        <>
            {renderRows}
            <AddRowText
                placeholder={props.placeholder}
                onRowButtonHandler={props.onRowButtonHandler}
                onRowButtonDeleteHandler={props.onRowButtonDeleteHandler} />
        </>
    );
}

export default AddRowContainer;