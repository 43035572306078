import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import ReactTable from 'react-table';
import { Grid, Button, Segment, Dropdown, Input } from 'semantic-ui-react';

import Backdrop from '../../../../../component/UI/Backdrop/Backdrop';

import 'react-table/react-table.css';
import './CustomReactTable.css';

const RoomLocation = (props) => {
  const [rooms, setRooms] = useState([]);
  // eslint-disable-next-line
  // const [roomTypes, setRoomTypes] = useState([]);
  const [roomTypeOptions, setRoomTypeOptions] = useState([]);
  const [roomCode, setRoomCode] = useState('');
  const [roomName, setRoomName] = useState('');
  const [roomIP, setRoomIP] = useState('');
  const [roomCapacity, setRoomCapacity] = useState('');
  const [roomStatus, setRoomStatus] = useState('');
  const [roomType, setRoomType] = useState('');
  const [roomDelay, setroomDelay] = useState('');
  const [dialogBoxShow, setDialogBoxShow] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState({
    type: {
      id: '',
      name: '',
    },
  });

  useEffect(() => {
    fetchRoomLocationsAndTypes();
  }, []);

  const fetchRoomLocationsAndTypes = async () => {
    try {
      props.loadingHandler(true);
      let data = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + 'admin/room'
      );
      let roomType = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + 'admin/room-type'
      );
      let tempArr = [];
      if (roomType.data && roomType.data.payload.status) {
        let temp = roomType.data.payload.data;
        
        for (let i = 0; i < temp.length; i++) {
          let transformed = {
            key: temp[i].id,
            value: temp[i].id,
            text: temp[i].name,
          };
          tempArr.push(transformed);
          // console.log(tempArr);
        }
        // setRoomTypes(roomType.data.payload.data);
        
        setRoomTypeOptions(tempArr);
      }
      if (data.data && data.data.payload.status) {
        let temp = data.data.payload.data;
        let tempArr2 = [];
        for (let i = 0; i < temp.length; i++) {
          for (let j = 0; j < tempArr.length; j++) {
            if(tempArr[j].key == temp[i].room_type_id){
              let transformed = {
                id: temp[i].id,
                room_code: temp[i].room_code,
                room_description: temp[i].room_description,
                room_ip: temp[i].room_ip,
                room_capacity: temp[i].room_capacity,
                room_delay: temp[i].room_delay,
                room_availability: temp[i].room_availability,
                room_type_id: tempArr[j].id,
                room_type_name: tempArr[j].text,
              };
              tempArr2.push(transformed);
            }
            
          }
        }
        setRooms(tempArr2);
      }
     
    } catch (e) {
      props.messageHandler(
        'Unexpected error please call the administrator',
        'error',
        true
      );
    }

    props.loadingHandler(false);
  };

  const updateRoomButtonHandler = async (
    id,
    code = null,
    name = null,
    type = null,
    ip = null,
    capacity = null,
    delay = null,
    status = null
  ) => {
    props.loadingHandler(true);

    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API + 'admin/room/' + id,
        {
          code: code,
          name: name,
          type: type,
          delay: delay,
          ip: ip,
          capacity: capacity,
          status: status,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler('Success Updating Room Type', 'success', true);
      } else {
        props.messageHandler('Cannot Update Room Type', 'error', true);
      }

      props.loadingHandler(false);

      fetchRoomLocationsAndTypes();
    } catch (e) {
      props.messageHandler('Unexpected error, please call the administrator');
      props.loadingHandler(false);
    }
  };

  const insertRoomButtonHandler = async (
    code = null,
    name = null,
    type = null,
    ip = null,
    capacity = null,
    delay = null,
    status = null
  ) => {
    props.loadingHandler(true);

    try {
      let result = await Axios.post(
        process.env.REACT_APP_DEFAULT_API + 'admin/room',
        {
          code: code,
          name: name,
          type: type,
          ip: ip,
          capacity: capacity,
          delay: delay,
          status: status,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler('Success Inserting Room Type', 'success', true);
      } else {
        props.messageHandler('Cannot Insert Room Type', 'error', true);
      }

      props.loadingHandler(false);

      fetchRoomLocationsAndTypes();
    } catch (e) {
      props.messageHandler('Unexpected error, please call the administrator');
      props.loadingHandler(false);
    }
  };

  const showHideDialogBox = (
    mode,
    room = {
      type: {
        id: '',
        name: '',
      },
    }
  ) => {
    setSelectedRoom(room);

    setDialogBoxShow(mode);
  };

  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
    },
    {
      Header: 'Room Code',
      accessor: 'room_code',
    },
    {
      Header: 'Room Name',
      accessor: 'room_description',
    },
    {
      Header: 'Room Type',
      accessor: 'room_type_name',
    },
    {
      Header: 'IP',
      accessor: 'room_ip',
    },
    {
      Header: 'Capacity',
      accessor: 'room_capacity',
    },
    {
      Header: 'Borrowing Delay',
      accessor: 'room_delay',
    },
    // {
    //   Header: 'Status',
    //   accessor: 'room_availability',
    //   Cell: (props) => {
    //     let style = {
    //       fontWeight: 'bold',
    //       color: '#a94442',
    //       // backgroundColor: '#dff0d8',
    //       display: 'inline-block',
    //       width: '100%',
    //       height: '100%',
    //       textAlign: 'center',
    //       cursor: 'pointer',
    //       userSelect: 'none',
    //     };
    //     let val = props.original.room_availability == 1 ? 'Inactive' : 'Active';

    //     if (props.original.room_availability != 1) {
    //       style.color = '#3c763d';
    //       // style.backgroundColor = '#f2dede';
    //     }

    //     return <span style={style}>{val}</span>;
    //   },
    // },
    {
      Header: 'Action',
      filterable: false,
      sortable: false,
      Cell: (props) => {
        let style = {
          fontWeight: 'bold',
          color: '#3c763d',
          backgroundColor: '#dff0d8',
          display: 'inline-block',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          cursor: 'pointer',
          userSelect: 'none',
        };

        let val = 'Edit';

        return (
          <span
            style={style}
            onClick={() => showHideDialogBox(true, props.original)}
          >
            {val}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Backdrop
        clickable={true}
        clickHandler={showHideDialogBox}
        show={dialogBoxShow}
      />
      <DialogBox
        messageHandler={props.messageHandler}
        selectedRoom={selectedRoom}
        roomTypeOptions={roomTypeOptions}
        updateRoomButtonHandler={updateRoomButtonHandler}
        show={dialogBoxShow}
      />
      <Segment>
        <h3>Insert New Room</h3>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <span>Code</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <input
                type='text'
                placeholder='Room Code'
                value={roomCode}
                onChange={(evt) => {
                  setRoomCode(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <span>Name</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <input
                type='text'
                placeholder='Room Name'
                value={roomName}
                onChange={(evt) => {
                  setRoomName(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <span>IP Address</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <input
                type='text'
                placeholder='IP Address'
                value={roomIP}
                onChange={(evt) => {
                  setRoomIP(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <span>Capacity</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Input
                type='number'
                placeholder='0'
                value={roomCapacity}
                onChange={(evt) => {
                  setRoomCapacity(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Delay (day(s))</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Input
                type='number'
                placeholder='0'
                value={roomDelay}
                onChange={(evt) => {
                  setroomDelay(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <span>Status</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Active / Inactive'
                value={roomStatus}
                onChange={(evt, { value }) => {
                  setRoomStatus(value);
                }}
                options={[
                  { key: 1, value: 'Active', text: 'Active' },
                  { key: 2, value: 'Inactive', text: 'Inactive' },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Type</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Select Room Type Location'
                value={roomType}
                onChange={(event, { value }) => {
                  setRoomType(value);
                }}
                options={roomTypeOptions}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Button
                positive
                fluid
                onClick={() => {
                  if (roomCode.length === 0) {
                    props.messageHandler(
                      'Room Code must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomName.length === 0) {
                    props.messageHandler(
                      'Room Name must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  // if (roomIP.length === 0) {
                  //     props.messageHandler('Room IP must be filled', 'error', true);
                  //     return;
                  // }
                  if (roomCapacity.length === 0) {
                    props.messageHandler(
                      'Room Capacity must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomStatus.length === 0) {
                    props.messageHandler(
                      'Room Status must be chosen',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomType.length === 0) {
                    props.messageHandler(
                      'Room Type must be chosen',
                      'error',
                      true
                    );
                    return;
                  }
                  insertRoomButtonHandler(
                    roomCode,
                    roomName,
                    roomType,
                    roomIP,
                    roomCapacity,
                    roomDelay,
                    roomStatus
                  );
                }}
              >
                Insert
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <ReactTable
        data={rooms}
        columns={columns}
        filterable={true}
        defaultPageSize={10}
        className='-striped -highlight'
      />
    </>
  );
};

const DialogBox = (props) => {
  let dialogBoxStyles = {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '105',
    padding: '16px 18px',
    minWidth: '460px',
    maxWidth: '300px',
    backgroundColor: 'white',
    boxShadow: '0 1px 0 rgba(12,13,14,0.1), 0 1px 6px rgba(59,64,69,0.1)',
    visibility: 'hidden',
  };

  let buttonStyle = {
    marginTop: '10px',
    fontWeight: 'bold',
    color: '#3c763d',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#dff0d8',
    display: 'inline-block',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  };

  if (props.show) {
    dialogBoxStyles.visibility = 'visible';
  }
  //editing form VVV
  const DialogBoxContent = () => {
    const [roomID, setRoomID] = useState('');
    const [roomCode, setRoomCode] = useState('');
    const [roomName, setRoomName] = useState('');
    const [roomIP, setRoomIP] = useState('');
    const [roomCapacity, setRoomCapacity] = useState('');
    const [roomDelay, setRoomDelay] = useState('');
    const [roomStatus, setRoomStatus] = useState('');
    const [roomType, setRoomType] = useState('');
    const [roomTypeOptions, setRoomTypeOptions] = useState([]);

    useEffect(() => {
      setRoomID(props.selectedRoom.id);
      setRoomCode(props.selectedRoom.room_code);
      setRoomName(props.selectedRoom.room_description);
      setRoomIP(props.selectedRoom.room_ip);
      setRoomCapacity(props.selectedRoom.room_capacity);
      setRoomStatus(props.selectedRoom.status);
      setRoomType(props.selectedRoom.room_type_id);
      setRoomTypeOptions(props.roomTypeOptions);
      setRoomDelay(props.selectedRoom.room_delay);
    }, [props]);

    //kalo inactive berarti dia mo matiin usernya
    // if (props.dialogPurpose === 'Inactivate') {
    buttonStyle.color = '#3c763d';
    buttonStyle.backgroundColor = '#dff0d8';
    return (
      <Segment>
        <h3>Update Room #{roomID}</h3>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <span>Code</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <input
                type='text'
                placeholder='Room Code'
                value={roomCode}
                onChange={(evt) => {
                  setRoomCode(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <span>Name</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <input
                type='text'
                placeholder='Room Name'
                value={roomName}
                onChange={(evt) => {
                  setRoomName(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <span>IP Address</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <input
                type='text'
                placeholder='IP Address'
                value={roomIP}
                onChange={(evt) => {
                  setRoomIP(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <span>Capacity</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Input
                type='number'
                placeholder='0'
                value={roomCapacity}
                onChange={(evt) => {
                  setRoomCapacity(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Delay (day(s))</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Input
                type='number'
                placeholder='0'
                value={roomDelay}
                onChange={(evt) => {
                  setRoomDelay(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              <span>Status</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Active / Inactive'
                value={roomStatus}
                onChange={(evt, { value }) => {
                  setRoomStatus(value);
                }}
                options={[
                  { key: 1, value: 'Active', text: 'Active' },
                  { key: 2, value: 'Inactive', text: 'Inactive' },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Type</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Select Room Type Location'
                value={roomType}
                onChange={(event, { value }) => {
                  setRoomType(value);
                }}
                options={roomTypeOptions}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Button
                positive
                fluid
                onClick={() => {
                  if (roomCode.length === 0) {
                    props.messageHandler(
                      'Room Code must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomName.length === 0) {
                    props.messageHandler(
                      'Room Name must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  // if (roomIP.length === 0) {
                  //     props.messageHandler('Room IP must be filled', 'error', true);
                  //     return;
                  // }
                  if (roomCapacity.length === 0) {
                    props.messageHandler(
                      'Room Capacity must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomStatus.length === 0) {
                    props.messageHandler(
                      'Room Status must be chosen',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomType.length === 0) {
                    props.messageHandler(
                      'Room Type must be chosen',
                      'error',
                      true
                    );
                    return;
                  }
                  props.updateRoomButtonHandler(
                    roomID,
                    roomCode,
                    roomName,
                    roomType,
                    roomIP,
                    roomCapacity,
                    roomDelay,
                    roomStatus
                  );
                }}
              >
                Update
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  return <div style={dialogBoxStyles}>{DialogBoxContent()}</div>;
};

export default RoomLocation;
