import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Dropdown } from "semantic-ui-react";
import AssetBorrowingDetail from "../../Student/AssetBorrowingDetail/AssetBorrowingDetail";
import SubMenu from "../../../../component/Navigation/Menu/SubMenu/SubMenu";
import classes from "./NewStaffAssetBorrowingHistory.module.css";
import AssetBorrowingList from "../../../../component/UI/List/AssetBorrowingList/AssetBorrowingList";

const StaffAssetBorrowingHistory = (props) => {
  const [data, setData] = useState([]);
  const [semester, setSemester] = useState([]);
  const [selectedSemester, setSelectedSemester] = useState(0);
  const [currentSemester, setCurrentSemester] = useState(0);
  const [serverTime, setServerTime] = useState(null);
  const [menuMode, setMenuMode] = useState(0);
  const [selectedSubMenu, setSelectedSubMenu] = useState(0);
  const [unfinalizedDataCount, setUnfinalizedDataCount] = useState(0);
  const [isDetailShow, setDetailShow] = useState({
    show: false,
    data: null,
  });

  const [currentStudent, setCurrentStudent] = useState({});
  const [currentStudentData, setCurrentStudentData] = useState({});
  const [currentAssets, setCurrentAssets] = useState([]);
  const [currentCheckInNotes, setCurrentCheckInNotes] = useState(null);
  const [currentCheckOutNotes, setCurrentCheckOutNotes] = useState(null);
  const [currentCheckInDetailIDs, setCurrentCheckInDetailIDs] = useState(null);
  const [currentCheckOutDetailIDs, setCurrentCheckOutDetailIDs] =
    useState(null);

  const subMenuList = [
    {
      name: "All",
      cssClasses: [classes.All],
      handler: () => setSelectedSubMenu(0),
    },
    {
      name: "Accepted[" + unfinalizedDataCount + " unfinalized]",
      cssClasses: [classes.Accepted],
      handler: () => setSelectedSubMenu(1),
    },
    {
      name: "Rejected",
      cssClasses: [classes.Rejected],
      handler: () => setSelectedSubMenu(2),
    },
    {
      name: "Canceled",
      cssClasses: [classes.Canceled],
      handler: () => setSelectedSubMenu(3),
    },
    {
      name: "Check-Out Finalized",
      cssClasses: [classes.Finalized],
      handler: () => setSelectedSubMenu(4),
    },
    {
      name: "Check-In Finalized",
      cssClasses: [classes.Finalized],
      handler: () => setSelectedSubMenu(5),
    },
    {
      name: "Late / Absent",
      cssClasses: [classes.Absent],
      handler: () => setSelectedSubMenu(6),
    },
  ];

  const onClickDataHandler = (data) => {
    setDetailShow({
      show: true,
      data: data,
    });
  };

  const backButtonHandler = () => {
    setDetailShow({
      show: false,
      data: null,
    });
  };

  const getUnfinalizedDataCount = async () => {
    let result = await Axios.get(
      process.env.REACT_APP_DEFAULT_API + "staff/asset-borrowing/accepted",
      {
        params: {
          pic: props.userData.username,
          semesterId: selectedSemester,
        },
      }
    );
    setUnfinalizedDataCount(result.data.payload.data.length);
  };

  const acceptButtonHandler = async (id) => {
    props.loadingHandler(true);
    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API + "staff/accept-asset-borrowing",
        {
          id: id,
          username: props.userData.username,
        }
      );
      if (result.data.payload.status) {
        props.messageHandler(
          result.data.payload.message,
          "Accept Success",
          true
        );
      } else {
        props.messageHandler(result.data.payload.message, "Error", true);
      }

      setTimeout(() => {
        props.loadingHandler(true);
      }, 1000);

      setTimeout(() => {
        props.loadingHandler(false);
        window.location.reload();
      }, 3000);
    } catch (e) {
      props.messageHandler(
        "Unexpected error please call the administrator",
        "error",
        true
      );
      props.loadingHandler(false);
    }
  };

  const rejectButtonHandler = async (id, reason) => {
    console.log(reason);
    if (reason == "ISI DONG REASONNYA") {
      props.messageHandler("Additional note must be filled!", "error", true);
      return;
    }

    props.loadingHandler(true);
    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API + "staff/reject-asset-borrowing",
        {
          id: id,
          username: props.userData.username,
          reason: reason,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler(result.data.payload.message, "success", true);
      } else {
        props.messageHandler(result.data.payload.message, "error", true);
      }

      setTimeout(() => {
        props.loadingHandler(true);
      }, 1000);

      setTimeout(() => {
        props.loadingHandler(false);
        window.location.reload();
      }, 3000);
    } catch (e) {
      props.messageHandler(
        "Unexpected error please call the administrator",
        "error",
        true
      );
      props.loadingHandler(false);
    }
  };

  const finalizeCheckInButtonHandler = async (
    greenlit_id,
    check_in_id,
    shift_id,
    status,
    description,
    check_in_notes,
    assets,
    asset_calibrations
  ) => {
    props.loadingHandler(true);

    if (!status || status.length === 0) {
      props.messageHandler("Status must be filled!", "error", true);
      props.loadingHandler(false);
      return;
    }

    if (status === "present" && (!description || description.length === 0)) {
      props.messageHandler("Check In Note must be filled!", "error", true);
      props.loadingHandler(false);
      return;
    }

    for (let i = 0; i < check_in_notes.length; i++) {
      const currNote = check_in_notes[i];

      if (!currNote || currNote.length === 0) {
        props.messageHandler("Check In Details must be filled!", "error", true);
        props.loadingHandler(false);
        return;
      }
    }

    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API +
          "staff/finalize-asset-borrowing-check-in",
        {
          greenlit_id: greenlit_id,
          check_in_id: check_in_id,
          username: props.userData.username,
          borrower_email:currentStudent.email,
          shift_id: shift_id,
          status: status,
          description: description,
          check_in_notes: check_in_notes,
          check_in_detail_ids: currentCheckInDetailIDs,
          assets: assets,
          asset_calibrations: asset_calibrations,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler(result.data.payload.message, "success", true);
        props.loadingHandler(false);
      } else {
        props.messageHandler(result.data.payload.message, "error", true);
        props.loadingHandler(false);
      }
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (e) {
      props.messageHandler(
        "Unexpected error please call the administrator",
        "error",
        true
      );
      props.loadingHandler(false);
    }
    // else {
    //   props.messageHandler(
    //     "Please input all the requested data",
    //     "error",
    //     true
    //   );
    //   props.loadingHandler(false);
    // }
  };

  const finalizeCheckOutButtonHandler = async (
    greenlit_id,
    check_out_id,
    shift_id,
    status,
    description,
    check_out_notes,
    // assets,
    // asset_calibrations
  ) => {
    props.loadingHandler(true);

    // console.log(check_out_notes);

    if (!status || status.length === 0) {
      props.messageHandler("Status must be filled!", "error", true);
      props.loadingHandler(false);
      return;
    }

    if (status === "present" && (!description || description.length === 0)) {
      props.messageHandler("Check Out Note must be filled!", "error", true);
      props.loadingHandler(false);
      return;
    }

    for (let i = 0; i < check_out_notes.length; i++) {
      const currNote = check_out_notes[i];

      if (!currNote || currNote.length === 0) {
        props.messageHandler(
          "Check Out Details must be filled!",
          "error",
          true
        );
        props.loadingHandler(false);
        return;
      }
    }

    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API +
          "staff/finalize-asset-borrowing-check-out",
        {
          greenlit_id: greenlit_id,
          check_out_id: check_out_id,
          username: props.userData.username,
          shift_id: shift_id,
          status: status,
          description: description,
          check_out_notes: check_out_notes,
          check_out_detail_ids: currentCheckOutDetailIDs,
          // assets: assets,
          // asset_calibrations: asset_calibrations,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler(result.data.payload.message, "success", true);
        props.loadingHandler(false);
      } else {
        props.messageHandler(result.data.payload.message, "error", true);
        props.loadingHandler(false);
      }
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (e) {
      props.messageHandler(
        "Unexpected error please call the administrator",
        "error",
        true
      );
      props.loadingHandler(false);
    }

    // else {
    //   props.messageHandler(
    //     "Please input all the requested data",
    //     "error",
    //     true
    //   );
    //   props.loadingHandler(false);
    // }
  };

  const fetchData = async () => {
    props.loadingHandler(true);
    try {
      let result = null;
      if (menuMode === 0) {
        result = await Axios.get(
          process.env.REACT_APP_DEFAULT_API + "staff/asset-borrowing/pending",
          {
            params: {
              pic: props.userData.username,
              semesterId: selectedSemester,
            },
          }
        );
      } else {
        if (menuMode === 1) {
          if (selectedSubMenu === 0) {
            result = await Axios.get(
              process.env.REACT_APP_DEFAULT_API + "staff/asset-borrowing/all",
              {
                params: {
                  pic: props.userData.username,
                  semesterId: selectedSemester,
                },
              }
            );
          } else if (selectedSubMenu === 1) {
            result = await Axios.get(
              process.env.REACT_APP_DEFAULT_API +
                "staff/asset-borrowing/accepted",
              {
                params: {
                  pic: props.userData.username,
                  semesterId: selectedSemester,
                },
              }
            );
          } else if (selectedSubMenu === 2) {
            result = await Axios.get(
              process.env.REACT_APP_DEFAULT_API +
                "staff/asset-borrowing/rejected",
              {
                params: {
                  pic: props.userData.username,
                  semesterId: selectedSemester,
                },
              }
            );
          } else if (selectedSubMenu === 3) {
            result = await Axios.get(
              process.env.REACT_APP_DEFAULT_API +
                "staff/asset-borrowing/canceled",
              {
                params: {
                  pic: props.userData.username,
                  semesterId: selectedSemester,
                },
              }
            );
          } else if (selectedSubMenu === 4) {
            result = await Axios.get(
              process.env.REACT_APP_DEFAULT_API +
                "staff/asset-borrowing/check-out-finalized",
              {
                params: {
                  pic: props.userData.username,
                  semesterId: selectedSemester,
                },
              }
            );
          } else if (selectedSubMenu === 5) {
            result = await Axios.get(
              process.env.REACT_APP_DEFAULT_API +
                "staff/asset-borrowing/check-in-finalized",
              {
                params: {
                  pic: props.userData.username,
                  semesterId: selectedSemester,
                },
              }
            );
          } else if (selectedSubMenu === 6) {
            result = await Axios.get(
              process.env.REACT_APP_DEFAULT_API +
                "staff/asset-borrowing/absent",
              {
                params: {
                  pic: props.userData.username,
                  semesterId: selectedSemester,
                },
              }
            );
          }
        }
      }

      if (Array.isArray(result.data.payload.data)) {
        setData(result.data.payload.data);
        setServerTime(result.data.payload.server_time);
      }
    } catch (e) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
      setData([]);
    }
    props.loadingHandler(false);
  };

  const fetchSemester = async () => {
    props.loadingHandler(true);
    try {
      let results = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + "semester"
      );

      let semesterData = results.data.semester.data;

      if (semesterData.length !== 0) {
        let temp = [];
        for (let i = 0; i < semesterData.length; i++) {
          let transformed = {
            key: semesterData[i].id,
            value: semesterData[i].id,
            text: semesterData[i].description,
          };
          temp.push(transformed);
        }
        setSemester(temp);
      }
    } catch (e) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
      setSemester([]);
    }
    props.loadingHandler(false);
  };

  const getCurrentSemester = async () => {
    try {
      let result = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + "semester/current"
      );
      setSelectedSemester(result.data.currentSemester.id);
      setCurrentSemester(result.data.currentSemester);
    } catch (error) {
      props.messageHandler(
        "Unexpected error, please contact the administrator",
        "error",
        true
      );
      setSelectedSemester(1);
    }
  };

  useEffect(() => {
    getCurrentSemester();
    fetchSemester();
    getUnfinalizedDataCount();
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedSemester !== 0) {
      getUnfinalizedDataCount();
      fetchData();
    }
  }, [selectedSemester, menuMode, selectedSubMenu]);

  useEffect(() => {
    if (isDetailShow.data) {
      setCurrentStudent({
        name: isDetailShow.data.student_name,
        email: isDetailShow.data.student_email,
        username: isDetailShow.data.student_id,
      });

      setCurrentStudentData({
        phone: isDetailShow.data.student_phone_number,
        purpose: isDetailShow.data.comments,
      });

      // Ambil Data Assets
      let assets = [];
      let checkInNotes = [];
      let checkOutNotes = [];
      let checkInDetailIDs = [];
      let checkOutDetailIDs = [];

      isDetailShow.data.check_in.details.forEach((item) => {
        assets.push(item.asset);
        checkInNotes.push(item.note);
        checkInDetailIDs.push(item.id);
      });

      isDetailShow.data.check_out.details.forEach((item) => {
        checkOutNotes.push(item.note);
        checkOutDetailIDs.push(item.id);
      });

      setCurrentAssets(assets);
      setCurrentCheckInNotes(checkInNotes);
      setCurrentCheckOutNotes(checkOutNotes);
      setCurrentCheckInDetailIDs(checkInDetailIDs);
      setCurrentCheckOutDetailIDs(checkOutDetailIDs);
    }
  }, [isDetailShow]);

  const assetBorrowingList = () => {
    if (data.length > 0) {
      const render = data.map((item, index) => (
        <AssetBorrowingList
          onClickHandler={onClickDataHandler}
          key={index}
          data={item}
        />
      ));

      return render;
    }

    return (
      <div
        style={{
          textAlign: "center",
          height: "100%",
          fontWeight: "bold",
        }}
      >
        No data available
      </div>
    );
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      {isDetailShow.show ? (
        ""
      ) : (
        <>
          {currentSemester.id ? (
            <Dropdown
              selection
              fluid
              header
              defaultValue={selectedSemester}
              onChange={(evt, { value }) => {
                setSelectedSemester(value);
              }}
              options={semester}
            />
          ) : (
            <div>
              <Dropdown selection fluid header options={semester} />
            </div>
          )}

          <MainMenu
            unfinalizedDataCount={unfinalizedDataCount}
            menu={menuMode}
            selectedSubMenu={selectedSubMenu}
            subMenuList={subMenuList}
            changeMenuHandler={setMenuMode}
          />
        </>
      )}
      <div style={{ width: "100%", marginTop: "24px" }}>
        {isDetailShow.show ? (
          <AssetBorrowingDetail
            detailBorrowing={true}
            pic={true}
            backButtonHandler={backButtonHandler}
            acceptButtonHandler={acceptButtonHandler}
            rejectButtonHandler={rejectButtonHandler}
            finalizeCheckInButtonHandler={finalizeCheckInButtonHandler}
            finalizeCheckOutButtonHandler={finalizeCheckOutButtonHandler}
            loadingHandler={props.loadingHandler}
            // history={props.history}
            messageHandler={props.messageHandler}
            id={isDetailShow.data.id}
            status={isDetailShow.data.status}
            date={isDetailShow.data.check_in_date}
            serverTime={serverTime}
            user={currentStudent}
            userData={currentStudentData}
            room={isDetailShow.data.check_in.details[0].asset.room}
            // laboratoryType={isDetailShow.data.room.type}
            assets={currentAssets}
            checkInID={isDetailShow.data.check_in.id}
            checkOutID={isDetailShow.data.check_out.id}
            checkInDate={isDetailShow.data.check_in_date}
            checkOutDate={isDetailShow.data.check_out_date}
            checkInShift={isDetailShow.data.check_in_shift}
            checkOutShift={isDetailShow.data.check_out_shift}
            actualCheckInShift={isDetailShow.data.check_in.shift}
            actualCheckOutShift={isDetailShow.data.check_out.shift}
            checkInStatus={isDetailShow.data.check_in.realization}
            checkOutStatus={isDetailShow.data.check_out.realization}
            checkInNote={isDetailShow.data.check_in.finalization_note}
            checkOutNote={isDetailShow.data.check_out.finalization_note}
            checkInNotes={currentCheckInNotes}
            checkOutNotes={currentCheckOutNotes}
            // shifts={isDetailShow.data.detail_shifts}
            // teammateData={isDetailShow.data.teammateData}
            finalizeData={{
              status: isDetailShow.data.attendance_status,
              description: isDetailShow.data.finalize_note,
            }}
          />
        ) : (
          assetBorrowingList()
        )}
      </div>
    </div>
  );
};

const MainMenu = (props) => {
  return (
    <>
      <div className={classes.MenuContainer}>
        <span
          className={props.menu === 0 ? classes.Active : ""}
          onClick={() => props.changeMenuHandler(0)}
        >
          Recent
        </span>
        <span
          className={props.menu === 1 ? classes.Active : ""}
          onClick={() => props.changeMenuHandler(1)}
        >
          History [{props.unfinalizedDataCount} data unfinalized]
        </span>
      </div>
      {props.menu === 1 ? (
        <SubMenu
          subMenuList={props.subMenuList}
          selectedSubMenu={props.selectedSubMenu}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default StaffAssetBorrowingHistory;
