import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import ReactTable from 'react-table';

import Backdrop from '../../../../../component/UI/Backdrop/Backdrop';
import { Grid, Button, Segment, Dropdown, TextArea } from 'semantic-ui-react';

import 'react-table/react-table.css';
import './CustomReactTable.css';

const RoomLocation = (props) => {
  const [roomTypes, setRoomTypes] = useState([]);
  // eslint-disable-next-line
  const [roomLocations, setRoomLocations] = useState([]);
  const [roomLocationOptions, setRoomLocationOptions] = useState([]);
  const [roomTypeName, setRoomTypeName] = useState('');
  const [roomTypeDescription, setRoomTypeDescription] = useState('');
  const [roomTypeBorrowable, setRoomTypeBorrowable] = useState('');
  const [roomTypeLocation, setRoomTypeLocation] = useState('');
  const [dialogBoxShow, setDialogBoxShow] = useState(false);
  const [selectedRoomType, setSelectedRoomType] = useState([]);
  const [roomTypeHasSoftware, setRoomTypeHasSoftware] = useState('');

  useEffect(() => {
    fetchRoomLocationsAndTypes();
  }, []);

  const fetchRoomLocationsAndTypes = async () => {
    try {
      props.loadingHandler(true);
      let data = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + 'admin/room-type'
      );
      let roomLocation = await Axios.get(
        process.env.REACT_APP_DEFAULT_API + 'admin/room-location'
      );
      if (data.data && data.data.payload.status) {
        setRoomTypes(data.data.payload.data);
      }
      if (roomLocation.data && roomLocation.data.payload.status) {
        let temp = roomLocation.data.payload.data;
        let tempArr = [];
        for (let i = 0; i < temp.length; i++) {
          let transformed = {
            key: temp[i].id,
            value: temp[i].id,
            text: temp[i].location,
          };
          tempArr.push(transformed);
        }
        setRoomLocations(roomLocation.data.payload.data);
        setRoomLocationOptions(tempArr);
      }
    } catch (e) {
      props.messageHandler(
        'Unexpected error please call the administrator',
        'error',
        true
      );
    }

    props.loadingHandler(false);
  };

  const updateRoomTypeButtonHandler = async (
    id,
    name = '',
    description = '',
    borrowable = '',
    has_software = '',
    location = ''
  ) => {
    props.loadingHandler(true);

    try {
      let result = await Axios.patch(
        process.env.REACT_APP_DEFAULT_API + 'admin/room-type/' + id,
        {
          id: id,
          name: name,
          description: description,
          borrowable: borrowable,
          location: location,
          has_software : has_software
        }
      );

      if (result.data.payload.status) {
        props.messageHandler('Success Updating Room Type', 'success', true);
      } else {
        props.messageHandler('Cannot Update Room Type', 'error', true);
      }

      props.loadingHandler(false);

      fetchRoomLocationsAndTypes();
    } catch (e) {
      props.messageHandler('Unexpected error, please call the administrator');
      props.loadingHandler(false);
    }
  };

  const insertRoomTypeButtonHandler = async (
    name = null,
    description = null,
    borrowable = null,
    location = null,
    has_software = null
  ) => {
    props.loadingHandler(true);

    try {
      let result = await Axios.post(
        process.env.REACT_APP_DEFAULT_API + 'admin/room-type',
        {
          name: name,
          description: description,
          borrowable: borrowable,
          location: location,
          has_software: has_software,
        }
      );

      if (result.data.payload.status) {
        props.messageHandler('Success Inserting Room Type', 'success', true);
      } else {
        props.messageHandler('Cannot Insert Room Type', 'error', true);
      }

      props.loadingHandler(false);

      fetchRoomLocationsAndTypes();
    } catch (e) {
      props.messageHandler('Unexpected error, please call the administrator');
      props.loadingHandler(false);
    }
  };

  const showHideDialogBox = (
    mode,
    roomType = {
      id: '',
      name: '',
      location_name: '',
      description: '',
      borrowable: '',
      has_software: '',
    }
  ) => {
    setSelectedRoomType(roomType);

    setDialogBoxShow(mode);
  };

  const columns = [
    {
      Header: 'Id',
      accessor: 'id',
    },
    {
      Header: 'Location',
      accessor: 'location_name',
    },
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Borrowable',
      accessor: 'borrowable',
      Cell: (props) => {
        let style = {
          fontWeight: 'bold',
          color: '#3c763d',
          // backgroundColor: '#dff0d8',
          display: 'inline-block',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          cursor: 'pointer',
          userSelect: 'none',
        };
        let val = props.original.borrowable == 1 ? 'Yes' : 'No';

        if (props.original.borrowable != 1) {
          style.color = '#a94442';
          // style.backgroundColor = '#f2dede';
        }

        return <span style={style}>{val}</span>;
      },
    },
    {
      Header: 'Has Software',
      accessor: 'has_software',
      Cell: (props) => {
        let style = {
          fontWeight: 'bold',
          color: '#3c763d',
          // backgroundColor: '#dff0d8',
          display: 'inline-block',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          cursor: 'pointer',
          userSelect: 'none',
        };
        let val = props.original.has_software == 1 ? 'Yes' : 'No';

        if (props.original.has_software != 1) {
          style.color = '#a94442';
          // style.backgroundColor = '#f2dede';
        }

        return <span style={style}>{val}</span>;
      },
    },
    {
      Header: 'Action',
      filterable: false,
      sortable: false,
      Cell: (props) => {
        let style = {
          fontWeight: 'bold',
          color: '#3c763d',
          backgroundColor: '#dff0d8',
          display: 'inline-block',
          width: '100%',
          height: '100%',
          textAlign: 'center',
          cursor: 'pointer',
          userSelect: 'none',
        };

        let val = 'Edit';

        return (
          <span
            style={style}
            onClick={() => showHideDialogBox(true, props.original)}
          >
            {val}
          </span>
        );
      },
    },
  ];

  return (
    <>
      <Backdrop
        clickable={true}
        clickHandler={showHideDialogBox}
        show={dialogBoxShow}
      />
      <DialogBox
        messageHandler={props.messageHandler}
        selectedRoomType={selectedRoomType}
        roomLocationOptions={roomLocationOptions}
        updateRoomTypeButtonHandler={updateRoomTypeButtonHandler}
        show={dialogBoxShow}
      />
      <Segment>
        <h3>Insert New Room Type</h3>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <span>Name</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <input
                type='text'
                placeholder='Name'
                value={roomTypeName}
                onChange={(evt) => {
                  setRoomTypeName(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Description</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <TextArea
                type='text'
                placeholder='Description'
                value={roomTypeDescription}
                onChange={(evt) => {
                  setRoomTypeDescription(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Borrowable</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Yes / No'
                value={roomTypeBorrowable}
                onChange={(evt, { value }) => {
                  setRoomTypeBorrowable(value);
                }}
                options={[
                  { key: 1, value: 1, text: 'Yes' },
                  { key: 2, value: 0, text: 'No' },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Has Software</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Yes / No'
                value={roomTypeHasSoftware}
                onChange={(evt, { value }) => {
                  setRoomTypeHasSoftware(value);
                }}
                options={[
                  { key: 1, value: 1, text: 'Yes' },
                  { key: 2, value: 0, text: 'No' },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Location</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Select Room Type Location'
                value={roomTypeLocation}
                onChange={(event, { value }) => {
                  setRoomTypeLocation(value);
                }}
                options={roomLocationOptions}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Button
                positive
                fluid
                onClick={() => {
                  if (roomTypeName.length === 0) {
                    props.messageHandler(
                      'Room Type Name must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomTypeDescription.length === 0) {
                    props.messageHandler(
                      'Room Type Description must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomTypeBorrowable.length === 0) {
                    props.messageHandler(
                      'Borrowable status must be choosen',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomTypeLocation.length === 0) {
                    props.messageHandler(
                      'Room Type Location must be choosen',
                      'error',
                      true
                    );
                    return;
                  }
                  insertRoomTypeButtonHandler(
                    roomTypeName,
                    roomTypeDescription,
                    roomTypeBorrowable,
                    roomTypeLocation,
                    roomTypeHasSoftware
                  );
                }}
              >
                Insert
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <ReactTable
        data={roomTypes}
        columns={columns}
        filterable={true}
        defaultPageSize={10}
        className='-striped -highlight'
      />
    </>
  );
};

const DialogBox = (props) => {
  let dialogBoxStyles = {
    position: 'fixed',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '105',
    padding: '16px 18px',
    minWidth: '460px',
    maxWidth: '300px',
    backgroundColor: 'white',
    boxShadow: '0 1px 0 rgba(12,13,14,0.1), 0 1px 6px rgba(59,64,69,0.1)',
    visibility: 'hidden',
  };

  let buttonStyle = {
    marginTop: '10px',
    fontWeight: 'bold',
    color: '#3c763d',
    paddingTop: '10px',
    paddingBottom: '10px',
    backgroundColor: '#dff0d8',
    display: 'inline-block',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  };

  if (props.show) {
    dialogBoxStyles.visibility = 'visible';
  }
  //editing form VVV
  const DialogBoxContent = () => {
    const [roomTypeId, setRoomTypeId] = useState('');
    const [roomTypeName, setRoomTypeName] = useState('');
    const [roomTypeDescription, setRoomTypeDescription] = useState('');
    const [roomTypeBorrowable, setRoomTypeBorrowable] = useState('');
    const [roomTypeHasSoftware, setRoomTypeHasSoftware] = useState('');
    const [roomTypeLocation, setRoomTypeLocation] = useState('');
    const [roomLocationOptions, setRoomLocationOptions] = useState([]);

    useEffect(() => {
      setRoomTypeId(props.selectedRoomType.id);
      setRoomTypeName(props.selectedRoomType.name);
      setRoomTypeDescription(props.selectedRoomType.description);
      setRoomTypeBorrowable(props.selectedRoomType.borrowable);
      setRoomTypeHasSoftware(props.selectedRoomType.has_software);
      setRoomTypeLocation(props.selectedRoomType.location_id);
      setRoomLocationOptions(props.roomLocationOptions);
    }, [props]);

    //kalo inactive berarti dia mo matiin usernya
    // if (props.dialogPurpose === 'Inactivate') {
    buttonStyle.color = '#3c763d';
    buttonStyle.backgroundColor = '#dff0d8';
    return (
      <Segment>
        <h3>Update Room Type #{roomTypeId}</h3>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column>
              <span>Name</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <input
                type='text'
                value={roomTypeName}
                onChange={(evt) => {
                  setRoomTypeName(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Description</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <TextArea
                type='text'
                value={roomTypeDescription}
                onChange={(evt) => {
                  setRoomTypeDescription(evt.target.value);
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Borrowable</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Yes / No'
                value={roomTypeBorrowable}
                onChange={(evt, { value }) => {
                  setRoomTypeBorrowable(value);
                }}
                options={[
                  { key: 1, value: 1, text: 'Yes' },
                  { key: 2, value: 0, text: 'No' },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Has Software</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Yes / No'
                value={roomTypeHasSoftware}
                onChange={(evt, { value }) => {
                  setRoomTypeHasSoftware(value);
                }}
                options={[
                  { key: 1, value: 1, text: 'Yes' },
                  { key: 2, value: 0, text: 'No' },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span>Location</span>
            </Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Dropdown
                selection
                fluid
                placeholder='Select Room Type Location'
                value={roomTypeLocation}
                onChange={(event, { value }) => {
                  setRoomTypeLocation(value);
                }}
                options={roomLocationOptions}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column></Grid.Column>
            <Grid.Column width={12} columns={2}>
              <Button
                positive
                fluid
                onClick={() => {
                  if (roomTypeName.length === 0) {
                    props.messageHandler(
                      'Room Type Name must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomTypeDescription.length === 0) {
                    props.messageHandler(
                      'Room Type Description must be filled',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomTypeBorrowable.length === 0) {
                    props.messageHandler(
                      'Borrowable status must be choosen',
                      'error',
                      true
                    );
                    return;
                  }
                  if (roomTypeLocation.length === 0) {
                    props.messageHandler(
                      'Room Type Location must be choosen',
                      'error',
                      true
                    );
                    return;
                  }
                  props.updateRoomTypeButtonHandler(
                    roomTypeId,
                    roomTypeName,
                    roomTypeDescription,
                    roomTypeBorrowable,
                    roomTypeHasSoftware,
                    roomTypeLocation,
                  );
                  // insertRoomTypeButtonHandler(roomTypeName, roomTypeDescription, roomTypeBorrowable, roomTypeLocation);
                }}
              >
                Update
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  };

  return <div style={dialogBoxStyles}>{DialogBoxContent()}</div>;
};

export default RoomLocation;
