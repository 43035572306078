import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './BottomToolBar.module.css';

const BottomToolBar = (props) => {
  const menu = props.menu.map((menu, index) => {
    return (
      <NavLink to={menu.link} activeClassName={classes.Active} key={index}>
        <div className={classes.BTB__Item}>
          <div>
            <i className='material-icons'>{menu.logo}</i>
          </div>
          <div>
            {menu.name}
          </div>
        </div>
      </NavLink>
    );
  });

  return <div className={classes.BottomToolBar}>{menu}</div>;
};

export default BottomToolBar;
