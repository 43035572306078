import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './Toolbar.module.css';

const Toolbar = (props) => {

    const handleClick = () => {
        props.sideDrawerHandler(true);
    }

    return (
        <div className={classes.Toolbar}>
            <div className={classes.Toolbar__Hamburger}>
                <i className="material-icons" style={{fontSize: "32px"}} onClick={handleClick}>menu</i>
            </div>
            <div className={classes.Toolbar__MenuContainer}>
                {
                    props.menu.map((menu, i) => {
                        return (
                            <NavLink to={menu.link} activeClassName={classes.MenuContainer__Item_Active} key={i}>
                                <div className={classes.MenuContainer__Item}>
                                    <i className="material-icons">{menu.logo}</i>
                                    <span style={{textAlign: 'center'}}>{menu.name}</span>
                                </div>
                            </NavLink>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default Toolbar;